import React, {useState} from 'react';
import {Typography} from '@mui/material';
import { createTheme , ThemeProvider } from '@mui/material/styles';
import {useSelector } from 'react-redux'

//import Tracks from "./tracks/Tracks.js"
//import Profile from "./profile/Profile.js"
import Dashboard from "./dashboard/Dashboard.js"
import Campaigns from "./campaigns/Campaigns.js"
import Positioning from "./positioning/Positioning.js"
import Leads from "./campaigns/Leads.js"
import Integrations from "./integrations/Integrations.js"
import CustomerProfile from "./customerProfile/CustomerProfile.js"
//import Companies from "./companies/Companies.js"
import Team from "./team/Team.js"
import Settings from "./settings/Settings.js"
import Agents from "./agents/Agents"

const headingTheme = createTheme({
    typography: {
      fontFamily: ['Roboto Mono', "monospace"].join(","),
    },
  });

export default function ContentGrid(props) {
  const [heading, setHeading] = useState('');
  const [description, setDescription] = useState('');
  const [bg, setBg] = useState('');

  const content_callback = (key, value) => {
    const keyMap = {
      "heading": setHeading,
      "description": setDescription,
      "bg": setBg
    }

    if (key in keyMap){
      const setFunction = keyMap[key]
      setFunction(value)
    }
    else{
      console.log("Can't set the value of "  + key)
    }
  }

  const component = {
    //"tracks": <Tracks key={props.subMode} callback={content_callback} {...props}/> ,
    //"profile": <Profile callback={content_callback}/> ,
    "home": <Dashboard key={props.subMode} callback={content_callback} {...props}/> ,
    "icp": <CustomerProfile key={props.subMode} callback={content_callback} {...props}/>,
    "positioning": <Positioning key={props.subMode} callback={content_callback} {...props}/>,
    "campaigns": <Campaigns key={props.subMode} callback={content_callback} {...props}/>,
    "leads": <Leads key={props.subMode} callback={content_callback} {...props} />,
    "integrations": <Integrations key={props.subMode} callback={content_callback} {...props}/>,
    //"companies": <Companies key={props.subMode} callback={content_callback} {...props}/> ,
    "team": <Team key={props.subMode} callback={content_callback} {...props}/> ,
    "settings": <Settings key={props.subMode} callback={content_callback} {...props}/>,
    "agents": <Agents key={props.subMode} callback={content_callback} {...props} />
  }

  const headingHeight = "85px"
  const contentMargin = headingHeight + "46px"

  return (
    <>
      <div style={{height: headingHeight, padding: "0px 10px 18px", marginLeft: "12px"}}>
        {//<ThemeProvider theme={headingTheme}>
        }
          <Typography fontSize="36" display="inline" sx={{
            overflow: 'hidden', //all this is added to contrain heading to one line- https://stackoverflow.com/a/71324014/9160820
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '1',
            WebkitBoxOrient: 'vertical'}} >
            {heading}
          </Typography>
        {//</ThemeProvider>
        }
        <Typography variant="body1" sx={{
          overflow: 'hidden', //all this is added to contrain heading to one line- https://stackoverflow.com/a/71324014/9160820
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: '1',
          WebkitBoxOrient: 'vertical'}} >
          {description}
        </Typography>
      </div>
      <div style={{ padding: "0px 10px 18px", marginLeft: "12px", marginTop: "18px"}}>
        {component[props["mode"]]}
      </div>
    </>
  )
}

// when old sidebar was in use
// <>
//   <div style={{height: headingHeight, padding: "0px 10px 18px", marginLeft: "80px"}}>
//     {//<ThemeProvider theme={headingTheme}>
//     }
//       <Typography variant="h3" display="inline" sx={{
//         overflow: 'hidden', //all this is added to contrain heading to one line- https://stackoverflow.com/a/71324014/9160820
//         textOverflow: 'ellipsis',
//         display: '-webkit-box',
//         WebkitLineClamp: '1',
//         WebkitBoxOrient: 'vertical'}} >
//         {heading}
//       </Typography>
//     {//</ThemeProvider>
//     }
//     <Typography variant="h6" sx={{
//       overflow: 'hidden', //all this is added to contrain heading to one line- https://stackoverflow.com/a/71324014/9160820
//       textOverflow: 'ellipsis',
//       display: '-webkit-box',
//       WebkitLineClamp: '1',
//       WebkitBoxOrient: 'vertical'}} >
//       {description}
//     </Typography>
//   </div>
//   <div style={{ padding: "12px 18px 24px", position: "absolute", left: "80px", right: "0px"}}>
//     {component[props["mode"]]}
//   </div>
// </>
