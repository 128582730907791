import React, {useEffect, useState} from "react"
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {Grid, Typography, Divider} from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';

import axios from "axios"
import { BACKEND_ADDRESS } from "./../utils/getEndpoint"
import { PERSONAS, STAGE_MAPPING } from "./../utils/constants"

import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ListIcon from '@mui/icons-material/List';

import AddPersona from "./AddPersona"
import AddCustomerProfile from "./AddCustomerProfile"
import CustomerProfileScore from "./CustomerProfileScore"

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const redC = "#ee8c91"
const greenC = "#9fc794"

export default function CompareCP(props){
  const lhs = props.data[0]
  const rhs = props.data[1]
  const lhsName = props.data[2]
  const rhsName = props.data[3]

  const findArrayDiff = (arr1, arr2, mode="index_match") => {
    //modes- "index_match" or "value_match"- index_match preserves index for left and right array separately while value_match doesn't care about element index, just order
    console.log("()")
    console.log(arr1, arr2)
    
    const arrDict1 = arr1.reduce((obj, key, ind) => {obj[key] = ind; return obj}, {})
    const arrDict2 = arr2.reduce((obj, key, ind) => {obj[key] = ind; return obj}, {})
    var arrDifference = []
    var arrDifferenceRight = []
    const arrKeys1 = Object.keys(arrDict1)
    const arrKeys2 = Object.keys(arrDict2)

    for(var i=0;i<arrKeys1.length;i+=1){
      //storing index of where it was find in rhs array
      if(arrKeys1[i] in arrDict2) arrDifference.push(arrDict2[arrKeys1[i]])
      else arrDifference.push("l")
    }
    if(mode === "index_match"){
      for(i=0;i<arrKeys2.length;i+=1){
        if(arrKeys2[i] in arrDict1) arrDifferenceRight.push(arrDict1[arrKeys2[i]])
        else arrDifferenceRight.push("r")
      }
      console.log(arrDifference)
      console.log(arrDifferenceRight)
      return [arrDifference, arrDifferenceRight] 
    }else{
      for(i=0;i<arrKeys2.length;i+=1){
        if(arrKeys2[i] in arrDict1) {}
        else arrDifference.push("r")
      }
      console.log(arrDifference)
    }

    return arrDifference
  }

  const nameDifference = (lhs["name"] !== rhs["name"])
  const descriptionDifference = (lhs["description"] !== rhs["description"])
  const problemDifference = findArrayDiff(lhs["problems"], rhs["problems"])
  const industryDifference = findArrayDiff(lhs["industries"], rhs["industries"])
  const verticalDifference = findArrayDiff(lhs["verticals"], rhs["verticals"])
  const companySizeDifference = findArrayDiff(lhs["company_size"], rhs["company_size"])
  const teamSizeDifference = findArrayDiff(lhs["team_size"], rhs["team_size"])
  const geographyDifference = findArrayDiff(lhs["geographies"], rhs["geographies"])
  const buyingSignalTechDifference = findArrayDiff(lhs["buying_signals_tech"], rhs["buying_signals_tech"])
  const buyingSignalNonTechDifference = findArrayDiff(lhs["buying_signals_non_tech"], rhs["buying_signals_non_tech"])
  const personaDifference = findArrayDiff(lhs["personas"].map(x=>x["id"]), rhs["personas"].map(x=>x["_id"]["$oid"]))
  //const personaDifferenceRhs = findArrayDiff(rhs["personas"].map(x=>x["_id"]["$oid"]), lhs["personas"].map(x=>x["id"]))
  console.log(lhs["personas"])
  console.log(personaDifference)
  console.log("====")
  console.log(rhs["personas"])
  //console.log(personaDifferenceRhs)

  return (
  <Dialog open onClose={props.onCloseCallback} maxWidth="lg" fullWidth>
    <DialogContent>
      <Grid container direction="row" spacing={2} justifyContent="center">
        <Grid container item xs={6} spacing={2} direction="column" style={{paddingRight: "16px", borderRight: "1px solid rgba(0,0,0,0.12)"}}>
          <Grid item><Typography variant="h5">{lhsName}</Typography></Grid>
          <Grid item>
            <Paper style={{borderRadius: "8px", padding: "24px"}}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} container direction="row" justifyContent="space-between">
                  <Grid item>
                    <Typography variant="h5" sx={nameDifference?{backgroundColor: redC}:{}}>{lhs["name"]}</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={descriptionDifference?{backgroundColor: redC}:{}}>{lhs["description"]}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider sx={{mb: 2}} />
                  <Typography><b>Problems</b></Typography>
                  {problemDifference[0].map((x, ind) => {
                  if(typeof(x) === "number") return (<Stack direction="row" alignItems="center" key={ind} spacing={1}><ArrowForwardIosIcon color="primary" fontSize="16" /><Typography>{lhs["problems"][ind]}</Typography></Stack>)
                  else if(x==="l") return (<Stack direction="row" alignItems="center" key={ind} spacing={1}><ArrowForwardIosIcon color="primary" fontSize="16" /><Typography sx={{backgroundColor: redC}}>{lhs["problems"][ind]}</Typography></Stack>)
                  else return (<Stack direction="row" alignItems="center" key={ind} spacing={1}><ArrowForwardIosIcon color="primary" fontSize="16" /><Typography></Typography></Stack>)
                  })}
                  <Divider sx={{mt: 2}} />
                </Grid>
                <Grid item xs={6}>
                  <Typography><b>Industries</b></Typography>
                  <Stack direction="row" spacing={1} sx={{flexWrap: 'wrap'}}>
                    {industryDifference[0].map((x, ind) => {
                    if(typeof(x) === "number") return (<Chip key={ind} label={lhs["industries"][ind]} sx={{color: "primary.main"}} style={{marginBottom: 2}}/>)
                    else if(x === "l") return (<Chip key={ind} label={lhs["industries"][ind]} sx={{color: "primary.main", backgroundColor: redC}} style={{marginBottom: 2}}/>)
                    else return (<React.Fragment key={ind}></React.Fragment>)
                    })}
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Typography><b>Verticals</b></Typography>
                  <Stack direction="row" spacing={1} sx={{flexWrap: 'wrap'}}>
                    {verticalDifference[0].map((x, ind) => {
                    if(typeof(x) === "number") return (<Chip key={ind} label={lhs["verticals"][ind]} sx={{color: "primary.main"}} style={{marginBottom: 2}}/>)
                    else if(x === "l") return (<Chip key={ind} label={lhs["verticals"][ind]} sx={{color: "primary.main", backgroundColor: redC}} style={{marginBottom: 2}}/>)
                    else return (<React.Fragment key={ind}></React.Fragment>)
                    })}
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Typography><b>Company Size</b></Typography>
                  <Stack direction="row" spacing={1} sx={{flexWrap: 'wrap'}}>
                    {companySizeDifference[0].map((x, ind) => {
                    if(typeof(x) === "number") return (<Chip key={ind} label={lhs["company_size"][ind]} sx={{color: "primary.main"}} style={{marginBottom: 2}}/>)
                    else if(x === "l") return (<Chip key={ind} label={lhs["company_size"][ind]} sx={{color: "primary.main", backgroundColor: redC}} style={{marginBottom: 2}}/>)
                    else return (<React.Fragment key={ind}></React.Fragment>)
                    })}
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Typography><b>Team Size</b></Typography>
                  <Stack direction="row" spacing={1} sx={{flexWrap: 'wrap'}}>
                    {teamSizeDifference[0].map((x, ind) => {
                    if(typeof(x) === "number") return (<Chip key={ind} label={lhs["team_size"][ind]} sx={{color: "primary.main"}} style={{marginBottom: 2}}/>)
                    else if(x === "l") return (<Chip key={ind} label={lhs["team_size"][ind]} sx={{color: "primary.main", backgroundColor: redC}} style={{marginBottom: 2}}/>)
                    else return (<React.Fragment key={ind}></React.Fragment>)
                    })}
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Typography><b>Geography</b></Typography>
                  <Stack direction="row" spacing={1} sx={{flexWrap: 'wrap'}}>
                    {geographyDifference[0].map((x, ind) => {
                    if(typeof(x) === "number") return (<Chip key={ind} label={lhs["geographies"][ind]} sx={{color: "primary.main"}} style={{marginBottom: 2}}/>)
                    else if(x === "l") return (<Chip key={ind} label={lhs["geographies"][ind]} sx={{color: "primary.main", backgroundColor: redC}} style={{marginBottom: 2}}/>)
                    else return (<React.Fragment key={ind}></React.Fragment>)
                    })}
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Typography><b>Technical Buying Signals</b></Typography>
                  <Stack direction="row" spacing={1} sx={{flexWrap: 'wrap'}}>
                    {buyingSignalTechDifference[0].map((x, ind) => {
                    if(typeof(x) === "number") return (<Chip key={ind} label={lhs["buying_signals_tech"][ind]} sx={{color: "primary.main"}} style={{marginBottom: 2}}/>)
                    else if(x === "l") return (<Chip key={ind} label={x} sx={{color: "primary.main", backgroundColor: redC}} style={{marginBottom: 2}}/>)
                    else return (<React.Fragment key={ind}></React.Fragment>)
                    })}
                  </Stack>
                </Grid>

                <Grid item xs={6}>
                  <Typography><b>Non-technical Buying Signals</b></Typography>
                  <Stack direction="row" spacing={1} sx={{flexWrap: 'wrap'}}>
                    {buyingSignalNonTechDifference[0].map((x, ind) => {
                    if(typeof(x) === "number") return (<Chip key={ind} label={lhs["buying_signals_non_tech"][ind]} sx={{color: "primary.main"}} style={{marginBottom: 2}}/>)
                    else if(x === "l") return (<Chip key={ind} label={x} sx={{color: "primary.main", backgroundColor: redC}} style={{marginBottom: 2}}/>)
                    else return (<React.Fragment key={ind}></React.Fragment>)
                    })}
                  </Stack>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item sx={{mt:2}}>
          <Typography variant="h6">Buyer Personas</Typography>
        </Grid>
        {personaDifference[0].map((x, ind) => {
          // if(x === "r"){
          //   return (
          //   <Grid item key={ind}>
          //     <Paper style={{borderRadius: "8px", padding: "24px", height: "270"}}elevation={0}>
          //     </Paper>
          //   </Grid>
          //   )
          // }
          const personaNameDiff = ((typeof(x) === "number")?((lhs["personas"][ind]["designation"] + lhs["personas"][ind]["vertical"]) !==
          (rhs["personas"][x]["designation"] + rhs["personas"][x]["vertical"])):false)
          const valuePropDiff = (typeof(x) === "number")?findArrayDiff(lhs["personas"][ind]["value_props"], rhs["personas"][x]["value_props"])[0]:lhs["personas"][ind]["value_props"].map(i=>"l")
          const benefitsDiff = (typeof(x) === "number")?findArrayDiff(lhs["personas"][ind]["benefits"], rhs["personas"][x]["benefits"])[0]:lhs["personas"][ind]["benefits"].map(i=>"l")
          console.log(lhs["personas"][ind]["value_props"])
          console.log(lhs["personas"][ind]["benefits"])

          return (
            <Grid item key={ind}>
              <Paper style={{borderRadius: "8px", padding: "24px"}} sx={(x==="l")?{backgroundColor:redC}:{}}>
                <Grid container direction="row" spacing={3}>
                  <Grid container item direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item>
                      <Stack direction="row" alignItems="center">
                        <Avatar src={PERSONAS[x["avatar"]]} sx={{width: "70px", height: "70px"}} />
                        &nbsp;&nbsp;&nbsp;
                        <Typography variant="h6" sx={personaNameDiff?{backgroundColor: redC}:{}}>{lhs["personas"][ind]["designation"]},&nbsp;{lhs["personas"][ind]["vertical"]}</Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack direction="column">
                      <Typography><b>Value Props</b></Typography>
                      {valuePropDiff.map((y, id) => {
                        if(lhs["personas"][ind]["value_props"][id]!==""){
                          return (
                            <Stack direction="row" alignItems="center" key={id} spacing={1}>
                              <ArrowForwardIosIcon color="primary" fontSize="16" />
                              {typeof(y) === "number"?
                                <Typography>{lhs["personas"][ind]["value_props"][id]}</Typography>
                              :y === "l"?
                                <Typography sx={{backgroundColor: redC}}>{lhs["personas"][ind]["value_props"][id]}</Typography>
                              :<></>}
                            </Stack>
                          )
                        }else return (<React.Fragment key={id}></React.Fragment>)
                      })}
                      {benefitsDiff.map((y, id) => {
                        if(lhs["personas"][ind]["benefits"][id]!==""){
                          return (
                            <Stack direction="row" alignItems="center" key={id} spacing={1}>
                              <ArrowForwardIosIcon color="primary" fontSize="16" />
                              {typeof(y) === "number"?
                                <Typography>{lhs["personas"][ind]["benefits"][id]}</Typography>
                              :y === "l"?
                                <Typography sx={{backgroundColor: redC}}>{lhs["personas"][ind]["benefits"][id]}</Typography>
                              :<></>}
                            </Stack>
                          )
                        }else return (<React.Fragment key={id}></React.Fragment>)
                      })}
                    </Stack>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          )
        })}
        </Grid>
        <Grid container item xs={6} spacing={2} direction="column">
        <Grid item><Typography variant="h5">{rhsName}</Typography></Grid>
          <Grid item>
            <Paper style={{borderRadius: "8px", padding: "24px"}}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} container direction="row" justifyContent="space-between">
                  <Grid item>
                    <Typography variant="h5" sx={nameDifference?{backgroundColor: greenC}:{}}>{rhs["name"]}</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={descriptionDifference?{backgroundColor: greenC}:{}}>{rhs["description"]}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider sx={{mb: 2}} />
                  <Typography><b>Problems</b></Typography>
                  {problemDifference[1].map((x, ind) => {
                  if(typeof(x) === "number") return (<Stack direction="row" alignItems="center" key={ind} spacing={1}><ArrowForwardIosIcon color="primary" fontSize="16" /><Typography>{rhs["problems"][ind]}</Typography></Stack>)
                  else if(x==="r") return (<Stack direction="row" alignItems="center" key={ind} spacing={1}><ArrowForwardIosIcon color="primary" fontSize="16" /><Typography sx={{backgroundColor: greenC}}>{rhs["problems"][ind]}</Typography></Stack>)
                  else return (<Stack direction="row" alignItems="center" key={ind} spacing={1}><ArrowForwardIosIcon color="primary" fontSize="16" /><Typography></Typography></Stack>)
                  })}
                  <Divider sx={{mt: 2}} />
                </Grid>
                <Grid item xs={6}>
                  <Typography><b>Industries</b></Typography>
                  <Stack direction="row" spacing={1} sx={{flexWrap: 'wrap'}}>
                    {industryDifference[1].map((x, ind) => {
                    if(typeof(x) === "number") return (<Chip key={ind} label={rhs["industries"][ind]} sx={{color: "primary.main"}} style={{marginBottom: 2}}/>)
                    else if(x === "r") return (<Chip key={ind} label={rhs["industries"][ind]} sx={{color: "primary.main", backgroundColor: greenC}} style={{marginBottom: 2}}/>)
                    else return (<React.Fragment key={ind}></React.Fragment>)
                    })}
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Typography><b>Verticals</b></Typography>
                  <Stack direction="row" spacing={1} sx={{flexWrap: 'wrap'}}>
                    {verticalDifference[1].map((x, ind) => {
                    if(typeof(x) === "number") return (<Chip key={ind} label={rhs["verticals"][ind]} sx={{color: "primary.main"}} style={{marginBottom: 2}}/>)
                    else if(x === "r") return (<Chip key={ind} label={rhs["verticals"][ind]} sx={{color: "primary.main", backgroundColor: greenC}} style={{marginBottom: 2}}/>)
                    else return (<React.Fragment key={ind}></React.Fragment>)
                    })}
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Typography><b>Company Size</b></Typography>
                  <Stack direction="row" spacing={1} sx={{flexWrap: 'wrap'}}>
                    {companySizeDifference[1].map((x, ind) => {
                    if(typeof(x) === "number") return (<Chip key={ind} label={rhs["company_size"][ind]} sx={{color: "primary.main"}} style={{marginBottom: 2}}/>)
                    else if(x === "r") return (<Chip key={ind} label={rhs["company_size"][ind]} sx={{color: "primary.main", backgroundColor: greenC}} style={{marginBottom: 2}}/>)
                    else return (<React.Fragment key={ind}></React.Fragment>)
                    })}
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Typography><b>Team Size</b></Typography>
                  <Stack direction="row" spacing={1} sx={{flexWrap: 'wrap'}}>
                    {teamSizeDifference[1].map((x, ind) => {
                    if(typeof(x) === "number") return (<Chip key={ind} label={rhs["team_size"][ind]} sx={{color: "primary.main"}} style={{marginBottom: 2}}/>)
                    else if(x === "r") return (<Chip key={ind} label={rhs["team_size"][ind]} sx={{color: "primary.main", backgroundColor: greenC}} style={{marginBottom: 2}}/>)
                    else return (<React.Fragment key={ind}></React.Fragment>)
                    })}
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Typography><b>Geography</b></Typography>
                  <Stack direction="row" spacing={1} sx={{flexWrap: 'wrap'}}>
                    {geographyDifference[1].map((x, ind) => {
                    if(typeof(x) === "number") return (<Chip key={ind} label={rhs["geographies"][ind]} sx={{color: "primary.main"}} style={{marginBottom: 2}}/>)
                    else if(x === "r") return (<Chip key={ind} label={rhs["geographies"][ind]} sx={{color: "primary.main", backgroundColor: greenC}} style={{marginBottom: 2}}/>)
                    else return (<React.Fragment key={ind}></React.Fragment>)
                    })}
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Typography><b>Technical Buying Signals</b></Typography>
                  <Stack direction="row" spacing={1} sx={{flexWrap: 'wrap'}}>
                    {buyingSignalTechDifference[1].map((x, ind) => {
                    if(typeof(x) === "number") return (<Chip key={ind} label={rhs["buying_signals_tech"][ind]} sx={{color: "primary.main"}} style={{marginBottom: 2}}/>)
                    else if(x ==="r") return (<Chip key={ind} label={rhs["buying_signals_tech"][ind]} sx={{color: "primary.main", backgroundColor: greenC}} style={{marginBottom: 2}}/>)
                    else return (<React.Fragment key={ind}></React.Fragment>)
                    })}
                  </Stack>
                </Grid>

                <Grid item xs={6}>
                  <Typography><b>Non-technical Buying Signals</b></Typography>
                  <Stack direction="row" spacing={1} sx={{flexWrap: 'wrap'}}>
                    {buyingSignalNonTechDifference[1].map((x, ind) => {
                    if(typeof(x) === "number") return (<Chip key={ind} label={rhs["buying_signals_non_tech"][ind]} sx={{color: "primary.main"}} style={{marginBottom: 2}}/>)
                    else if(x === "r") return (<Chip key={ind} label={rhs["buying_signals_non_tech"][ind]} sx={{color: "primary.main", backgroundColor: greenC}} style={{marginBottom: 2}}/>)
                    else return (<React.Fragment key={ind}></React.Fragment>)
                    })}
                  </Stack>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item sx={{mt:2}}>
          <Typography variant="h6">Buyer Personas</Typography>
        </Grid>
        {personaDifference[1].map((x, ind) => {
          // if(x === "r"){
          //   return (
          //   <Grid item key={ind}>
          //     <Paper style={{borderRadius: "8px", padding: "24px", height: "270"}}elevation={0}>
          //     </Paper>
          //   </Grid>
          //   )
          // }
          const personaNameDiff = ((typeof(x) === "number")?((rhs["personas"][ind]["designation"] + rhs["personas"][ind]["vertical"]) !==
          (lhs["personas"][x]["designation"] + lhs["personas"][x]["vertical"])):false)
          const valuePropDiff = (typeof(x) === "number")?findArrayDiff(rhs["personas"][ind]["value_props"], lhs["personas"][x]["value_props"])[0]:rhs["personas"][ind]["value_props"].map(i=>"r")
          const benefitsDiff = (typeof(x) === "number")?findArrayDiff(rhs["personas"][ind]["benefits"], lhs["personas"][x]["benefits"])[0]:rhs["personas"][ind]["benefits"].map(i=>"r")

          console.log(rhs["personas"][ind]["value_props"])
          console.log(rhs["personas"][ind]["benefits"])
          return (
            <Grid item key={ind}>
              <Paper style={{borderRadius: "8px", padding: "24px"}} sx={(x==="r")?{backgroundColor:greenC}:{}}>
                <Grid container direction="row" spacing={3}>
                  <Grid container item direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item>
                      <Stack direction="row" alignItems="center">
                        <Avatar src={PERSONAS[x["avatar"]]} sx={{width: "70px", height: "70px"}} />
                        &nbsp;&nbsp;&nbsp;
                        <Typography variant="h6" sx={personaNameDiff?{backgroundColor: greenC}:{}}>{rhs["personas"][ind]["designation"]},&nbsp;{rhs["personas"][ind]["vertical"]}</Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack direction="column">
                      <Typography><b>Value Props</b></Typography>
                      {valuePropDiff.map((y, id) => {
                        if(rhs["personas"][ind]["value_props"][id]!==""){
                          return (
                            <Stack direction="row" alignItems="center" key={id} spacing={1}>
                              <ArrowForwardIosIcon color="primary" fontSize="16" />
                              {typeof(y) === "number"?
                                <Typography>{rhs["personas"][ind]["value_props"][id]}</Typography>
                              :y === "r"?
                                <Typography sx={{backgroundColor: greenC}}>{rhs["personas"][ind]["value_props"][id]}</Typography>
                              :<></>}
                            </Stack>
                          )
                        }else return (<React.Fragment key={id}></React.Fragment>)
                      })}
                      {benefitsDiff.map((y, id) => {
                        if(rhs["personas"][ind]["benefits"][id]!==""){
                          console.log(y, typeof(y) === "number")
                          return (
                            <Stack direction="row" alignItems="center" key={id} spacing={1}>
                              <ArrowForwardIosIcon color="primary" fontSize="16" />
                              {typeof(y) === "number"?
                                <Typography>{rhs["personas"][ind]["benefits"][id]}</Typography>
                              :y === "r"?
                                <Typography sx={{backgroundColor: greenC}}>{rhs["personas"][ind]["benefits"][id]}</Typography>
                              :<></>}
                            </Stack>
                          )
                        }else return (<React.Fragment key={id}></React.Fragment>)
                      })}
                    </Stack>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          )
        })}
        </Grid>
      </Grid>
    </DialogContent>
  </Dialog>
  )
}