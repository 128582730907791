import React, {useEffect, useState} from "react"
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {Grid, Typography} from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import ToastComponent from "./../utils/ToastComponent";
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import axios from "axios"
import { BACKEND_ADDRESS } from "./../utils/getEndpoint"
import { PERSONAS, STAGE_MAPPING } from "./../utils/constants"

import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import LockIcon from '@mui/icons-material/Lock';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider'
import TextField from '@mui/material/TextField';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ListIcon from '@mui/icons-material/List';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

export default function Agents(props){
  useEffect(() => {
    props.callback("heading", "Agents")
    props.callback("description", "Choose an agentic workflow below to achieve a task of your choice")
  }, [])

  let history = useHistory()

  const allAgents = [
    {
      name: "Investor Update",
      description: "Send an investor update with a snapshot of your ICP Refinement Progress",
      uri: "/investor-update"
    },
    {
      name: "AI Cold Call Roleplay",
      description: "Roleplay with buyers in your Customer Profile to practice before talking to them",
      uri: "/roleplay"
    },
    {
      name: "AI Elevator Pitch Roleplay",
      description: "Roleplay with buyers in your Customer Profile to practice before talking to them",
      uri: "/roleplay"
    },
    {
      name: "Improve Customer Profile Messaging",
      description: "Use Aspiro's Buyer Intelligence AI to improve your messaging",
      uri: "/improve-messaging"
    },
    {
      name: "LinkedIn Content Generation",
      description: "Create content around your offering that appeals to your Buyer Personas",
      uri: "/linkedin-content"
    },
    {
      name: "X Content Generation",
      description: "Create content around your offering that appeals to your Buyer Personas",
      uri: "/x-content"
    },
    {
      name: "Sharpen Customer Profile",
      description: "Make your Customer Profile narrower to make it risk free",
      uri: "/sharpen-cp"
    },
    {
      name: "Repeatable Customer Profile?",
      description: "Check if your customer profiles are repeatable and create predictability in your sales motion",
      uri: "/sharpen-cp"
    },
    {
      name: "Validate Buyer Problems",
      description: "Run campaigns to (in)validate buyer problems",
      uri: "/sharpen-cp"
    },
    {
      name: "Benchmark Test",
      description: "Evaluate campaign performance to understand if customer need is strong enough",
      uri: "/sharpen-cp"
    },
  ]

  return (
    <Grid container direction="row" spacing={2}>
      {allAgents.map((x, ind) => {
        return (
          <Grid item>
            <Paper style={{borderRadius: "8px", padding: "24px", width: "200px", height: "150px"}}
            sx={{':hover': {boxShadow: 10, cursor: "pointer"}}} onClick={() => history.push('/agents/' + x.uri)}>
              <Typography variant="h6">{x.name}</Typography>
              <Typography variant="body1">{x.description}</Typography>
            </Paper>
          </Grid>
        )
      })}
    </Grid>
  )
}
