import {useState, useEffect} from "react"

import Aspiro from "./Aspiro"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Paper from "@mui/material/Paper";

import { IconButton, CardHeader} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge'

import fire from './utils/base'
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux'
import {setRoleValue, setEmailValue} from './store/roleSlice';
import {useHistory} from "react-router-dom"
import {BACKEND_ADDRESS} from "./utils/getEndpoint.js"

export default function NavBarCustom(props) {
  let history = useHistory();
  const role = useSelector((state) => state.role.value);
  const email = useSelector((state) => state.email.value);
  var name = useSelector((state) => state.name.value);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [showimage, setshowimage]= useState('');

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  //for Notification option
  const [anchorEl1, setAnchorEl1] = useState(null);
  const open1 = Boolean(anchorEl1);
  const dispatch = useDispatch()

  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const handleMenu1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };


  function logout(){
    fire.auth().signOut().then(() => {
      console.log("sign out successfully")
    }).catch((error) => {
    });
    localStorage.removeItem('user');
    setAnchorEl(null);

    //setting role to blank again
    dispatch(setRoleValue(""))
    dispatch(setEmailValue(""))

    history.push('/signin');
  };

  function handleProfile(){
    history.push('/profile');
  };

  useEffect( () => {
    if(email !== "" && role !== ""){
      axios.get(BACKEND_ADDRESS + "/profile/get_image/",
      {params: {
        email : email,
        role : role
      }})
      .then((response) => {
        //console.log(response.data)
        if('url' in response.data){
          setshowimage(response.data["url"]);
        }else{
          console.log("Profile image not setup")
        }
      })
      .catch((error) => {
        console.log(error)
      })
    }
  } , [email, role])

  return (
      <Paper square elevation={0} style={{position: "fixed", height: "36px", bottom: "auto", top: "0", left: "auto", right: "0", zIndex: 7, padding: "6px 12px", marginLeft: "-10px"}}>
        <Grid container direction="row" spacing={1} justifyContent="end" alignItems="center">
        <Grid item><Aspiro /></Grid>
        <Grid item>
        <IconButton aria-controls={open1 ?
           'basic-menu1' : undefined} aria-expanded={open1 ? 'true' : undefined}
             aria-haspopup="true" onClick={handleMenu1} size="small">
          <Badge color="secondary" badgeContent={0}>
            <NotificationsIcon style={{fill:"#395af6"}}/>
          </Badge>
        </IconButton>

        <Menu id="basic-menu1" anchorEl={anchorEl1}
          open={open1} onClose={handleClose1}
          anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
          transformOrigin={{vertical: 'top', horizontal: 'center'}}
          style={{width: 300}}
        >
          <MenuItem><i>You are all caught up!</i></MenuItem>
        </Menu>
        </Grid>
        <Grid item>
        <IconButton aria-label="Current user account"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}  aria-haspopup="true"
          onClick={handleMenu} color="inherit" decoration="none" size="small">
        <Avatar sx={{width: "28px", height: "28px"}} src={showimage} alt={name}>
        </Avatar>
        </IconButton>

        <Menu id="basic-menu" anchorEl={anchorEl}
          open={open} onClose={handleClose}
          anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
          transformOrigin={{vertical: 'top', horizontal: 'center'}}
          style={{width: 300}}
        >
          <Card elevation={0}>
            <CardHeader style={{minWidth: 225,
            minHeight: 50,
            paddingBottom: '10px'}}
              avatar={
                <Avatar src={showimage}>
                </Avatar>
              }
              titleTypographyProps={{variant:'h6'}} title={name}
              subheaderTypographyProps={{variant:'subtitle2'}} subheader={email}
            />
            {/*DONT Delete
              <MenuItem onClick={handleProfile}>Profile</MenuItem>
            */}
            <MenuItem onClick={logout}>Log Out</MenuItem>
          </Card>
        </Menu>
        </Grid>
        </Grid>
      </Paper>
  )
}
