import australia_male_1 from "./../prospect/australia_male_1.png"
import australia_male_2 from "./../prospect/australia_male_2.png"

import europe_male_1 from "./../prospect/europe_male_1.png"
import europe_female_1 from "./../prospect/europe_female_1.png"
import europe_male_2 from "./../prospect/europe_male_2.png"
import europe_female_2 from "./../prospect/europe_female_2.png"

import india_male_1 from "./../prospect/india_male_1.png"
import india_female_1 from "./../prospect/india_female_1.png"
import india_male_2 from "./../prospect/india_male_2.png"
import india_female_2 from "./../prospect/india_female_2.png"

import la_male_1 from "./../prospect/la_male_1.png"
import la_female_1 from "./../prospect/la_female_1.png"
import la_male_2 from "./../prospect/la_male_2.png"
import la_female_2 from "./../prospect/la_female_2.png"

import na_male_1 from "./../prospect/na_male_1.png"
import na_female_1 from "./../prospect/na_female_1.png"
import na_male_2 from "./../prospect/na_male_2.png"
import na_female_2 from "./../prospect/na_female_2.png"

const COLD_CALL = "Cold Call"
const DISCOVERY = "Discovery"

const BACKEND_ADDRESS = process.env.REACT_APP_BACKEND

const STAGE_MAPPING = {
  1: "Problem Validation",
  2: "Early Sales"
}

const PERSONAS = [australia_male_1, australia_male_2, europe_male_1, europe_male_2,
  europe_female_1, europe_female_2, india_male_1, india_male_2, india_female_1,
  india_female_2, la_male_1, la_male_2, la_female_1, la_female_2, na_male_1,
  na_female_1, na_male_2, na_female_2]

const CAMPAIGN_STAGES = [
  { stageNumber: 1, stageName: 'Set Up Outreach Copy & Scripts'},
  { stageNumber: 2, stageName: 'Contact Leads & Log Conversations'},
  //{ stageNumber: 3, stageName: 'Log Conversations'},
];

const TEMPLATE_COPY = [
  {
    Channel: 0,
    Theme: 0,
    copy: [
      {
        description: "Not Personalised",
        subject: "Seeking your inputs for my startup",
        message: "Hey {name},\n\nI'm a startup founder trying to solve {problem} for {vertical}. [has_product?It is still very early days and we don't even have anything to sell, but I want to make sure we're building something that people actually want.:We have a product out already, but this is in no way a sales pitch. We're just moving into some new areas like {add_areas} and I felt your guidance could really help.]\n\nSince I have limited experience and insight with {industry}, I'm having a hard time. I was looking for experts in this space and some of my friends pointed in your direction. Your inputs could save us from making a ton of mistakes.\n\n[meet_in_person?Are you available for a 30min chat over coffee some time next week?:Are you available for a virtual meeting? Please book a 30min slot as per your convenience: {meeting_link}]\n\nRegards,\n{seller_name}"
      },
      // {
      //   description: "Personalised using Buyer's LinkedIn/Twitter post",
      //   subject: "Seeking your inputs for my startup",
      //   message: "hjg gjh"
      // },
      // {
      //   description: "Personalised using Buyer's Work/Industry",
      //   subject: "Seeking your inputs for my startup",
      //   message: "hjg gjh"
      // },
    ],
  },
  {
    Channel: 1,
    Theme: 0,
    copy: [
      {
        description: "Not Personalised",
        message: "Hey {name}, {seller_name} here. I got your reference from my friend {friend_name}.\n\nI'm a first time founder and have a startup named {seller_company}. We're trying to solve {problem} for {vertical}.\n\n[has_product?It is still very early days and we don't even have anything to sell, but I want to make sure we're building something that people actually want.:We have a product out already, but this is in no way a sales pitch. We're just moving into some new areas like {add_areas} and I felt your guidance could really help.]",
        script: "**They reply and ask how they can help out**\nSince I have limited experience and insight with {industry}, I'm having a hard time. 😕\n\nI was looking for experts in this space and got pointed in your direction. Your advice could save us from making a ton of mistakes.\n\n[meet_in_person?Are you available for a 30min chat over coffee some time next week?:Are you available for a virtual meeting? Please book a 30min slot as per your convenience: {meeting_link}]\n\n**They confirm or give other way to contact**\nThanks! Really appreciate it and looking forward to our chat. ✌🏼"
      }
    ]
  },
  {
    Channel: 2,
    Theme: 0,
    copy: [
      {
        description: "Not Personalised",
        script: "Hi, Is this {name}?\n\n**They confirm name and ask who are you/why you are calling**\nHi {name}, {seller_name} here. I'm the founder of a startup named {seller_company}. First off, this is not a sales call. And I promise I won't take more than 45 seconds of your time. May I continue?\n\n**They allow you to continue**\nGreat! So we're trying to solve {problem} for {vertical}. [has_product?It is still very early days and we don't even have anything to sell, but I want to make sure we're building something that people actually want.:We have a product out already, but I felt your guidance could really help as we're moving into some new areas like {add_areas}.]\n\nSince I have limited experience and insight with {industry}, I'm having a hard time. I was looking for experts in this space and got pointed in your direction. Your advice could save us from making a ton of mistakes.\n\n**They get impatient but still keenly listening**\n[meet_in_person?Are you available for a 30min chat over coffee some time next week?:Are you available for a 30min virtual meeting some time next week?]\n\n**They agree and ask to send an invite**\nThank you so much, I can drop you a calendar invite. May I have your email ID?\n\n**They confirm or give other way to contact**\n<Confirm Email details> Got it! Really appreciate it and looking forward to our chat."
      }
    ]
  },
  {
    Channel: 3,
    Theme: 0,
    copy: [
      {
        description: "Not Personalised",
        script: "Hi {name}, I'm {seller_name}. I'm a first time founder and have a startup named {seller_company}. How have you been?\n\n**They say hello to you**\nSo we're trying to solve {problem} for {vertical}.\n[has_product?It is still very early days and we don't even have anything to sell, but I want to make sure we're building something that people actually want.:We have a product out already, but I felt your guidance could really help as we're moving into some new areas like {add_areas}.]\n\n**They allow you to continue**\nSince I have limited experience and insight with {industry}, I'm having a hard time. I was looking for experts in this space and got pointed in your direction. Your advice could save us from making a ton of mistakes.\n\n[meet_in_person?Are you available for a 30min chat over coffee some time next week?:Are you available for a 30min virtual meeting some time next week?]\n\n**They agree and ask to send an invite**\nThank you so much, I can drop you a calendar invite. May I have your email ID?\n\n**They confirm or give other way to contact**\n<Confirm Email Details> Got it! Really appreciate it and looking forward to our chat."
      }
    ]
  },
]

export {COLD_CALL, DISCOVERY}
//export {FOLLOW_TALKING_POINTS, HANDLE_A_SITUATION, APPLY_A_TACTIC, SELL_TO_A_PERSONA}
//export {PLAY_STAGE_HELPER_CONTENT, OVERALL_EVALUATION}
//export {DEFAULT_TRACKS}
//export {ABOUT_PROSPECT_COMPANY, THE_CHALLENGE, THE_SOLUTION, BENEFITS_AND_RESULTS}
export {BACKEND_ADDRESS}
export {PERSONAS, STAGE_MAPPING}
export {CAMPAIGN_STAGES, TEMPLATE_COPY}
