import "@assistant-ui/react/styles/index.css";
//import MyModelAdapter from "./myRuntimeProvider.tsx";
import { BACKEND_ADDRESS } from "../utils/constants";
import {
  AssistantRuntimeProvider,
  useLocalRuntime,
  useMessage,
  useAssistantRuntime,
  type ChatModelAdapter,
} from "@assistant-ui/react";
import {
  Thread,
  ThreadWelcome,
  Composer,
  type ThreadConfig,
} from "@assistant-ui/react";
import { PERSONAS } from "../utils/constants.js";
import { Typography, Avatar } from "@mui/material";
import { useEffect } from "react";
import axios from "axios"

const ChatUI = (props) => {
  const MyModelAdapter: ChatModelAdapter = {
    async run({ messages, abortSignal }) {
      // TODO replace with your own API
      const result = await fetch(BACKEND_ADDRESS + '/positioning/assistant/', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        // forward the messages in the chat to the API
        body: JSON.stringify({
          messages,
          all_cp: props.all_cp
        }),
        // if the user hits the "cancel" button or escape keyboard key, cancel the request
        signal: abortSignal,
      });

      const data = await result.json();
      //messages.push({role: "assistant", content: [{text: data["text"]}]})
      //console.log(messages)

      axios.post(BACKEND_ADDRESS + '/positioning/preview/', {messages: messages, id: props.id, account_id: props.account_id,
        all_cp: props.all_cp, all_narratives: props.all_narratives, all_maturities: props.all_maturities})
      .then(response => {
        const result = response.data
        props.callback(result["positioning"])
      })

      return {
        content: [
          {
            type: "text",
            text: data.text,
          },
        ],
      };
    },
  };

  const runtime = useLocalRuntime(MyModelAdapter);

  return (
    <div style={{height: "80vh", width: "100%"}}>
      <AssistantRuntimeProvider runtime={runtime}>
        <Thread.Root>
          <Thread.Viewport>
            <ThreadWelcome.Root>
              <ThreadWelcome.Center>
                <Avatar sx={{width: "40px", height: "40px"}} src={PERSONAS[0]} alt="positioning"></Avatar>
                <ThreadWelcome.Message message={<><Typography variant="h6" align="center" sx={{ fontWeight: 'bold' }}>Positioning Assistant</Typography><Typography align="center">Struggling to get your buyers to understand the value of your product? Avoid confusion and skip the guess work, position your product for closing deals today!</Typography></>} />
              </ThreadWelcome.Center>
              {props.subMode === "add_positioning"?
                <div className="aui-thread-welcome-suggestion-container">
                  <ThreadWelcome.Suggestion suggestion={{text: "What is Positioning?", prompt: "Explain product positioning"}} />
                  <ThreadWelcome.Suggestion suggestion={{text: "Create Positioning", prompt: "Create Positioning"}} />
                </div>
                :
                <div className="aui-thread-welcome-suggestion-container">
                  <ThreadWelcome.Suggestion suggestion={{text: "Edit Positioning", prompt: "Edit Positioning"}} />
                </div>
              } 
              {/*
              <div className="aui-thread-welcome-suggestion-container">
                <ThreadWelcome.Suggestion suggestion={{text: "Why should I work on positioning?", prompt: "Why should I work on positioning?"}} />
                <ThreadWelcome.Suggestion suggestion={{text: "Help me differentiate from competition", prompt: "Help me differentiate from competition"}} />
              </div>
              */}
            </ThreadWelcome.Root>
            <Thread.Messages />
            <Thread.FollowupSuggestions />
            <Thread.ViewportFooter>
              <Thread.ScrollToBottom />
              <Composer />
            </Thread.ViewportFooter>
          </Thread.Viewport>
        </Thread.Root>
      </AssistantRuntimeProvider>
    </div>
  );
};

export default ChatUI
