import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import FolderSharedOutlinedIcon from '@mui/icons-material/FolderSharedOutlined';
import ControlCameraIcon from '@mui/icons-material/ControlCamera';

import { NavLink, withRouter } from "react-router-dom";
import Aspiro from "./Aspiro"

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

function HomeIcon(props) {
    return (
      <svg width={24} height={24} viewBox="0 0 40 40" fill="rgba(0, 0, 0, 0.7)" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.499 11.716a1.304 1.304 0 011.334 2.14l-1.174.869a2.45 2.45 0 00-.884 1.87v13.26a3.942 3.942 0 003.928 3.913h6.666v.03a1.304 1.304 0 110 2.608h-6.666a6.55 6.55 0 01-6.536-6.536V16.594a5.043 5.043 0 011.956-3.913l1.203-.898.173-.067zm8.537-7.05a6.014 6.014 0 017.536 0l10.015 7.986a5.087 5.087 0 011.898 3.913l-.014 13.29a6.536 6.536 0 01-6.536 6.536h-2.899a3.174 3.174 0 01-3.174-3.16v-6l-.013-.15a.84.84 0 00-.827-.69h-4.348a.84.84 0 00-.84.855v2.29a1.304 1.304 0 01-2.61 0v-2.29a3.464 3.464 0 013.464-3.463h4.348a3.464 3.464 0 013.45 3.463v5.986c0 .304.246.55.55.55h2.971a3.913 3.913 0 003.913-3.912V16.594a2.45 2.45 0 00-.927-1.884L21.978 6.725a3.42 3.42 0 00-4.348 0L14.413 9.13a1.33 1.33 0 01-1.55-2.159l3.173-2.304z"
      />
    </svg>
    );
  }

  function PlayIcon(props) {
    return (
      <svg width={24} height={24} viewBox="0 0 40 40" fill="rgba(0, 0, 0, 0.7)" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M34.365 26.48H24.299c-.297 0-.581.116-.791.323-.21.207-.328.487-.328.78 0 .876-.353 1.717-.982 2.337a3.38 3.38 0 01-2.373.969 3.38 3.38 0 01-2.373-.969 3.282 3.282 0 01-.982-2.338c0-.292-.118-.572-.328-.78a1.127 1.127 0 00-.79-.322H5.284c-.297 0-.581.116-.79.323-.21.207-.328.487-.328.78v4.408a4.382 4.382 0 001.311 3.116A4.513 4.513 0 008.64 36.4h22.37a4.513 4.513 0 003.162-1.293 4.382 4.382 0 001.311-3.116v-4.409c0-.292-.117-.572-.327-.78a1.127 1.127 0 00-.791-.322zm1.08-11.306L33.22 6.947c-.443-2.264-2.102-3.614-4.446-3.614H10.877c-1.174 0-2.167.323-2.943.96-.776.638-1.275 1.52-1.503 2.65l-2.227 8.231a1.08 1.08 0 00-.037.284v3.307c0 2.43 2.007 5.51 4.473 5.51h22.37c2.466 0 4.473-3.08 4.473-5.51v-3.307c0-.096-.012-.191-.037-.284zm-2.718-.818h-8.428a1.121 1.121 0 00-.788.316 1.09 1.09 0 00-.33.773c0 .877-.354 1.718-.983 2.339a3.38 3.38 0 01-2.373.968 3.38 3.38 0 01-2.373-.968 3.283 3.283 0 01-.982-2.339 1.089 1.089 0 00-.33-.773 1.121 1.121 0 00-.789-.316H6.923a.14.14 0 01-.137-.111.136.136 0 01.002-.063L8.62 7.398c.248-1.268.966-1.86 2.257-1.86h17.896c1.3 0 2.016.587 2.254 1.85l1.835 6.794a.136.136 0 01-.073.16.141.141 0 01-.062.014z"
      />
    </svg>
    );
  }

  function StatIcon(props) {
    return (
      <svg width={24} height={24} viewBox="0 0 40 40" fill="rgba(0, 0, 0, 0.7)" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd"
        d="M27.265 1.667c5.623 0 9.402 3.947 9.402 9.821v13.69c0 5.875-3.779 9.822-9.402 9.822h-14.53c-5.623 0-9.402-3.947-9.402-9.822v-13.69c0-5.874 3.779-9.821 9.402-9.821h14.53zm0 2.325h-14.53c-4.298 0-7.076 2.941-7.076 7.496v13.69c0 4.555 2.778 7.496 7.076 7.496h14.53c4.3 0 7.076-2.94 7.076-7.496v-13.69c0-4.555-2.777-7.496-7.076-7.496zM12.823 14.384c.642 0 1.163.52 1.163 1.162v10.636a1.163 1.163 0 01-2.325 0V15.547c0-.642.52-1.163 1.162-1.163zm7.236-5.092c.642 0 1.163.521 1.163 1.163v15.726a1.163 1.163 0 01-2.326 0V10.455c0-.642.521-1.163 1.163-1.163zm7.117 10.712c.642 0 1.163.52 1.163 1.163v5.014a1.163 1.163 0 01-2.326 0v-5.014c0-.642.521-1.163 1.163-1.163z"
      />
    </svg>
    );
  }

  function IntegrationsIcon(props){
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="rgba(0, 0, 0, 0.7)"  viewBox="0 0 20 20" {...props} >
        <path d="M0 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2H2a2 2 0 0 1-2-2V2zm5 10v2a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1h-2v5a2 2 0 0 1-2 2H5zm6-8V2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h2V6a2 2 0 0 1 2-2h5z" />
      </svg>
    )
  }

  function SettingIcon(props) {
    return (
      <svg width={29} height={24} viewBox="0 0 48 40" fill="rgba(0, 0, 0, 0.7)" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
          d="M21.195 3.333c1.26 0 2.402.7 3.032 1.734.307.5.511 1.117.46 1.766-.034.5.12 1 .392 1.467.868 1.417 2.793 1.95 4.292 1.15a3.52 3.52 0 014.786 1.267l1.141 1.966a3.379 3.379 0 01-1.26 4.717c-1.448.85-1.959 2.734-1.09 4.167.272.45.579.833 1.056 1.067.596.316 1.056.816 1.38 1.316.63 1.034.578 2.3-.035 3.417l-1.192 2a3.55 3.55 0 01-3.015 1.733c-.596 0-1.26-.167-1.805-.5-.443-.283-.954-.383-1.5-.383-1.685 0-3.099 1.383-3.15 3.033 0 1.917-1.567 3.417-3.526 3.417h-2.316c-1.976 0-3.543-1.5-3.543-3.417-.034-1.65-1.448-3.033-3.134-3.033-.562 0-1.073.1-1.499.383-.545.333-1.226.5-1.805.5a3.556 3.556 0 01-3.032-1.733l-1.175-2c-.63-1.084-.665-2.384-.035-3.417.273-.5.784-1 1.363-1.316.477-.234.784-.617 1.073-1.067.852-1.433.34-3.317-1.107-4.167a3.407 3.407 0 01-1.26-4.716l1.14-1.967a3.54 3.54 0 014.804-1.267c1.482.8 3.406.267 4.275-1.15.273-.467.426-.967.392-1.467a2.975 2.975 0 01.477-1.766 3.658 3.658 0 013.015-1.734h2.401zM20.02 15.3c-2.674 0-4.837 2.1-4.837 4.717 0 2.617 2.163 4.7 4.837 4.7s4.786-2.083 4.786-4.7S22.694 15.3 20.02 15.3z"
        />
      </svg>
    );
  }

  function LogOutIcon(props) {
    return (
      <svg width={24} height={24} viewBox="0 0 40 40" fill="rgba(0, 0, 0, 0.7)" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.552 7.5c3.474 0 6.3 2.902 6.3 6.47v1.358c0 .604-.477 1.094-1.065 1.094s-1.065-.49-1.065-1.094V13.97c0-2.362-1.87-4.281-4.17-4.281H9.63c-2.296 0-4.165 1.919-4.165 4.281V30.2c0 2.361 1.869 4.28 4.165 4.28h6.94c2.289 0 4.153-1.913 4.153-4.264V28.84c0-.604.477-1.094 1.065-1.094s1.066.49 1.066 1.094v1.375c0 3.558-2.821 6.452-6.285 6.452H9.63c-3.47 0-6.296-2.901-6.296-6.468v-16.23c0-3.567 2.825-6.469 6.296-6.469h6.923zm11.476 9.558l4.158 4.251c.037.038.07.077.1.119l-.1-.119a1.074 1.074 0 01.303.62 1.815 1.815 0 01.011.154l-.007.09-.003.059-.001.007.011-.156a1.122 1.122 0 01-.213.656c-.033.046-.07.089-.11.128l-4.15 4.243a1.046 1.046 0 01-1.505-.003 1.114 1.114 0 01.003-1.546l2.33-2.384H14.333c-.588 0-1.065-.49-1.065-1.094 0-.603.477-1.093 1.065-1.093h14.523l-2.332-2.383a1.113 1.113 0 01-.003-1.546 1.044 1.044 0 011.506-.003z"
        fill="#fff"
      />
    </svg>
    );
  }

  function TeamIcon(props){
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={24}
      height={24} viewBox="0 0 24 24" fill="rgba(0, 0, 0, 0.7)" {...props} >
        <g><rect fill="none" height="24" width="24"/></g>
        <g><g/>
        <g>
          <g><path d="M16.67,13.13C18.04,14.06,19,15.32,19,17v3h4v-3 C23,14.82,19.43,13.53,16.67,13.13z" fillRule="evenodd"/></g>
          <g><circle cx="9" cy="8" fillRule="evenodd" r="4"/></g>
          <g><path d="M15,12c2.21,0,4-1.79,4-4c0-2.21-1.79-4-4-4c-0.47,0-0.91,0.1-1.33,0.24 C14.5,5.27,15,6.58,15,8s-0.5,2.73-1.33,3.76C14.09,11.9,14.53,12,15,12z" fillRule="evenodd"/></g>
          <g><path d="M9,13c-2.67,0-8,1.34-8,4v3h16v-3C17,14.34,11.67,13,9,13z" fillRule="evenodd"/></g>
        </g></g>
      </svg>
    )
  }

  function HelpIcon(props){
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-question-square" viewBox="0 0 16 16" {...props}>
        <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
        <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
      </svg>
    )
  }

function TracksIcon(props){
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 47 47"  fill="rgba(0, 0, 0, 0.7)" {...props}>
      <path d="M21.5417 41.125H9.79167C7.6375 41.125 5.875 39.3625 5.875 37.2083V9.79167C5.875 7.6375 7.6375 5.875 9.79167 5.875H21.5417V41.125ZM25.4583 41.125H37.2083C39.3625 41.125 41.125 39.3625 41.125 37.2083V23.5H25.4583V41.125ZM41.125 19.5833V9.79167C41.125 7.6375 39.3625 5.875 37.2083 5.875H25.4583V19.5833H41.125Z"/>
    </svg>
  )
}

function BuyerCompanyIcon(props){
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 41 33"  fill="rgba(0, 0, 0, 0.7)" {...props}>
      <path d="M36.166 0.832031H4.83268C2.65893 0.832031 0.935599 2.57495 0.935599 4.7487L0.916016 28.2487C0.916016 30.4224 2.65893 32.1654 4.83268 32.1654H36.166C38.3398 32.1654 40.0827 30.4224 40.0827 28.2487V4.7487C40.0827 2.57495 38.3398 0.832031 36.166 0.832031ZM36.166 28.2487H4.83268V16.4987H36.166V28.2487ZM36.166 8.66536H4.83268V4.7487H36.166V8.66536Z"/>
    </svg>
  )
}

function CampaignIcon(props){
  return (
    <svg fill="000000" height="24" width="24" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 236.11 236.11" fill="rgba(0, 0, 0, 0.7)" transform="matrix(1, 0, 0, 1, 0, 0)rotate(0)" strokeWidth="0.00236108" {...props}>
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" stroke="#CCCCCC" strokeWidth="0.9444320000000002"></g>
    <g id="SVGRepo_iconCarrier"><g>
    <path d="M7.5,69.21c-4.142,0-7.5,3.358-7.5,7.5v58.54c0,4.142,3.358,7.5,7.5,7.5s7.5-3.358,7.5-7.5V76.71 C15,72.568,11.642,69.21,7.5,69.21z"></path>
    <path d="M166.428,25.459h-31.204c-4.142,0-7.5,3.358-7.5,7.5v6.775L27.289,69.517c-3.184,0.944-5.368,3.87-5.368,7.19v58.512 c-0.002,0.375,0.034,0.753,0.09,1.132c0.01,0.07,0.02,0.14,0.032,0.209c0.056,0.31,0.124,0.62,0.221,0.928l21.261,67.9 c1.004,3.207,3.963,5.261,7.155,5.261c0.741,0,1.495-0.111,2.24-0.344l30.961-9.681c1.899-0.594,3.484-1.918,4.406-3.68 c0.923-1.762,1.107-3.819,0.513-5.718l-10.536-33.669l49.459,14.67v6.774c0,4.142,3.358,7.5,7.5,7.5h31.204 c4.142,0,7.5-3.358,7.5-7.5V32.959C173.928,28.817,170.57,25.459,166.428,25.459z M72.245,188.546l-16.646,5.205l-14.814-47.311 l20.154,5.977L72.245,188.546z M69.078,139.187c-0.021-0.006-0.043-0.013-0.064-0.019l-32.092-9.519V82.306l90.803-26.926v101.202 L69.078,139.187z M158.928,171.502h-16.204v-4.873V45.333v-4.873h16.204V171.502z"></path>
    <path d="M224.962,147.969l-32.452-16.229c-3.706-1.851-8.21-0.352-10.063,3.354c-1.853,3.705-0.351,8.21,3.354,10.063 l32.452,16.229c1.077,0.539,2.221,0.793,3.349,0.793c2.751,0,5.4-1.519,6.714-4.147 C230.168,154.327,228.666,149.822,224.962,147.969z"></path>
    <path d="M189.161,81.012c1.127,0,2.272-0.255,3.348-0.794l32.453-16.227c3.705-1.853,5.207-6.357,3.354-10.062 c-1.852-3.705-6.358-5.207-10.062-3.354l-32.453,16.227c-3.705,1.853-5.207,6.357-3.354,10.062 C183.761,79.493,186.41,81.012,189.161,81.012z"></path>
    <path d="M228.608,98.48h-27.098c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h27.098c4.142,0,7.5-3.358,7.5-7.5 C236.108,101.837,232.75,98.48,228.608,98.48z"></path>
    </g></g>
    </svg>
  )
}

function AgentIcon(props){
  return (
    <svg fill="rgba(0, 0, 0, 0.7)" height="24" width="24" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
	 viewBox="0 0 511.999 511.999" >
    <g>
    	<g>
    		<circle cx="256.001" cy="76.406" r="12.351"/>
    	</g>
    </g>
    <g>
    	<g>
    		<circle cx="256.001" cy="127.721" r="12.351"/>
    	</g>
    </g>
    <g>
    	<g>
    		<circle cx="256.001" cy="416.84" r="12.351"/>
    	</g>
    </g>
    <g>
    	<g>
    		<path d="M255.999,166.156c-6.821,0-12.351,5.53-12.351,12.351v38.817c0,6.821,5.53,12.351,12.351,12.351
    			c6.821,0,12.351-5.53,12.351-12.351v-38.817C268.35,171.686,262.82,166.156,255.999,166.156z"/>
    	</g>
    </g>
    <g>
    	<g>
    		<path d="M504.076,267.55h-12.745c0.191-3.892,0.299-7.756,0.299-11.552s-0.108-7.661-0.299-11.552c0,0,12.682,0,12.748,0
    			c4.572,0,8.247-3.984,7.897-8.529c-2.272-29.511-9.507-58.056-21.506-84.84c-1.276-2.849-4.107-4.683-7.229-4.683h-18.551
    			c-4.184-7.941-8.849-15.661-13.942-23.105c0,0,10.543,0,10.616,0c6.479,0,10.211-7.798,6.207-12.843
    			c-8.873-12.841-18.971-24.951-30.02-36C396.938,33.834,345.559,8.555,288.967,1.342c-4.664-0.595-8.922,3.163-8.922,7.856v13.344
    			c-8.013-0.82-16.068-1.239-24.047-1.239c-7.979,0-16.033,0.419-24.046,1.239c0,0,0-13.329,0-13.344
    			c0-4.698-4.263-8.45-8.922-7.856C166.44,8.555,115.06,33.834,74.449,74.447C63.276,85.621,53.076,97.878,44.131,110.88
    			c-3.549,5.16,0.284,12.41,6.525,12.41h10.594c-5.094,7.444-9.759,15.165-13.942,23.105H28.756c-3.122,0-5.952,1.833-7.229,4.683
    			C9.53,177.864,2.295,206.408,0.023,235.917c-0.35,4.54,3.352,8.529,7.897,8.529h12.748c-0.379,7.701-0.379,15.404,0,23.105
    			c0,0-12.725,0-12.745,0c-4.52,0-8.244,4.027-7.897,8.528c2.55,33.152,11.325,64.944,26.082,94.492
    			c1.341,2.685,4.085,4.382,7.086,4.382h19.324c4.679,7.971,9.854,15.694,15.477,23.105c0,0-10.568,0-10.592,0
    			c-6.425,0-10.195,7.585-6.311,12.707c41.479,54.697,103.797,91.206,171.942,99.893c0.334,0.043,0.669,0.064,1.001,0.064
    			c4.318,0,7.921-3.61,7.921-7.921v-13.344c8.012,0.819,16.067,1.239,24.046,1.239c7.979,0,16.033-0.419,24.047-1.239
    			c0,0,0,13.332,0,13.344c0,4.304,3.624,7.921,7.921,7.921c0.333,0,0.667-0.021,1.001-0.064
    			c67.948-8.702,130.585-45.358,171.941-99.891c3.875-5.108,0.096-12.708-6.31-12.708h-10.592
    			c5.622-7.411,10.797-15.133,15.477-23.105h19.325c3.001,0,5.745-1.696,7.085-4.382c14.758-29.548,23.532-61.339,26.082-94.49
    			C512.325,271.509,508.656,267.55,504.076,267.55z M71.106,374.952h15.698c6.37,0,11.552,5.182,11.552,11.552
    			c0,0.38-0.02,0.76-0.06,1.137c-0.558,5.293-4.797,9.676-10.091,10.321C82.002,390.654,76.283,382.955,71.106,374.952z
    			 M216.114,493.638h-0.001c-49.554-8.179-94.501-31.327-130.463-67.289c-4.001-4.001-7.88-8.162-11.61-12.452
    			c0,0,12.713,0,12.765,0c35.271,0,35.419-54.787,0-54.787H57.089H38.146c-11.338-23.899-18.558-49.31-21.521-75.719h12.496
    			c0,0,57.536,0,57.681,0c35.236,0,35.408-54.787,0-54.787H29.121H16.623c2.579-22.958,8.39-45.225,17.324-66.37h18.185
    			c0,0,34.557,0,34.672,0c35.04,0,35.485-54.787,0-54.787H66.313c6.005-7.642,12.475-14.939,19.338-21.8
    			c35.961-35.961,80.909-59.109,130.463-67.288V493.638z M36.21,255.999c0-3.792,0.103-7.656,0.308-11.552h50.286
    			c6.37,0,11.552,5.182,11.552,11.552c0,6.37-5.182,11.552-11.552,11.552H36.519C36.313,263.656,36.21,259.791,36.21,255.999z
    			 M65.387,146.394c4.618-7.995,9.76-15.725,15.371-23.105c0,0,6.031,0,6.046,0c14.796,0,14.942,23.105,0,23.105H65.387z
    			 M280.046,473.53c-16.016,1.755-32.076,1.755-48.092,0V38.467c16.016-1.756,32.076-1.756,48.092,0V473.53z M423.794,397.962
    			c-4.194-0.512-7.961-3.415-9.409-7.408c-2.698-7.445,2.814-15.602,10.81-15.602h15.698
    			C435.717,382.955,429.998,390.653,423.794,397.962z M473.852,359.111H454.91h-29.716c-15.105,0-27.393,12.288-27.393,27.393
    			c0,15.105,12.288,27.393,27.393,27.393h12.765c-3.73,4.29-7.61,8.45-11.61,12.452c-35.961,35.961-80.91,59.109-130.462,67.289
    			V18.36c49.553,8.179,94.501,31.327,130.462,67.288c6.861,6.861,13.332,14.159,19.337,21.8c0,0-20.453,0-20.491,0
    			c-32.92,0-36.501,49.978-3.795,54.525c1.257,0.175,38.467,0.262,38.467,0.262h18.184c8.933,21.144,14.744,43.411,17.324,66.37
    			h-12.497c0,0-57.531,0-57.682,0c-35.239,0-35.404,54.787,0,54.787h57.682h12.496C492.412,309.801,485.191,335.212,473.852,359.111
    			z M446.612,146.394c0,0-21.389,0-21.418,0c-14.873,0-14.881-23.105,0-23.105h6.046
    			C436.852,130.668,441.994,138.398,446.612,146.394z M475.789,255.999c0,3.791-0.104,7.656-0.308,11.552c0,0-50.082,0-50.286,0
    			c-14.919,0-14.936-23.105-0.001-23.105h50.286C475.686,248.343,475.789,252.207,475.789,255.999z"/>
    	</g>
    </g>
    </svg>
  )
}

export default function MiniDrawer() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };


  const categoryNames = ["", "Define", "Validate", "Settings"]
  const options = [['Home'], ['Customer Profiles', 'Positioning'], ['Leads', 'Campaigns'], ["Team", "Settings"]]
  const icons = [[<HomeIcon />], [<BuyerCompanyIcon />, <ControlCameraIcon sx={{color: "rgba(0, 0, 0, 0.7)"}} />], [<FolderSharedOutlinedIcon sx={{color: "rgba(0, 0, 0, 0.7)"}}/>, <CampaignIcon />], [<PeopleOutlineIcon sx={{color: "rgba(0, 0, 0, 0.7)"}} />, <SettingsOutlinedIcon sx={{color: "rgba(0, 0, 0, 0.7)"}} />]]
  const links = [['/home'], ['/icp', '/positioning'], ['/leads', '/campaigns'], ["/team", "/settings"]]

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Drawer variant="permanent" open={open} style={{zIndex: 6}}>
        <DrawerHeader sx={{m: "0 4"}}>
          {open?
          <>
            <Aspiro />
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon color="primary"/>}
            </IconButton>
          </>
          :
          <IconButton color="inherit" onClick={handleDrawerOpen}
          sx={{...(open && { display: 'none' })}}>
            <MenuIcon color="primary"/>
          </IconButton>
          }
        </DrawerHeader>
        <Divider />
        {categoryNames.map((category, ind1) =>
          <React.Fragment key={ind1}>
            <List>
              {categoryNames[ind1] !== ""?<Typography sx={open?{px: 1.5}:{px: 0.5, textAlign: "center"}} fontSize={open?16:11} color="primary">{categoryNames[ind1]}</Typography>:<></>}
              {options[ind1].map((text, ind2) => (
              <NavLink key={ind2} to={links[ind1][ind2]} style={{textDecoration: 'none', color: 'black'}}>
                <ListItem key={text} disablePadding sx={{display: 'block'}} selected={window.location.pathname.slice(0, links[ind1][ind2].length) === links[ind1][ind2]}>
                  <ListItemButton sx={{minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5}} style={open?{}:{flexDirection: 'column', alignItems: 'center'}}>
                    <ListItemIcon sx={{minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center'}}>
                      {icons[ind1][ind2]}
                    </ListItemIcon>
                    <ListItemText primary={text} style={open?{}:{textAlign: 'center'}} primaryTypographyProps={open?{whiteSpace: 'normal', wordwrap: 'break-word'}:{fontSize: "11", whiteSpace: 'normal', wordwrap: 'break-word'}}/>
                  </ListItemButton>
                </ListItem>
                </NavLink>
              ))}
            </List>
            <Divider />
          </React.Fragment>
        )}
      </Drawer>
    </Box>
  );
}
