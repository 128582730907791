import {useState, useEffect} from "react"
import { useHistory } from "react-router-dom";
import { BACKEND_ADDRESS } from "./getEndpoint"
import axios from "axios"
import { useSelector } from "react-redux";

import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import Box from "@mui/material/Box"
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Paper from "@mui/material/Paper";

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';

const steps = [
  "Describe your Potential Customers: Create a new Customer Profile (CP) or edit an existing one",
  "Add leads that belong to that CP",
  "Run a Campaign to gather market feedback.",
  "Analyse CP performance using Aspiro dashboard. Validate/discard customer assumptions.",
  "Repeat steps 1-4 until repeatable and monetizable customer behaviours are evident."
]
const ctaLinks = ["/icp", "/leads", "/campaigns", "/home", ""]
const ctaText = ["Create/Edit Customer Profile", "Add Leads", "Create Campaign", "View Dashboard", ""]

export default function ProductHelp(props) {
  const [open, setOpen] = useState(localStorage.getItem("howTo") === "false"?false:true)
  const [status, setStatus] = useState([false, false, false, false, false])

  const account_id = useSelector((state) => state.account.value);
  const history = useHistory()

  useEffect(() => {
    var newStatus = JSON.parse(JSON.stringify(status))

    axios.get(BACKEND_ADDRESS + '/account/', {params: {account_id: account_id}})
    .then((response) => {
      if(response.data["customer_profiles"].length > 0) newStatus[0] = true
      if(response.data["lead_lists"].length > 0) newStatus[1] = true
      if(response.data["campaigns"].length > 0) newStatus[2] = true

      if(newStatus[0] && newStatus[1] && newStatus[2]){
        newStatus[3] = true
        newStatus[4] = true
        //setOpen(false) //if all steps are done, keep it minimized
      }
      setStatus(newStatus)
    })
  }, [open])

  const handleDontShow = () => {
    localStorage.setItem('howTo', 'false');
    setOpen(false)
  }

  return (
    <>
    {open?
      <Paper elevation={4} sx={{width: "350px", position: "fixed", bottom: "0", right: "0", left: "auto", top: "auto", zIndex: 7}}>
        <Stack direction="row" spacing={1} alignItems="center" sx={{backgroundColor:"primary.main", color: "white", p:1.5}}>
          <IconButton onClick={() => setOpen(false)} sx={{color: "white"}}><KeyboardArrowDownIcon size="small"/></IconButton>
          <Typography variant="h6"><b>How Does it Work?</b></Typography>
        </Stack>
        <Stack direction="column" sx={{p:1.5}}>
          <Typography sx={{m:1}}>Aspiro helps founders identify and sell to their ideal customers by iteratively elliminating irrelevant ones.</Typography>
          {steps.map((x, ind) =>
            <Box sx={{p:1}} key={ind}>
              <Stack direction="column" alignItems="flex-start" spacing={1}>
                <Typography>
                  {status[ind]?
                    <CheckCircleIcon sx={{verticalAlign: 'middle', fontSize: '20', color:"#3f8f29"}} />
                    :<PendingIcon sx={{verticalAlign: 'middle', fontSize: '20', color: "#ffc232"}} />
                  }
                  <b>{" Step " + String(ind+1) + ": "}</b>
                  {steps[ind]}
                </Typography>
                {!status[ind] && ctaText[ind] !== ""?
                  <Button variant="contained" size="small" onClick={() => {history.push(ctaLinks[ind]);setOpen(false)}}>
                    {ctaText[ind]}
                  </Button>
                  :<></>
                }
              </Stack>
            </Box>
          )}
          <center><Button onClick={handleDontShow}>Don't Show this again</Button></center>
        </Stack>
      </Paper>
      :
      <Paper sx={{backgroundColor: "primary.main", color:"white", width: "250px", position: "fixed", bottom: "0", right: "0", left: "auto", top: "auto", zIndex: 7, padding: "6px 12px"}}>
        <Stack direction="row" spacing={1} alignItems="center">
          <IconButton onClick={() => setOpen(true)}><KeyboardArrowUpIcon size="small" sx={{color:"white"}}/></IconButton>
          <Typography>How Does it Work?</Typography>
        </Stack>
      </Paper>
    }
    </>
  )
}
