import React, {useEffect, useState} from "react"
import {useSelector} from "react-redux"
import { useHistory } from "react-router-dom";
import {Box, Grid, Typography, TableCell, TableContainer, TableRow, TableBody, TableHead, Table, Paper, Stack, Chip, FormControl, Select, MenuItem, Avatar, Button, IconButton, Tooltip, Skeleton, TextField} from '@mui/material';
import { PERSONAS, BACKEND_ADDRESS } from "./../utils/constants"
import ToastComponent from "./../utils/ToastComponent";
import ShareIcon from '@mui/icons-material/Share';
import InfoIcon from '@mui/icons-material/Info';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import axios from "axios"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoneIcon from '@mui/icons-material/Done';
import Aspiro from "../Aspiro";
import CustomerProfileScore from "../customerProfile/CustomerProfileScore";

const allCpAttributes = [
  {value: "industries", name: "Industry"},
  {value: "verticals", name: "Vertical"},
  {value: "company_size", name: "Company Size"},
  {value: "team_size", name: "Team Size"},
  {value: "geographies", name: "Geography"},
  {value: "buying_signals_tech", name: "Technical Buying Signals"},
  {value: "buying_signals_non_tech", name: "Non-Technical Buying Signals"},
]

var allCpAttributesMap = {}
for(var i=0;i<allCpAttributes.length;i+=1){
  allCpAttributesMap[allCpAttributes[i]["value"]] = allCpAttributes[i]["name"]
}

const stakeholderDescription = {
  "Champion": "The advocate who pushes for the product or service within the organization",
  "Decision Maker": "The individual with final authority to make the purchase decision",
  "Executive Sponsor": "A senior leader who looks/sponsors the deal from an ROI, budget, and a big picture perspective",
  "End User": "The individual who will use the product or service directly",
  "Influencer": "An individual who has a following and whose opinion influences the decision-making process",
  "Budget Holder": "The person responsible for approving and controlling the budget",
  "Legal & Compliance": "The representative who ensures that the purchase meets all legal and regulatory requirements",
  "Blocker": "An individual who may resist or oppose adoption of the offering"
}

const getTopPersonas = (persona_map) => {
  var items = Object.keys(persona_map).map((key) => {return {name: key, value: persona_map[key], index:1}})

  // Sort the array based on the second element
  items.sort((first, second) => second["value"] - first["value"]);

  //filter elements with 0 count. And show max 5 elements
  const result = items.slice(0, 5).filter((element) => element["value"] !== 0)

  if(result.length === 0) return ([{"name": "BLANK"}])
  else return result
}

const getMaxPersonaCount = (cp) => {
  var maxInPersona = cp["personas"].map(x => Object.values(x["buyer_role"]).reduce((total, y) => Math.max(total, y), 0))
  var maxOverall = maxInPersona.reduce((total, x) => Math.max(total, x), 0)

  return Math.min(maxOverall, 80) //max 80 because graph disorients beyond it
}

function getTimestampFromObjectId(objectId) {
  const timestampHex = objectId.substring(0, 8); // Extract first 8 characters (timestamp part)
  const timestampDecimal = parseInt(timestampHex, 16); // Convert hex to decimal
  return timestampDecimal * 1000
}

export default function Dashboard(props) {
  const [allCp, setAllCp] = useState(null)
  const [cpScorecard, setCpScorecard] = useState(null)
  const [activeCp, setActiveCp] = useState("")
  const [activeCpId, setActiveCpId] = useState('')
  const [state, setState] = useState('loading') //loading/ready/empty (empty when no CP created)
  const [copied, setCopied] = useState(false)
  const [accountName, setAccountName] = useState("")
  const [showSharpness, setShowSharpness] = useState(true)
  const [sharpness, setSharpess] = useState(0)

  let history = useHistory()
  var acc = useSelector((state) => state.account.value)
  var account_id =  props.match?props.match.params.a_id:acc

  const maxPersonaCount = activeCp !== ""?getMaxPersonaCount(allCp[activeCp]):0

  useEffect(() => {
    if(props.mode !== "view"){
      props.callback("heading", "Dashboard")
      props.callback("description", "Overview and Action Items")
      props.callback("bg", "#fbfafe")
    }

    window.scrollTo(0,0) //scrolling to top of new screen

    axios.get(BACKEND_ADDRESS + '/account/', {params: {account_id: account_id}})
    .then(response => setAccountName(response.data["company_name"]))

    axios.post(BACKEND_ADDRESS + '/dashboard/buying_potential/', {account_id: account_id})
    .then((response) => {
      var cp_all = response.data["customer_profiles"]
      var cp_scorecard = response.data["cp_scorecard"]

      setAllCp(cp_all)
      setCpScorecard(cp_scorecard)
      if(cp_all.length > 0){
        setActiveCp(0)
        setActiveCpId(Object.keys(cp_scorecard)[0])
        setState('ready')
      }else{
        setState('empty')
      }
    }).catch(e => ToastComponent("error", "Can't generate Customer Profile Overview"))
  }, [])

  return (
    <>
    <TableContainer component={Paper} sx={props.mode === "view"?{margin: "12"}:{}}>
      <Table size="small">
        <TableHead>
          <TableRow sx={{backgroundColor: "#ebebeb"}}>
            <TableCell colSpan={7} sx={{p: 3}}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
              <Stack direction="column" spacing={1}>
                {props.mode === "view"?<Typography variant="h4">{accountName}</Typography>
                :<Typography variant="h5">Customer Profile Overview</Typography>}
              </Stack>

              <Stack direction="row" alignItems={props.mode === "view"?"flex-end":""}>
                {props.mode === "view"?
                <>
                <Typography>powered by </Typography>
                <Aspiro height="40"/>
                </>
                :
                <>
                <TextField size="small" sx={{width: "400"}} label={window.location.origin + "/dashboard/" + account_id} />
                <Button variant="contained" size="small" startIcon={copied?<DoneIcon />:<ContentCopyIcon />}
                onClick={() => {setCopied(true); navigator.clipboard.writeText(window.location.origin + "/dashboard/" + account_id)}}>
                  {copied?"Copied to Clipboard":"Share Public Link"}
                </Button>
                </>
                }
              </Stack>
            </Stack>
            <br />
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction="column" spacing={1}>
                <Typography>Choose Customer Profile: </Typography>
                <FormControl variant="standard" sx={{width: "300px"}}>
                  <Select size="small" variant="outlined" value={activeCp} onChange={(e) => {setActiveCp(e.target.value); setActiveCpId(Object.keys(cpScorecard)[e.target.value])}}>
                    {allCp !== null && activeCp !== "" && allCp.map((x, ind) => {
                      return (<MenuItem key={ind} value={ind}>{x["name"]}</MenuItem>)
                    })}
                  </Select>
                </FormControl>
              </Stack>

              <Stack direction="column" alignItems="center" spacing={1}>
                {state === "ready" && cpScorecard !== null && activeCpId !== ""?
                <Typography variant="h3">{Math.round(1000*cpScorecard[activeCpId]["overall"]["total"]/cpScorecard[activeCpId]["overall"]["maxTotal"])/10 + "%"}</Typography>
                :<Skeleton variant="rectangular" width={50} height={50} />
                }
                <Typography>Buying Potential</Typography>
              </Stack>

              <Stack direction="column" alignItems="center" spacing={1}>
                {state === "ready" && allCp !== null && activeCp !== ""?
                <Typography variant="h3">{sharpness}%</Typography>
                :<Skeleton variant="rectangular" width={50} height={50} />
                }
                <Typography>CP Sharpness &nbsp;
                  {/*<Button disabled={state === "loading"} size="small" variant="outlined" onClick={() => history.push("/icp/" + allCp[activeCp]["_id"]["$oid"] + "#cpsharpness")}>Check</Button>
                  */}
                </Typography>
              </Stack>

              <Stack direction="column" alignItems="center" spacing={1}>
                <Button variant="outlined" onClick={() => history.push("/campaigns")}>Improve CP by creating a Campaign</Button>
                {state === "ready" && allCp !== null && activeCp !== ""?<Typography>{"last_campaign" in allCp[activeCp]?<a href={"/campaigns/"+allCp[activeCp]["last_campaign"] + "/dashboard"}>{"Last Campaign: " + new Date(getTimestampFromObjectId(allCp[activeCp]["last_campaign"])).toLocaleDateString()}</a>:"Last Campaign: N/A"}</Typography>
                :<></>}
              </Stack>
            </Stack>
            </TableCell>
          </TableRow>
          <TableRow sx={{backgroundColor: "#f5f5f5"}}>
            <TableCell colSpan={7} sx={{p: 2}}>
              <Typography><b>Buying Potential</b> (out of 100) refers to long-term chances of finding customers predictably and repeatably in
              your Customer profile. Mathematically, Buying Potential is calculated as combination of Problem
              Validation and Buying Intent.<br /><br /><b>Problem Validation</b> is the process of validating that the problem your offering aims to solve is real and significant for your potential customers to consider solutions.
              <br /><b>Buying Intent</b> is the process of gauging buyer's intent to pay. This is influenced by factors such as budget availability, urgency, and decision-making power within the organization.
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow sx={{backgroundColor: "#f5f5f5"}}>
            <TableCell><Typography><b>Attributes</b></Typography></TableCell>
            <TableCell><Typography><b>Attribute Value</b></Typography></TableCell>
            <TableCell>
              <Stack direction="row" alignItems="center" spacing="2">
                <Typography><b>Problem Validation</b></Typography>
                {/*}<HelpOutlineIcon fontSize="small"/>*/}
              </Stack>
            </TableCell>
            <TableCell>
              <Stack direction="row" alignItems="center" spacing="2">
                <Typography><b>Buying Intent</b></Typography>
                {/*<HelpOutlineIcon fontSize="small"/>*/}
              </Stack>
            </TableCell>
            <TableCell>
              <Typography><b># of Conversations</b></Typography>
            </TableCell>
            <TableCell>
              <Tooltip title="Until you have 5 conversations, Buying Potential is Low because of statistical insignificance. If both Problem Validation is High and Buying intent is High, then Buying Potential is High. If both are Low, then it's Low. In other cases, it's Medium.">
                <Typography><b>Buying Potential</b>&nbsp;
                <HelpOutlineIcon fontSize="small" sx={{verticalAlign: 'middle', fontSize: '18'}}/>
                </Typography>
              </Tooltip>
            </TableCell>
            <TableCell>
              <Typography fontStyle="italic"><b>Recommendation</b></Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allCpAttributes.map((row, id) =>
              <React.Fragment key={id}>
              {allCp !== null && activeCp !== "" && cpScorecard !== null && activeCpId !== ""?
                allCp[activeCp][row.value].map((x, ind) =>
                  <TableRow key={ind} key={row.name} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                    {ind === 0?
                      <TableCell component="th" scope="row" rowSpan={allCp[activeCp][row.value].length}>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography>{row.name}</Typography>
                        </Stack>
                      </TableCell>
                      :<></>
                    }
                    <TableCell>
                      <Typography>{x}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color={cpScorecard[activeCpId][row.value][x]["problem_validation_score"] === "High"?"#00cc33":"red"}>
                        {cpScorecard[activeCpId][row.value][x]["conversations"] > 0?Math.round(10000*Math.max(...cpScorecard[activeCpId][row.value][x]["problem_validation"])/cpScorecard[activeCpId][row.value][x]["conversations"])/100 + "%":"N/A"}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color={cpScorecard[activeCpId][row.value][x]["buying_intent_score"] === "High"?"#00cc33":"red"}>
                      {cpScorecard[activeCpId][row.value][x]["conversations"] > 0?Math.round(10000*Math.max(...cpScorecard[activeCpId][row.value][x]["buying_intent"])/cpScorecard[activeCpId][row.value][x]["conversations"])/100+"%":"N/A "}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color={cpScorecard[activeCpId][row.value][x]["conversations"] < 5?"red":"black"}>{cpScorecard[activeCpId][row.value][x]["conversations"]}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color={cpScorecard[activeCpId][row.value][x]["overall"] === "High"?"#00cc33":cpScorecard[activeCpId][row.value][x]["overall"] === "Medium"?"#FFC000":"red"}>
                        {cpScorecard[activeCpId][row.value][x]["overall"] === "High"?"High (2pts)":cpScorecard[activeCpId][row.value][x]["overall"] === "Medium"?"Medium (1pt)":"Low (0pt)"}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontStyle="italic">
                        {cpScorecard[activeCpId][row.value][x]["conversations"] < 5?
                          "Have >= 5 conversations"
                          :
                          cpScorecard[activeCpId][row.value][x]["overall"] === "Low"?
                          "Discard/Modify Attribute Value"
                          :
                          ""
                        }
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
                :
                <>
                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                  <TableCell component="th" scope="row" rowSpan={2}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography>{row.name}</Typography>
                    </Stack>
                  </TableCell>
                  {Array.apply(null, Array(6)).map((x, id) =>
                    <TableCell key={id}><Skeleton variant="text" /></TableCell>
                  )}
                </TableRow>
                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                  {Array.apply(null, Array(6)).map((x, idd) =>
                    <TableCell key={idd}><Skeleton variant="text" /></TableCell>
                  )}
                </TableRow>
                </>
              }
              </React.Fragment>
          )}
          <TableRow >
            <TableCell align="right" colSpan={5} >
              <Typography variant="h6">Buying Potential (across CP)</Typography>
            </TableCell>
            <TableCell style={{backgroundColor: "#395af6"}} colSpan={1}>
              <Typography color="white">{state === "ready" && cpScorecard !== null && activeCpId !== ""?cpScorecard[activeCpId]["overall"]["total"] + " / " + cpScorecard[activeCpId]["overall"]["maxTotal"] + " = " + Math.round(1000*cpScorecard[activeCpId]["overall"]["total"]/cpScorecard[activeCpId]["overall"]["maxTotal"])/10 + "%":<Skeleton variant="text" />}</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    <br /><br />
    <TableContainer component={Paper} sx={props.mode === "view"?{margin: "12"}:{}}>
      <CustomerProfileScore key={activeCp} mode='dashboard' callback={setSharpess} data={activeCp !== ""?allCp[activeCp]:null} />
    </TableContainer>
    <br /><br />
    <TableContainer component={Paper} sx={props.mode === "view"?{margin: "12"}:{}}>
      <Table size="small">
        <TableHead sx={{backgroundColor: "#f5f5f5"}}>
          <TableRow>
            <TableCell colSpan={7} sx={{p: 2}}>
              <Typography>
                <b>Problem Validation</b> across different buyer problems. Discard buyer problem(s) whose Problem Validation is consistently Low.
                <br /><br />
                Validation Questions-<br />
                Q1. Buyer confirmed the discussed problem as critical?<br />
                Q2. Buyer articulated (with concrete stories & examples) what they are unable to achieve due to problem? <br />
                Q3. Buyer had actively invested money/effort/time/tech/human resources to solve the problem?<br />
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow sx={{backgroundColor: "#f5f5f5"}}>
            <TableCell rowSpan={2} sx={{minWidth:200}}><Typography><b>Buyer Problems</b></Typography></TableCell>
            <TableCell rowSpan={2}>
              <Typography><b># of Conversations (N)</b></Typography>
            </TableCell>
            <TableCell colSpan={3}>
            <Typography><center><b>Validation Questions</b></center></Typography>
            </TableCell>
            <TableCell sx={{minWidth:150}} rowSpan={2}>
              <Tooltip title="High if N >= 5 and Q3/N >= 70%">
                <Typography>
                  <b>Problem Validation</b>
                  <HelpOutlineIcon fontSize="small" sx={{verticalAlign: 'middle', fontSize: '18'}} />
                </Typography>
              </Tooltip>
            </TableCell>
            <TableCell rowSpan={2}>
              <Typography><b>Recommendation</b></Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
                <Typography><b>Q1</b></Typography>
              </TableCell>
              <TableCell>
                <Typography><b>Q2</b></Typography>
              </TableCell>
              <TableCell>
                <Typography><b>Q3</b></Typography>
              </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            allCp !== null && activeCp !== ""?
            allCp[activeCp]["problems"].map((row, id) => (
            <TableRow key={id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
              <TableCell component="th" scope="row">
                <Typography>{row}</Typography>
              </TableCell>
              <TableCell component="th" scope="row">
                <Typography>{allCp[activeCp]["problem_info"]["stats"][id][0]}</Typography>
              </TableCell>
              <TableCell component="th" scope="row">
                <Typography>{allCp[activeCp]["problem_info"]["stats"][id][1]}</Typography>
              </TableCell>
              <TableCell component="th" scope="row">
                <Typography>{allCp[activeCp]["problem_info"]["stats"][id][2]}</Typography>
              </TableCell>
              <TableCell component="th" scope="row">
                <Typography>{allCp[activeCp]["problem_info"]["stats"][id][3]}</Typography>
              </TableCell>

              <TableCell component="th" scope="row">
                {allCp[activeCp]["problem_info"]["validated"][id]?
                  <><Typography variant="h6">{Math.round(10000*allCp[activeCp]["problem_info"]["stats"][id][3]/allCp[activeCp]["problem_info"]["stats"][id][0])/100 + "%"}</Typography><Typography color="#00cc33">High</Typography></>
                  :
                  <><Typography variant="h6">{Math.round(10000*allCp[activeCp]["problem_info"]["stats"][id][3]/allCp[activeCp]["problem_info"]["stats"][id][0])/100 + "%"}</Typography><Typography color="red">Low</Typography></>
                }
              </TableCell>
              <TableCell component="th" scope="row">
                <Typography fontStyle="italic">
                {
                  allCp[activeCp]["problem_info"]["stats"][id][0] < 5?
                  "Have >= 5 conversations"
                  :
                  !allCp[activeCp]["problem_info"]["validated"][id]?
                  "Discard/Modify Buyer Problem"
                  :<></>
                }
                </Typography>
              </TableCell>
            </TableRow>
            ))
            :
            Array.apply(null, Array(3)).map((a, id) =>
            <TableRow key={id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
              {Array.apply(null, Array(7)).map((l, idd) =>
              <TableCell component="th" scope="row" key={idd}>
                <Skeleton variant="text" sx={{ fontSize: '2rem' }}/>
              </TableCell>
              )}
            </TableRow>
            )
          }
        </TableBody>
      </Table>
    </TableContainer>
    <br /><br />
    <TableContainer component={Paper} sx={props.mode === "view"?{margin: "12"}:{}}>
      <Table size="small">
        <TableHead>
          <TableRow sx={{backgroundColor: "#f5f5f5"}}>
            <TableCell colSpan={7} sx={{p: 2}}>
              <Typography>
                <b>Buyer Roles</b> played by different personas. Different roles have different motivations,
                objections and tasks in the buying process. Identifying the roles played by different
                personas helps you in positioning yourself better to them.<br /><br />
                {Object.keys(stakeholderDescription).map((x,ind) =>
                  <React.Fragment key={ind}>{String(ind+1) + ". " + x + ": " + stakeholderDescription[x]}<br/></React.Fragment>
                )}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow sx={{backgroundColor: "#f5f5f5"}}>
            <TableCell><Typography><b>Buyer Personas</b></Typography></TableCell>
            <TableCell><Typography><b>Top Buyer Roles displayed by this Persona</b></Typography></TableCell>
            <TableCell><Typography><b>Buying Intent</b></Typography></TableCell>
            {/*<TableCell><Typography><b>Last Validated</b></Typography></TableCell>
              */}
          </TableRow>
        </TableHead>
        <TableBody>
          {allCp !== null && activeCp !== ""?
            allCp[activeCp]["personas"].map((row, id) => (
            <TableRow key={id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
              <TableCell component="th" scope="row">
                <Stack direction="row" spacing={2} alignItems="center">
                  <Avatar src={allCp[activeCp]["personas"][id]["avatar"] >= 0?PERSONAS[allCp[activeCp]["personas"][id]["avatar"]]:""} sx={{width: "70px", height: "70px"}} />
                  <Typography>{allCp[activeCp]["personas"][id]["avatar"] >= 0?row.designation + ", " + row.vertical:row.designation}</Typography>
                </Stack>
              </TableCell>
              <TableCell>
                <Stack direction="row" spacing="2">
                  {getTopPersonas(row["buyer_role"]).map((x, ind) => {
                    if(x["name"] === "BLANK"){
                      return (<Typography key={ind} variant="subtitle2"><i>No Data to Show</i></Typography>)
                    }
                    return (
                      <Stack key={ind} direction="column" justifyContent="flex-end" alignItems="center">
                        <Box sx={{width: Math.min(x["value"], 80)*80/maxPersonaCount, height: Math.min(x["value"], 80)*80/maxPersonaCount, borderRadius: '50%', backgroundColor: '#395af6', color: 'white', display: 'flex',
                        justifyContent: 'center', alignItems: 'center', zIndex: 1}}>
                          <Typography>{x["value"]}</Typography>
                        </Box>
                        <Typography display="inline" fontSize="11">{(ind === 0?"| ":"") + x["name"] + " | "}</Typography>
                      </Stack>
                    )
                  })}
                </Stack>
              </TableCell>
              <TableCell component="th" scope="row">
                {allCp[activeCp]["personas"][id]["buying_intent_percent"] >= 0.7?
                <><Typography variant="h6">{allCp[activeCp]["personas"][id]["buying_intent_percent"] + "%)"}</Typography><Typography color="#00cc33">High</Typography></>
                :
                <><Typography variant="h6">{Math.round(allCp[activeCp]["personas"][id]["buying_intent_percent"] * 10000) / 100 + "%"}</Typography><Typography color="red">Low</Typography></>
                }
              </TableCell>
            </TableRow>
          ))
          :
          [1, 2].map((x, id) =>
            <TableRow key={id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
              <TableCell component="th" scope="row">
                <Stack direction="row" spacing={2} alignItems="center">
                  <Skeleton variant="circular" width={70} height={70} />
                  <Skeleton variant="text" width={100}/>
                </Stack>
              </TableCell>
              <TableCell>
                <Stack direction="row" spacing="2">
                  {[80, 50, 40].map((x, ind) => {
                    return (
                      <Stack key={ind} direction="column" justifyContent="flex-end" alignItems="center">
                        <Skeleton variant="circular" width={x} height={x} />
                        <Stack direction="row" spacing={1}>
                          <Typography display="inline">{ind === 0?"| ":""}</Typography>
                          <Skeleton display="inline" variant="text" sx={{ fontSize: '1rem' }} width={100} />
                          <Typography display="inline">{" | "}</Typography>
                        </Stack>
                      </Stack>
                    )
                  })}
                </Stack>
              </TableCell>
              <TableCell component="th" scope="row">
                <Skeleton variant="text" />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
      <Dialog open={state === "empty"}>
        <DialogTitle color="primary">
          Nothing to Show Here 🙁
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Create a Customer Profile first, to see your progress here.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setState("loading")} variant="outlined">Close</Button>
          <Button onClick={() => history.push("/icp/")} variant="contained" autoFocus>Create Customer Profile</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
