import React, { useState, useEffect } from 'react';
import {ListSubheader, Avatar, AccordionSummary, AccordionDetails, Typography, Box, IconButton, Button, Stack, Grid, Chip, Paper, TextField, Toolbar} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import PendingIcon from '@mui/icons-material/Pending';
import VisibilityIcon from '@mui/icons-material/Visibility';

import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider'

import TipBox from "../utils/TipBox.js"

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import LogCall from "./LogCall.js"
import ContactLead from "./ContactLead.js"
import FollowUp from './FollowUp.js';
import { BACKEND_ADDRESS } from '../utils/getEndpoint.js';
import axios from "axios"
import { Check } from '@mui/icons-material';
import { isValidEmail, isValidUrl } from '../utils/helper.js';
import ToastComponent from '../utils/ToastComponent.js';

export default function ExecuteCampaign(props){
  const [activeCampaign, setActiveCampaign] = useState(props.activeCampaign)
  const [mode, setMode] = useState('none') //none/reachout/followup/log
  const [activeLead, setActiveLead] = useState(-1);
  const [activeList, setActiveList] = useState(-1);
  const [noteState, setNoteState] = useState(props.activeCampaign["lead_notes"].map(x => x.map(y => (y === ""))))

  const callback = (campaign) => {
    setMode('none')
    // setActiveList(-1)
    // setActiveLead(-1)

    setActiveCampaign(campaign)
  }

  const setLeadStatus = (val, listNo, rowIndex) => {
    var obj = {type: val, status: "active"}
    if(val === "Contact Lead" || val === "Follow Up"){
      obj["subject"] = ""
      obj["message"] = ""
      obj["script"] = ""
    }

    if(val === "Contact Lead") setMode("reachout"); //open subtasks
    else if(val === "Follow Up") setMode("followup") //open subtasks
    else if(val === "Conversation Done") setMode("log") //open subtasks
    else {
      obj["status"] = "completed" //end task
      setMode("none")
    }

    setActiveLead(rowIndex); setActiveList(listNo);

    var newActiveCampaign = JSON.parse(JSON.stringify(activeCampaign))
    newActiveCampaign["lead_status"][listNo][rowIndex].push(obj)

    // update everywhere
    setActiveCampaign(newActiveCampaign)
    axios.put(BACKEND_ADDRESS + '/campaign/', newActiveCampaign)
  }

  const onLeadNotesChange = (val, listNo, rowIndex) => {
    var newActiveCampaign = JSON.parse(JSON.stringify(activeCampaign))
    newActiveCampaign["lead_notes"][listNo][rowIndex] = val

    // update everywhere
    setActiveCampaign(newActiveCampaign)
  }

  const saveNotes = () => {
    axios.put(BACKEND_ADDRESS + '/campaign/', activeCampaign)
    ToastComponent("info", "Note Saved!")
  }

  const changeNoteState = (listNo, rowIndex) => {
    var noteStateCopy = JSON.parse(JSON.stringify(noteState))
    noteStateCopy[listNo][rowIndex] = false
    setNoteState(noteStateCopy)
  }

  const colNames = ["Name", "Contact", "Customer Profile Tags", "Task Cadence", "Notes"]
  const colMaxWidths = [100, 150, 300, 300, 300]

  return (
    <Stack direction="row">
    <Paper elevation={3} sx={{width: "30%", overflowX: "scroll", height: "95vh", overflowY: "scroll"}}>
    <TableContainer>
    {activeCampaign["leads"].map((leadList, listNo) => {
      return (
      <React.Fragment key={listNo}>
        {leadList["fields"].length > 0 && leadList["data"]["Email"].length > 0?
          <>
            <Table size="small">
              <TableHead >
                <TableRow sx={{backgroundColor: "#f5f5f5"}}>
                  <TableCell colSpan={5}><Typography variant='h6'>{leadList["name"]}</Typography></TableCell>
                </TableRow>
                {/*
                <TableRow>
                  {colNames.map((x, ind)=> 
                    <TableCell key={ind} sx={{maxWidth: colMaxWidths[ind]}}>
                      <Typography style={x === "Sequence"?{marginLeft:"8px"}:{}}>
                        {x}&nbsp;
                        {x==="Task Cadence"?<ArrowDownwardIcon fontSize="small" sx={{verticalAlign: 'middle', fontSize: '18'}} />:<></>}
                      </Typography>
                    </TableCell>
                  )}
                </TableRow>
                */}
              </TableHead>
              <TableBody>
                {Array.from({length: leadList["data"]["Email"].length }).map((_, rowIndex) =>{
                  return (
                    <React.Fragment key={rowIndex}>
                    {activeCampaign["filtered_leads"][listNo][rowIndex]?
                    <TableRow key={rowIndex} sx={(activeList === listNo && activeLead === rowIndex)?{backgroundColor: "rgba(57, 90, 246, 0.15)"}:{}}>
                      <TableCell key="Name" component="th" scope="row">
                        <Typography variant='h6'>{leadList["data"]["Name"][rowIndex]}</Typography>
                        
                        <br />
                        Contact
                        <br />
                        {leadList["filteredFields"].includes("Email") && leadList["data"]["Email"][rowIndex] !== ""?
                          <>
                          {isValidEmail(leadList["data"]["Email"][rowIndex])?
                          <a href={"mailto:"+leadList["data"]["Email"][rowIndex]}>
                            <Chip avatar={<Avatar>{leadList["data"]["Email"][rowIndex][0]}</Avatar>}
                              variant="outlined" label={leadList["data"]["Email"][rowIndex]} />
                          </a>
                          :"Email: " + leadList["data"]["Email"][rowIndex]}
                          <br />
                          </>
                        :""}
                        {leadList["filteredFields"].includes("LinkedIn") && leadList["data"]["LinkedIn"][rowIndex] !== ""?
                          <>
                          {isValidUrl(leadList["data"]["LinkedIn"][rowIndex])?
                          <a target="_blank" href={leadList["data"]["LinkedIn"][rowIndex]}>
                            LinkedIn
                          </a>
                          :"LinkedIn: " + leadList["data"]["LinkedIn"][rowIndex]}
                          <br />
                          </>
                        :""}
                        {leadList["filteredFields"].includes("Twitter") && leadList["data"]["Twitter"][rowIndex] !== ""?
                          <>
                          {isValidUrl(leadList["data"]["Twitter"][rowIndex])?
                          <a target="_blank" href={leadList["data"]["Twitter"][rowIndex]}>
                            Twitter
                          </a>
                          :"Twitter: " + leadList["data"]["Twitter"][rowIndex]}
                          <br />
                          </>
                        :""}
                        {leadList["filteredFields"].includes("Phone") && leadList["data"]["Phone"][rowIndex] !== ""?<>{"Phone: " + leadList["data"]["Phone"][rowIndex]}<br /></>:""}
                        
                        <br />
                        Customer Profile
                        <br />
                        {/*leadList["metadata"]["cp"][rowIndex]["name"] !== ""?<>{"Customer Profile: "}<Chip sx={{mb: "1"}} size="small" label={leadList["metadata"]["cp"][rowIndex]["name"]} /><br /></>:<></>
                        */}
                        {leadList["metadata"]["persona"][rowIndex] && leadList["metadata"]["persona"][rowIndex]["designation"] !== ""?<>{"Persona: "}<Chip  sx={{mb: "1"}} size="small" label={leadList["metadata"]["persona"][rowIndex]["designation"] + " at " + leadList["metadata"]["persona"][rowIndex]["vertical"]} /><br /></>:<></>}
                        {leadList["metadata"]["industry"][rowIndex] !== ""?<>{"Industry: "}<Chip sx={{mb: "1"}} size="small" label={leadList["metadata"]["industry"][rowIndex]} />&nbsp;</>:<></>}
                        {leadList["metadata"]["vertical"][rowIndex] !== ""?<>{"Vertical: "}<Chip sx={{mb: "1"}} size="small" label={leadList["metadata"]["vertical"][rowIndex]} />&nbsp;</>:<></>}
                        {leadList["metadata"]["geography"][rowIndex] !== ""?<>{"Geography: "}<Chip sx={{mb: "1"}} size="small" label={leadList["metadata"]["geography"][rowIndex]} /><br /></>:<></>}
                        {leadList["metadata"]["company_size"][rowIndex] !== ""?<>{"Company Size: "}<Chip sx={{mb: "1"}} size="small" label={leadList["metadata"]["company_size"][rowIndex]} />&nbsp;</>:<></>}
                        {leadList["metadata"]["team_size"][rowIndex] !== ""?<>{"Team Size: "}<Chip sx={{mb: "1"}} size="small" label={leadList["metadata"]["team_size"][rowIndex]} /><br /></>:<></>}
                        {leadList["metadata"]["buying_signals_tech"][rowIndex] !== ""?<>{"Technical Buying Signal: "}<Chip sx={{mb: "1"}} size="small" label={leadList["metadata"]["buying_signals_tech"][rowIndex]} /><br /></>:<></>}
                        {leadList["metadata"]["buying_signals_non_tech"][rowIndex] !== ""?<>{"Non-technical Buying Signal: "}<Chip sx={{mb: "1"}} size="small" label={leadList["metadata"]["buying_signals_non_tech"][rowIndex]} /><br /></>:<></>}

                        <br /><br />
                        {noteState[listNo][rowIndex]?
                        <div>
                          <Button size="small" variant="outlined" onClick={() => changeNoteState(listNo, rowIndex)}>Add Note</Button>
                        </div>
                        :
                        <Stack direction="column" spacing={1} justifyContent="flex-start">
                          Note
                          <TextField sx={{width: "100%"}} variant="outlined" size="small" value={activeCampaign["lead_notes"][listNo][rowIndex]} onChange={e => onLeadNotesChange(e.target.value, listNo, rowIndex)} multiline minRows={2} />
                          <Button size="small" onClick={saveNotes}>Save Note</Button>
                        </Stack>
                        }
                        
                        <br />
                        <b>Tasks</b>
                        <Stack direction="row" alignItems="flex-end" sx={{flexWrap: 'wrap'}}>
                          {activeCampaign["lead_status"][listNo][rowIndex].map((val, i) => {
                            return (
                              <React.Fragment key={i}>
                                {val["type"] === "New"?
                                  <></>
                                :val["type"] === "Contact Lead" || val["type"] === "Follow Up"?
                                  <Stack direction="row" alignItems="center" spacing={1}>
                                    {val["status"] === "completed"?<CheckCircleIcon style={{color:"#3f8f29"}}/>:<></>}
                                    <Typography variant="subtitle2" style={{align:"center"}}>
                                      {val["type"]}
                                      {val["status"] === "active"?
                                      <IconButton sx={{p:0.5}} size="small" color="primary" onClick={() => {setMode(val["type"] === "Contact Lead"?"reachout":"followup"); setActiveLead(rowIndex); setActiveList(listNo)}}><EditIcon sx={{verticalAlign: 'middle', fontSize: '18'}} fontSize="small"/></IconButton>
                                      :<IconButton sx={{p:0.5}} size="small" color="primary" onClick={() => {setMode(val["type"] === "Contact Lead"?"reachout":"followup"); setActiveLead(rowIndex); setActiveList(listNo)}}><VisibilityIcon sx={{verticalAlign: 'middle', fontSize: '18'}} fontSize="small"/></IconButton>}
                                    </Typography>
                                    {val["status"] === "completed"?<NavigateNextIcon />:<></>
                                    }
                                  </Stack>
                                :val["type"] === "No Reply" || val["type"] === "Replied, but Not Interested"?
                                  <Stack direction="row" alignItems="center" spacing={1}>
                                    <CancelIcon style={{color:"#de1a24"}}/>
                                    <Typography variant="subtitle2">{val["type"]}</Typography>
                                  </Stack>
                                :val["type"] === "Conversation Done"?
                                  <Stack direction="row" alignItems="center" spacing={1}>
                                    {val["status"] === "completed"?<CheckCircleIcon style={{color:"#3f8f29"}}/>:<PendingIcon style={{color: "#ffc232"}}/>}
                                    <Typography variant="subtitle2">
                                      Conversation Done
                                      <IconButton sx={{p:0.5}} color="primary" onClick={() => {setMode("log"); setActiveLead(rowIndex); setActiveList(listNo)}}><EditIcon sx={{verticalAlign: 'middle', fontSize: '18'}} fontSize="small"/></IconButton>
                                    </Typography>
                                  </Stack>
                                :<></>
                                }
                                {i === activeCampaign["lead_status"][listNo][rowIndex].length - 1 && val["status"] === "completed"?
                                  val["type"] === "New"?
                                    <Button size="small" onClick={() => setLeadStatus("Contact Lead", listNo, rowIndex)}>Contact Lead</Button>
                                  :val["type"] === "Contact Lead"?
                                    <FormControl sx={{mt: -2}}>
                                      <InputLabel sx={{ml:-2}}>Next Task</InputLabel>
                                      <Select size="small" sx={{width: "100px"}} label='Next Task' variant="standard" 
                                      onChange={e => setLeadStatus(e.target.value, listNo, rowIndex)}>
                                        <ListSubheader>Active</ListSubheader>
                                        <MenuItem value={"Follow Up"}>
                                          Follow Up&nbsp;
                                          <Chip size="small" label="4 days later" style={{background: '#395af6', color: "white"}} />
                                        </MenuItem>
                                        <ListSubheader>Mark as Closed</ListSubheader>
                                        <MenuItem value={"No Reply"}>No Reply</MenuItem>
                                        <MenuItem value={"Replied, but Not Interested"}>Replied, but Not Interested</MenuItem>
                                        <MenuItem value={"Conversation Done"}>Conversation Done</MenuItem>
                                      </Select>
                                    </FormControl>
                                  :val["type"] === "Follow Up"?
                                    <FormControl sx={{mt: -2}}>
                                      <InputLabel>Next Task</InputLabel>
                                      <Select size="small" sx={{width: "100px"}} label='Next Task' variant="standard" 
                                      onChange={e => setLeadStatus(e.target.value, listNo, rowIndex)}>
                                        <ListSubheader>Mark as Closed</ListSubheader>
                                        <MenuItem value={"No Reply"}>No Reply</MenuItem>
                                        <MenuItem value={"Replied, but Not Interested"}>Replied, but Not Interested</MenuItem>
                                        <MenuItem value={"Conversation Done"}>Conversation Done</MenuItem>
                                      </Select>
                                    </FormControl>
                                  :<></>
                                :<></>
                                }
                              </React.Fragment>
                            )
                          })}
                        </Stack>
                      </TableCell>
                    </TableRow>
                    :<></>
                    }
                    </React.Fragment>
                  )
                })}
              </TableBody>
            </Table>
          </>
          :<></>
        }
      </React.Fragment>
      )
    })}
    </TableContainer>
    </Paper>

    <Paper sx={{width: "70%"}}>
      {activeLead > -1 && activeList > -1?
      (mode === "log" && activeCampaign["lead_status"][activeList][activeLead].slice(-1)[0]["type"] === "Conversation Done"?
        <LogCall key={String(activeList) + "-" + String(activeLead)} mode={mode} campaign={activeCampaign} listNo={activeList} leadNo={activeLead} cp={activeCampaign["cp"]} callback={callback}/>
      :mode === "reachout"?
        <ContactLead key={String(activeList) + "-" + String(activeLead)} campaign={activeCampaign} listNo={activeList} leadNo={activeLead} cp={activeCampaign["cp"]} callback={callback} />
      :mode === "followup"?
        <FollowUp key={String(activeList) + "-" + String(activeLead)} campaign={activeCampaign} listNo={activeList} leadNo={activeLead} cp={activeCampaign["cp"]} callback={callback} />
      :<></>)
      :<Typography sx={{ml:2, mt:2}}>Start a task in the left pane to begin!</Typography>
      }
    </Paper>
    </Stack>
  )
}
