import React, { useEffect, useState, useRef } from "react"
import ChatUI from "../chatUI/chatUI.tsx"

import {
  Grid,
  Stack,
  Paper,
  TextField,
  Select,
  MenuItem,
  Typography,
  IconButton,
  Button,
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  FormControl,
  FormHelperText,
  InputLabel
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { useSelector } from "react-redux";
import ToastComponent from "./../utils/ToastComponent";
import TipBox from "./../utils/TipBox.js"
import { BACKEND_ADDRESS } from "../utils/constants.js";

export default function AddPositioning(props){
  const account_id = useSelector((state) => state.account.value);
  const data = props.data

  // State variables
  const [id, setId] = useState(data["id"])
  const [name, setName] = useState(data["name"]);
  const [statement, setStatement] = useState(data["statement"]);
  const [cp, setCp] = useState(data["cp_id"]);
  const [allCp, setAllCp] = useState(props.allCp);
  const [narrative, setNarrative] = useState(data["narrative"]);
  const [maturity, setMaturity] = useState(data["maturity"]);
  const [outcomes, setOutcomes] = useState(data["outcomes"]);
  const [useCases, setUseCases] = useState(data["use_cases"]);
  const [categories, setCategories] = useState(data["categories"]);
  const [capabilities, setCapabilities] = useState(data["capabilities"]);
  const [competitors, setCompetitors] = useState(data["competitors"]);
  const [saveEnabled, setSaveEnabled] = useState(false);

  const hasMounted = useRef(false);

  const callback = (result) => {
    setName(result["name"])
    setStatement(result["statement"])
    setCp(result["cp_id"])
    setNarrative(result["narrative"])
    setMaturity(result["maturity"])
    setOutcomes(result["outcomes"])
    setUseCases(result["use_cases"])
    setCategories(result["categories"])
    setCapabilities(result["capabilities"])
    setCompetitors(result["competitors"])
  }

  // Mapper and lists
  const allMaturities = ["Immature Market", "Emerging Market", "Matured Market"];
  const maturityMap = {
    "Immature Market": { sellingType: "Outcome Based", contentType: "Top Of The FUnnel (TOFU) Dominant" },
    "Emerging Market": { sellingType: "Use Case Based", contentType: "Middle Of The FUnnel (MOFU) Dominant" },
    "Matured Market": { sellingType: "Product Category Based", contentType: "Bottom Of The FUnnel (BOFU) Dominant" },
  }
  const maturityToHelpText = {
    "Immature Market": "Convince buyers that a use case they aren't performing is vital to achieve their desired outcomes",
    "Emerging Market": "Convince buyers that a category of products they aren't using, is vital to perform their usecase",
    "Matured Market": "Convices buyers that your product has the best capabilities compared to any competitiors, for their requirements",
  };
  const allNarratives = ["Pain Point based", "Audience Based", "Differentiator Based", "Change Based"]
  const narrativeToTone = {
    "Pain Point based": "Sympathy, Humour, Joy",
    "Audience Based": "Sympathy, Humour, Indignation",
    "Differentiator Based": "Direct, Rational, Confrontational",
    "Change Based": "Idealism, Inspiration, Excitement"
  }
  const narrativeToHelpText = {
    "Pain Point based": "Positions the product as the best solution for a specific problem that exists in the market",
    "Audience Based": "Positions the product as the best solution for an undeserved audience segment",
    "Differentiator Based": "Positions the product as a solution unlike any other solution in the market.",
    "Change Based": "Positions the product as a thought leader guiding the market through a transformation."
  }

  // Derived values
  const tones = narrativeToTone[narrative] || "";
  const sellingType = maturity ? maturityMap[maturity].sellingType : "";
  const contentType = maturity ? maturityMap[maturity].contentType : "";

  // // Fetch allCp data on load
  // useEffect(() => {
  //   axios
  //     .get(`${BACKEND_ADDRESS}/cp/all_cps/`, { params: { account_id: account_id } })
  //     .then((response) => {
  //       const result = response.data["customer_profiles"].map((x, ind) => ({ ind, ...x }));
  //       setAllCp(result);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching customer profiles:", error);
  //     });
  // }, [account_id]);

  // Functions to handle list item changes for capabilities and competitors
  const handleAddItem = (setFunction) => setFunction((prev) => [...prev, ""]);
  const handleDeleteItem = (setFunction, index) =>
    setFunction((prev) => prev.filter((_, i) => i !== index));
  const handleChangeItem = (setFunction, index, value) =>
    setFunction((prev) => prev.map((item, i) => (i === index ? value : item)));

  const handlePositioningSave = () => {
    if(name === "" || statement === "") ToastComponent("error", "Name and Conversation Opener are required!")
    else{
      axios.post(BACKEND_ADDRESS + '/positioning/save/', {id: id, account_id: account_id, name: name, statement: statement, cp_id: cp, narrative: narrative, maturity: maturity, outcomes: outcomes, use_cases: useCases, categories: categories, capabilities: capabilities, competitors: competitors})
      .then(response => {
        const result = response.data
        
        if(id === "") ToastComponent("info", "Positioning Saved!")
        else ToastComponent("info", "Positioning Updated!")

        setId(result["id"])
        setSaveEnabled(false);
      })
    }
  }

  useEffect(() => {
    if (hasMounted.current) {
      setSaveEnabled(true);
    }else{
      // Skip effect on initial render
      hasMounted.current = true;
    }
  }, [name, statement, cp, narrative, maturity, outcomes, useCases, categories, capabilities, competitors])

  return (
    <Grid container direction="row" spacing={1}>
      {props.subMode == "positioning_content"?
        <Grid item xs={12}>
          <TipBox tip={
            <div>
              <Typography>There are two ways to edit positioning-</Typography>
              <Typography><b>a) Start from scratch-</b> Chat with the <i>Positioning Assistant</i> to create a new positioning on top of the existing positioning.</Typography>
              <Typography><b>b) Change finer details-</b> Edit fields in the <i>Positioning Draft</i> to improve the existing positioning.</Typography>
            </div>
          } />
        </Grid>
        :
        <Grid item xs={12}>
          <TipBox tip={
            <div>
              <Typography>There are two ways to create positioning-</Typography>
              <Typography><b>a) (Recommended) Chat-</b> Answer the questions asked by the <i>Positioning Assistant</i> to create a new positioning.</Typography>
              <Typography><b>b) Fill from Scratch-</b> If you are a PRO at positioning, edit fields directly in the <i>Positioning Draft</i> section.</Typography>
            </div>
          } />
        </Grid>
      }
      <Grid item xs={7}>
        <ChatUI callback={callback} id={id} account_id={account_id} all_cp={allCp} all_narratives={allNarratives} all_maturities={allMaturities}
        subMode={props.subMode} />
      </Grid>
      <Grid item xs={5}>
      <Paper style={{height: "80vh", width: "100%", borderRadius: "8px", padding: "24px", position: "relative", overflowY: "auto"}}
      elevation={2} >
        <Grid container spacing={2}>
        <Grid container item xs={12} justifyContent="space-between">
          <Typography align="center" variant="h6" sx={{ fontWeight: 'bold' }}>Positioning Draft</Typography>
          {id === "" ?
            <Button variant="contained" color="primary" onClick={handlePositioningSave} disabled={!saveEnabled}>Save</Button>
            :
            <Button variant="contained" color="primary" onClick={handlePositioningSave} disabled={!saveEnabled}>Save Changes</Button>
          }
        </Grid>
        <Grid item xs={12}></Grid>

        {/* Basic TextFields with placeholders */}
        <Grid item xs={6}>
          <TextField size="small" label="Name" placeholder="Enter name" value={name} onChange={(e) => setName(e.target.value)} fullWidth />
        </Grid>

        <Grid item xs={6}>
          {/* Select field for customer profiles */}
          <FormControl size="small" sx={{width: "100%"}}>
            <InputLabel>Customer Profile</InputLabel>
            <Select value={cp} onChange={(e) => setCp(e.target.value)} fullWidth>
              {allCp.map((option, ind) => (
                <MenuItem key={ind} value={option["_id"]["$oid"]}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <TextField size="small" label="Conversation Opener" placeholder="One line statement which communicates your unique position in the market" value={statement}
          onChange={(e) => setStatement(e.target.value)} fullWidth multiline minRows={2} />
        </Grid>

        <Grid item xs={12}><Divider style={{margin: "8 0"}}/></Grid>

        <Grid item xs={12}>
          {/* Select for maturity */}
          <FormControl size="small" sx={{width: "100%"}}>
            <InputLabel>Strategic Narrative</InputLabel>
            <Select
              value={narrative}
              onChange={(e) => setNarrative(e.target.value)}
              fullWidth
              displayEmpty
            >
              {allNarratives.map((level) => (
                <MenuItem key={level} value={level}>
                  {level}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {narrative === ""?
                "Select an option to know more"
                :narrativeToHelpText[narrative]
              }
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12}><Typography ><b>Tone</b></Typography><Typography variant="subtitle2">{tones}</Typography></Grid>
        <Grid item xs={12}><Divider style={{margin: "8 0"}} /></Grid>

        <Grid item xs={12}>
          {/* Select for maturity */}
          <FormControl size="small" sx={{width: "100%"}}>
            <InputLabel>Market Maturity</InputLabel>
            <Select
              value={maturity}
              onChange={(e) => setMaturity(e.target.value)}
              fullWidth
              displayEmpty
            >
              {allMaturities.map((level) => (
                <MenuItem key={level} value={level}>
                  {level}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {maturity === ""?
                "Select an option to know more"
                :maturityToHelpText[maturity]
              }
            </FormHelperText>
          </FormControl>
        </Grid>

        {/* Typography for derived fields */}
        <Grid item xs={6}><Typography ><b>Selling Type</b></Typography><Typography variant="subtitle2">{sellingType}</Typography></Grid>
        <Grid item xs={6}><Typography ><b>Content Type</b></Typography><Typography variant="subtitle2">{contentType}</Typography></Grid>
        <Grid item xs={12}><Divider style={{margin: "8 0"}} /></Grid>

        {/* Conditional rendering based on maturity selection */}
        {maturity && (
          <>
            {maturity === "Immature Market" && <Grid item xs={12}>
              {/* Outcome list */}
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography ><b>Outcome</b></Typography>
                <Button startIcon={<AddIcon />} onClick={() => handleAddItem(setOutcomes)}>
                  Add Outcome
                </Button>
              </Stack>
              <List sx={{ml:-2, pt:0}}>
                {outcomes.map((outcome, index) => (
                  <ListItem key={index} sx={{ display: "flex", alignItems: "center", p: "4 16" }}>
                    <TextField size="small"
                      placeholder="Enter outcome"
                      value={outcome}
                      onChange={(e) => handleChangeItem(setOutcomes, index, e.target.value)}
                      fullWidth
                    />
                    <IconButton onClick={() => handleDeleteItem(setOutcomes, index)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
            </Grid>
            }

            {maturity !== "Matured Market" && <Grid item xs={12}>
              {/* Usecase list */}
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography ><b>Use Cases</b></Typography>
                <Button startIcon={<AddIcon />} onClick={() => handleAddItem(setUseCases)}>
                  Add Use Case
                </Button>
              </Stack>
              <List sx={{ml:-2, pt:0}}>
                {useCases.map((usecase, index) => (
                  <ListItem key={index} sx={{ display: "flex", alignItems: "center", p: "4 16" }}>
                    <TextField size="small"
                      placeholder="Enter use case"
                      value={usecase}
                      onChange={(e) => handleChangeItem(setUseCases, index, e.target.value)}
                      fullWidth
                    />
                    <IconButton onClick={() => handleDeleteItem(setUseCases, index)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
            </Grid>
            }

            {maturity !== "Immature Market" && <Grid item xs={12}>
              {/* Categories list */}
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography ><b>Product Categories</b></Typography>
                <Button startIcon={<AddIcon />} onClick={() => handleAddItem(setCategories)}>
                  Add Product Category
                </Button>
              </Stack>
              <List sx={{ml:-2, pt:0}}>
                {categories.map((category, index) => (
                  <ListItem key={index} sx={{ display: "flex", alignItems: "center", p: "4 16" }}>
                    <TextField size="small"
                      placeholder="Enter product category"
                      value={category}
                      onChange={(e) => handleChangeItem(setCategories, index, e.target.value)}
                      fullWidth
                    />
                    <IconButton onClick={() => handleDeleteItem(setCategories, index)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
            </Grid>
            }

            {maturity === "Matured Market" && <Grid item xs={12}>
              {/* Capabilities list */}
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography ><b>Capabilities</b></Typography>
                <Button startIcon={<AddIcon />} onClick={() => handleAddItem(setCapabilities)}>
                  Add Capability
                </Button>
              </Stack>
              <List sx={{ml:-2, pt:0}}>
                {capabilities.map((capability, index) => (
                  <ListItem key={index} sx={{ display: "flex", alignItems: "center", p: "4 16" }}>
                    <TextField size="small"
                      placeholder="Enter capability"
                      value={capability}
                      onChange={(e) => handleChangeItem(setCapabilities, index, e.target.value)}
                      fullWidth
                    />
                    <IconButton onClick={() => handleDeleteItem(setCapabilities, index)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
            </Grid>
            }
          </>
        )}
        {console.log(competitors)}
        <Grid item xs={12}>
          {/* Competitors list */}
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography ><b>Competitors</b></Typography>
            <Button startIcon={<AddIcon />} onClick={() => handleAddItem(setCompetitors)}>
              Add Competitor
            </Button>
          </Stack>
          <List sx={{ml: -2, pt:0}}>
            {competitors.map((competitor, index) => (
              <ListItem key={index} sx={{ display: "flex", alignItems: "center", p: "4 16" }}>
                <TextField size="small"
                  placeholder="Enter competitor"
                  value={competitor}
                  onChange={(e) => handleChangeItem(setCompetitors, index, e.target.value)}
                  fullWidth
                />
                <IconButton onClick={() => handleDeleteItem(setCompetitors, index)}>
                  <DeleteIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
        </Grid>
        </Grid>
      </Paper>
      </Grid>
    </Grid>
  )
}
