import React, { useState, useEffect } from 'react';
import {useHistory} from "react-router-dom";
import { Accordion, AccordionSummary, AccordionDetails, LinearProgress, Typography, Box, IconButton, Button, Stack, Grid, Chip, Paper, TextField, Skeleton} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider'

import TipBox from "./../utils/TipBox"

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import ExecuteCampaign from "./ExecuteCampaign.js"
import CampaignAnalysis from "./CampaignAnalysis"

import {CAMPAIGN_STAGES, TEMPLATE_COPY} from "./../utils/constants.js"
import { BACKEND_ADDRESS } from "./../utils/getEndpoint"
import ToastComponent from "./../utils/ToastComponent";
import { HighlightText, getTimeLeft } from '../utils/helper.js';
import axios from "axios"
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

const copyCategories = {
  "Showing Copy for Channel": ["Email/LinkedIn InMail", "WhatsApp/LinkedIn/Twitter DM etc.", "Cold Call", "Face-to-face/At Events"],
  "Outreach Goal": ["Research Centric", "Sales Centric"],
}

const allCpAttributes = [
  {value: "industries", name: "Industry"},
  {value: "verticals", name: "Vertical"},
  {value: "company_size", name: "Company Size"},
  {value: "team_size", name: "Team Size"},
  {value: "geographies", name: "Geography"},
  {value: "buying_signals_tech", name: "Technical Buying Signals"},
  {value: "buying_signals_non_tech", name: "Non-Technical Buying Signals"},
]

var allCpAttributesMap = {}
for(var i=0;i<allCpAttributes.length;i+=1){
  allCpAttributesMap[allCpAttributes[i]["value"]] = allCpAttributes[i]["name"]
}

const LinearProgressWithLabel = (props) => {
  return (
    <>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', minWidth: "150px", mr:1}}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
    <Typography fontSize="13">Progress</Typography>
    </>
  )
}

export default function CampaignContent(props){
  const [activeCampaign, setActiveCampaign] = useState(null);
  const [activeCampaignNo, setActiveCampaignNo] = useState(-1)
  const [activeStage, setActiveStage] = useState(1);
  //const [completedStages, setCompletedStages] = useState([]);
  //const [copySource, setCopySource] = useState(props.copySource) //copySource is -1 for template and campaign index for campaign
  const [activeCopyCategory, setActiveCopyCategory] = useState([0, 0])
  const [editCopyPopup, setEditCopyPopup] = useState(false)
  const [editCopy, setEditCopy] = useState(null)
  const [copyDataActive, setCopyDataActive] = useState(null)

  // if editCopy=false, copy_source contains campaign number from which scripts were used. copyData contains script for custom scripts created (if any)
  // if editCopy=true, copy_source contains campaign number from which scripts were started from. copyData contains the actual scripts

  let history = useHistory()

  useEffect(() => {
    for(var i=0;i<props.content.length;i+=1){
      if(props.content[i]["_id"]["$oid"] === props.match.params.c_id){
        var activeCampaign = props.content[i]

        setActiveCampaign(activeCampaign)
        setActiveCampaignNo(i)
        setActiveStage(activeCampaign["active_stage"])
        setEditCopy(activeCampaign["edit_copy"])

        if(activeCampaign["copy_source"]>=0){
          const chosen_campaign = props.content[activeCampaign["copy_source"]]

          if(chosen_campaign["copy_source"] === -1 || chosen_campaign["copy_source"] === -2){
            setCopyDataActive(TEMPLATE_COPY[activeCopyCategory[0]])
          }else{
            setCopyDataActive(chosen_campaign["copy_data"][activeCopyCategory[0]])
          }
        }else if(activeCampaign["copy_source"] === -1){
          setCopyDataActive(TEMPLATE_COPY[activeCopyCategory[0]])
        }

        props.callback("heading", activeCampaign["name"])
        props.callback("description", "Progress on the Steps Below to Complete the Campaign!")

        break
      }
    }
  }, [])

  const handleAccordionChange = (e, stageNumber) => {
    if (stageNumber <= activeCampaign["active_stage"]) {
      setActiveStage(stageNumber);
    }
  };

  const handleCompleteStage = (stage) => {
    // // autosave
    // if(actC["active_stage"] === 1 && editCopy) saveCopyChanges()
    var actC = JSON.parse(JSON.stringify(activeCampaign))

    if(stage === CAMPAIGN_STAGES.length){
      var endingTypes = ["No Reply", "Replied, but Not Interested", "Conversation Done"]
      var filtered_lead_status =  actC["lead_status"].map((x, i) => x.filter((y, j) => actC["filtered_leads"][i][j]))
      var isCompleted = filtered_lead_status.reduce((tot, val) => tot && (val.length === 0 || (val.reduce((t, v) => t && v[v.length-1]["status"] === "completed" && endingTypes.includes(v[v.length-1]["type"]), true)), true))
      if(!isCompleted){
        ToastComponent("info", "Please finish pending Tasks for all leads, to end Campaign!")
        return
      }else{
        actC["status"] = "Completed"
      }
    }
    if(actC["active_stage"] === stage) actC["active_stage"] += 1
    setActiveCampaign(actC);
    setActiveStage(actC["active_stage"])

    // update backend
    axios.put(BACKEND_ADDRESS + '/campaign/', actC)

    if(actC["active_stage"] === CAMPAIGN_STAGES.length + 1){
      //campaign end. Go to dashboard
      history.push("/campaigns/" + activeCampaign["_id"]["$oid"] + "/dashboard")
    }
  };

  const handleCopySourceChange = (e) => {
    var actC = JSON.parse(JSON.stringify(activeCampaign))

    if(activeCampaign["copy_source"] === activeCampaignNo){
      //ie you just switched from custom copy. Saving custom copy changes before switching
      actC["custom_copy"][activeCopyCategory[0]] = copyDataActive
    }

    //updating copy_source
    actC["copy_source"] = e.target.value

    //updating edit_copy
    if(e.target.value === activeCampaignNo) actC["edit_copy"] = true
    else actC["edit_copy"] = false
    setEditCopy(actC["edit_copy"])

    //updating copy_data
    if(actC["edit_copy"]){
      actC["copy_data"] = actC["custom_copy"]
      setCopyDataActive(actC["custom_copy"][activeCopyCategory[0]])
    }else{
      if(actC["copy_source"]>=0){
        const chosen_campaign = props.content[actC["copy_source"]]

        if(chosen_campaign["copy_source"] === -1 || chosen_campaign["copy_source"] === -2){
          actC["copy_data"] = TEMPLATE_COPY
          setCopyDataActive(TEMPLATE_COPY[activeCopyCategory[0]])
        }else{
          actC["copy_data"] = chosen_campaign["copy_data"]
          setCopyDataActive(chosen_campaign["copy_data"][activeCopyCategory[0]])
        }
      }else if(actC["copy_source"] === -1){
        actC["copy_data"] = TEMPLATE_COPY
        setCopyDataActive(TEMPLATE_COPY[activeCopyCategory[0]])
      }
    }

    setActiveCampaign(actC);

    // update backend
    axios.put(BACKEND_ADDRESS + '/campaign/', actC)
  }

  const handleActiveCopyCategoryChange = (ind1, ind2) => {
    var copyCategory = JSON.parse(JSON.stringify(activeCopyCategory))
    copyCategory[ind1] = ind2
    setActiveCopyCategory(copyCategory)
    setCopyDataActive(activeCampaign["copy_data"][ind2])
  }

  const handleCustomiseCopy = () => {
    setEditCopy(true);
    setEditCopyPopup(false);

    //update active campaign
    var actC = JSON.parse(JSON.stringify(activeCampaign))
    var oldCopySource = actC["copy_source"]
    actC["copy_source"] = activeCampaignNo
    actC["edit_copy"] = true

    actC["copy_data"] = oldCopySource < 0?TEMPLATE_COPY:(props.content[activeCampaign["copy_source"]]["copy_source"] < 0?TEMPLATE_COPY:props.content[activeCampaign["copy_source"]]["copy_data"])
    actC["custom_copy"] = oldCopySource < 0?TEMPLATE_COPY:(props.content[activeCampaign["copy_source"]]["copy_source"] < 0?TEMPLATE_COPY:props.content[activeCampaign["copy_source"]]["copy_data"])

    setActiveCampaign(actC);

    // update backend
    axios.put(BACKEND_ADDRESS + '/campaign/', actC)
  }

  const handleCopyDataChange = (e, ind, key) => {
    var copyDataCopy = JSON.parse(JSON.stringify(copyDataActive))
    copyDataCopy["copy"][ind][key] = e.target.value
    setCopyDataActive(copyDataCopy)
  }

  const saveCopyChanges = () => {
    var actC = JSON.parse(JSON.stringify(activeCampaign))
    actC["copy_data"][activeCopyCategory[0]] = copyDataActive
    actC["custom_copy"][activeCopyCategory[0]] = copyDataActive

    setActiveCampaign(actC)
    axios.put(BACKEND_ADDRESS + '/campaign/', actC)
    .then(response => ToastComponent("info", "Copy changes saved!"))
    .catch(error => ToastComponent("error", "Failed to save Copy changes."))
  }

  var progress = 0

  if(activeCampaign){
    var totalLeads = activeCampaign["filtered_leads"].reduce((tot, val) => tot + val.reduce((t, v) => t + v, 0), 0)
    var endingTypes = ["No Reply", "Replied, but Not Interested", "Conversation Done"]
    progress = 100*(activeCampaign["lead_status"].reduce((tot, val) => tot + val.reduce((t, v) => t + (v.length > 1 && v[v.length-1]["status"] === "completed" && endingTypes.includes(v[v.length-1]["type"]) ), 0), 0))/totalLeads
  }

  const editLeads = () => {
    history.push("/campaigns/edit_campaign/" + props.match.params.c_id)
  }

  const timeLeft = (activeCampaign !== null)?getTimeLeft(activeCampaign["_id"]["$oid"], activeCampaign["duration"]):0
  const timeLeftString = (timeLeft[0] > 0)?(timeLeft[0] + " days"):(timeLeft[1] + " hours")
  const timeLeftColor = (timeLeft[0] > 2)?"#3f8f29":"#de1a24"

  return (
    <>
    {activeCampaign !== null?
    <div style={{marginTop: "-16"}}>
      <Paper elevation={1} sx={{mb:1, p:3, backgroundColor: "#f5f5f5"}}>
        <Stack direction="row" spacing={2} justifyContent="space-between" >
          <Stack direction="column">
            <Typography variant="h6">{activeCampaign["name"]}</Typography>
            <LinearProgressWithLabel value={progress} />
            <Stack direction="row" spacing={1} sx={{mt:2}}>
              <Typography>Time Remaining</Typography>
              <Chip size="small" label={timeLeftString} sx={{backgroundColor: timeLeftColor, color: "white"}} />
            </Stack>
          </Stack>
          <Stack direction="column" spacing={2}>
            <Stack direction="column" alignItems="flex-start">
              <Typography color="primary">Customer Profile</Typography>
              <Chip size="small" label={activeCampaign["cp"]["name"]} sx={{backgroundColor: "primary.main", color: "white"}} />
            </Stack>
            <Stack direction="column" alignItems="flex-start">
              <Typography color="primary">Product Ready?</Typography>
              <Chip size="small" label={activeCampaign["has_product"]?"Yes":"No"}/>
            </Stack>
          </Stack>
          <Stack direction="column">
            <Typography color="primary">Attributes Under Validation</Typography>
            {
            activeCampaign["cp_attributes"].map((x, ind) =>
            <Stack key={ind} direction="row" spacing={1} sx={{flexWrap: 'wrap'}}>
              <Typography color="black">{allCpAttributesMap[x]}:</Typography> {activeCampaign["cp"][x].map((y, ind2) => <Chip key={ind2} label={y} size="small" />)}
            </Stack>)
            }
          </Stack>
        </Stack>
      </Paper>

      {CAMPAIGN_STAGES.map((stage, index) => (
        <Stack direction="row" key={stage.stageNumber}>
          <Accordion key={activeStage+"_"+stage.stageNumber} sx={{width: "100%", mt:1, mb:1}} expanded={activeStage === stage.stageNumber} onChange={(e) => handleAccordionChange(e, stage.stageNumber)} disabled={stage.stageNumber > activeCampaign["active_stage"]}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{mb: -1}}>
              <Stack direction="row" sx={{width: "100%", pr:2}} justifyContent="space-between">
                <Box sx={{ display: 'flex', alignItems: 'center'}}>
                  <Box sx={{width: 80, height: 30, borderRadius: '15px', backgroundColor: '#395af6', color: 'white', display: 'flex',
                  justifyContent: 'center', alignItems: 'center', zIndex: 1}}>
                    <Typography>{"Step " + stage.stageNumber}</Typography>
                  </Box>
                  &nbsp;<Typography variant="h6">{stage.stageName}</Typography>
                </Box>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              {activeStage === 1?
                <>
                  <Paper elevation={0} sx={{width: "100%", overflowY: "auto", border: "1px solid rgba(0,0,0,0.12)", borderRadius: 2}}>
                    <Grid container direction="row" spacing={2} >
                      <Grid item xs={12} md={4}>
                        <Paper square sx={{height: "600px", overflowY: "auto", padding: "32 16", borderRight:"1px solid rgba(0, 0, 0, 0.12)", borderRadiusTopLeft: 2, borderRadiusBottomLeft: 2, backgroundColor: "#f5f5f5"}} elevation={0}>
                          <Stack direction="column" spacing={3}>
                            <Typography>Use copy and scripts from</Typography>
                            <FormControl variant="standard">
                              <InputLabel>Source</InputLabel>
                              <Select autoFocus variant="standard" value={activeCampaign["copy_source"]} onChange={handleCopySourceChange} sx={{minWidth: "250px"}}>
                                <MenuItem key={-1} value={-1}>Aspiro Template</MenuItem>
                                <Divider />
                                <Typography sx={{margin: "8 16"}}><b>All Campaigns</b></Typography>
                                {props.content.map((x, ind) => {
                                  return (x["_id"]["$oid"] !== props.match.params.c_id)?<MenuItem key={ind} value={ind}>{x["name"]}</MenuItem>:<div key={-ind}></div>
                                })}
                                <Divider />
                                {activeCampaign["custom_copy"].length?
                                  <MenuItem key={activeCampaignNo} value={activeCampaignNo}>Custom Copy</MenuItem>
                                  :<div key={-100}></div>
                                }
                              </Select>
                            </FormControl>
                            <Stack direction="row" spacing={1} alignItems="center" justifyContent="start">
                              <Typography>Or</Typography>
                              <Button variant="contained" onClick={()=> setEditCopyPopup(true)} disabled={activeCampaignNo === activeCampaign["copy_source"] || activeCampaign["copy_source"] === -2}>
                                Customise on Selected Copy Source
                              </Button>
                            </Stack>
                            <Divider />
                            {/*<Typography >Browse through or tweak copies/scripts for different Channels and Themes.<br /><br /> <b><i>You need to optimise these copies/scripts to maximize your chances of securing a meeting with the lead 👀.</i></b></Typography>
                              */}
                            {Object.keys(copyCategories).map((x, ind1) => {
                              return (
                                <Stack key={ind1} direction="column" spacing={1}>
                                  <Typography>{x}</Typography>
                                  <Stack spacing={1} direction="row" sx={{flexWrap: 'wrap'}}>
                                    {copyCategories[x].map((y, ind2) => {
                                      return (
                                        <Chip key={ind2} label={y} onClick={() => handleActiveCopyCategoryChange(ind1, ind2)} disabled={y==="Sales Centric" || activeCampaign["copy_source"] === -2}
                                        sx={activeCopyCategory[ind1] === ind2?{color: "white", backgroundColor: "#395af6", ":hover": {backgroundColor: "#395af6"}}:{}} style={{marginTop: 8}}/>
                                      )
                                    })}
                                  </Stack>
                                </Stack>
                              )
                            })}
                            <br />
                            {activeCampaign["status"] === "Active"?
                            <center>
                            <Button variant="contained" onClick={() => handleCompleteStage(1)} color="primary" startIcon={<CheckCircleIcon />} disabled={activeCampaign["copy_source"] === -2}>
                              Lock Copy and Move to Step 2
                            </Button>
                            </center>
                            :<></>
                            }
                          </Stack>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <Paper sx={{height: "600px", overflowY: "auto", padding: "32 16 0"}} elevation={0}>
                          <Stack direction="column" spacing={2}>
                            {copyDataActive?
                             copyDataActive["copy"].map((x, ind) => {
                              return (
                                <Stack key={ind} direction="column" sx={{width: "100%"}} spacing={1}>
                                  {/*<Typography variant="h6" style={{marginBottom: 8}}>Copy {ind+1}: {x["description"]}</Typography>
                                  */}
                                  {("subject" in x)?
                                    (editCopy?
                                    <TextField size="small" label="Subject" value={x["subject"]} onChange={e => handleCopyDataChange(e, ind, "subject")} multiline fullWidth />
                                    :<Typography label="Subject" style={{userSelect: 'none'}}><b>Subject</b><br /> {x["subject"]}</Typography>
                                    )
                                    :<></>
                                  }
                                  {("message" in x)?
                                    (editCopy?
                                    <TextField size="small" label={activeCopyCategory[0] === 1?"First Message":"Message"} value={x["message"]} onChange={e => handleCopyDataChange(e, ind, "message")} multiline minRows={4} fullWidth/>
                                    :<Box sx={{whiteSpace: "pre-wrap"}}><b>{activeCopyCategory[0] === 1?"First Message":"Message"}</b><HighlightText text={x["message"]} /></Box>
                                    )
                                    :<></>
                                  }
                                  {("script" in x)?
                                    editCopy?
                                    <TextField size="small" label="Script" value={x["script"]} onChange={e => handleCopyDataChange(e, ind, "script")} multiline minRows={4} fullWidth/>
                                    :<Box sx={{whiteSpace: "pre-wrap"}}><b>Script</b><HighlightText text={x["script"]} /></Box>
                                    :<></>
                                  }
                                </Stack>
                              )
                            })
                            :
                            [1,2,3].map((x, ind) => {
                             return (
                               <Stack key={ind} direction="column" sx={{width: "100%"}} spacing={1}>
                                 <Skeleton variant="text" width="100%" sx={{fontSize:"30"}}/>
                                 <Skeleton variant="rectangular" width="100%" height="100"/>
                               </Stack>
                             )})
                            }
                            {editCopy?
                              <Stack direction="row" justifyContent="flex-end">
                                <Button variant="outlined" onClick={saveCopyChanges} color="primary">
                                  Save Changes
                                </Button>
                              </Stack>
                            :<></>
                            }
                          </Stack>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Paper>
                </>
              :activeStage === 2?
                activeCampaign !== null?
                  <>
                  <Paper sx={{width: "100%", p:2}} elevation={0}>
                    <Stack direction="row" justifyContent="flex-end" spacing={1} sx={{mb:1}}>
                      {activeCampaign["status"] === "Active"?
                        <>
                        <Button variant="outlined" onClick={editLeads} color="primary" startIcon={<EditOutlinedIcon />}>
                          Edit Leads
                        </Button>
                        <Button variant="contained" onClick={() => handleCompleteStage(2)} color="primary" startIcon={<CheckCircleIcon />}>
                          End Campaign
                        </Button>
                        </>
                        :<></>
                      }
                    </Stack>
                    <TipBox tip={<div>
                      <Typography><b>Why is outreach manual on Aspiro in 2024?</b></Typography>
                      <Typography>Automated outreach can fall flat when you're still figuring 
                        out your Ideal Customer Profile and the best triggers for their engagement. Inboxes are flooded by AI-generated 
                        messages right now and reply rates are abysmally low. Manual outreach lets you hyper-personalize your messaging and test it, 
                        helping you stand out and increase response rates (and subsequently getting more meetings).
                      </Typography>
                      <br />
                      <Typography><b>What is the best channel to reach out to my prospects?</b></Typography>
                      <Typography>Usually, it depends on where your target market spends time for work. If they post a lot on LinkedIn, 
                        try that and if they hang in Discord communities, consider DMing them there. Don't presume that only popular channels are your 
                        best bets. Trial and error will help you find the right answer.
                      </Typography>
                      </div>
                    }/>
                  </Paper>
                  <ExecuteCampaign activeCampaign={activeCampaign} callback={setActiveCampaign}/>
                  </>
                :<></>
              :<></>}
            </AccordionDetails>
          </Accordion>
        </Stack>
      ))}

      {activeCampaign["copy_source"] >= -1?
      <Dialog open={editCopyPopup}>
        <DialogTitle >
          Customise Copy/Scripts from {activeCampaign["copy_source"] === -1?"Aspiro Template":props.content[activeCampaign["copy_source"]]["name"]}?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            If you've already made a custom copy in this campaign, this new customization will replace it.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setEditCopyPopup(false)}>Cancel</Button>
          <Button variant="contained" onClick={handleCustomiseCopy} autoFocus>Customise</Button>
        </DialogActions>
      </Dialog>
      :<></>}
    </div>
    :<Typography>Campaign not found!</Typography>}
    </>
  );
}
