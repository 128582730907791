import React, { useState, useEffect, useRef } from 'react';
import {Skeleton, ToggleButton, ToggleButtonGroup, FormHelperText, Accordion, AccordionSummary, AccordionDetails, Typography, Box, IconButton, Button, Stack, Grid, Chip, Paper, TextField, Toolbar, Radio, Checkbox, RadioGroup} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';

import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';

import axios from "axios"
import { BACKEND_ADDRESS } from "./../utils/getEndpoint"
import { HighlightText, useUndoRedo } from '../utils/helper';
import { useSelector } from 'react-redux'
import ToastComponent from '../utils/ToastComponent';

const allChannels = ["Email/LinkedIn InMail", "WhatsApp/LinkedIn/Twitter DM etc.", "Cold Call", "Face-to-face/At Events"]
const allCTAs = ["Video Call", "In Person"]

function replaceConditionalValues(str, key, x) {
  const regex = new RegExp(`\\[${key}\\?([^:]+):([^\\]]+)\\]`, 'g');

  return str.replace(regex, (_, val1, val2) => {
    return x ? val1 : val2;
  });
}

export default function ContactLead(props){
  var listNo = props.listNo
  var leadNo = props.leadNo
  var campaign = props.campaign
  var leadList = campaign["leads"][listNo]

  var allProblems = props.cp["problems"]
  var allIndustries = props.cp["industries"]
  var allVerticals = props.cp["verticals"]

  //Initialisation attempt 1: use defaults
  var initialObj = {
    //things that can be initialised from previously filled fields
    problem: allProblems[0],
    meetingLink: "",
    areas: "",
    cta: "Video Call",
  }

  //Initialisation attempt 2: old lead. Use old data
  var oldLead = false
  if(campaign["lead_status"][listNo][leadNo].length > 1 && campaign["lead_status"][listNo][leadNo][1]["status"] === "completed"){
    oldLead = true
    initialObj = campaign["lead_status"][listNo][leadNo][1]
  }else{
    //Initialisation attempt 3: use data from last filled lead
    for(var i=0;i<campaign["lead_status"].length;i+=1){
      for(var j=0;j<campaign["lead_status"][i].length; j+=1){
        if(campaign["lead_status"][i][j].length > 1 && campaign["lead_status"][i][j][1]["status"] === "completed"){
          initialObj = campaign["lead_status"][i][j][1]
          break
        }
      }
    }
  }

  const sellerName = useSelector((state) => state.name.value);
  const account = useSelector(state => state.account.value)
  const hasProduct = campaign["has_product"]
  const [sellerCompany, setSellerCompany] = useState("")

  const [channel, setChannel] = useState(oldLead?initialObj["channel"]:"")
  const [name, setName] = useState(leadList["data"]["Name"][leadNo])
  const [problem, setProblem] = useState(initialObj["problem"])
  const [industry, setIndustry] = useState(leadList["metadata"]["industry"][leadNo])
  const [vertical, setVertical] = useState(leadList["metadata"]["vertical"][leadNo])
  const [cta, setCta] = useState(initialObj["cta"])
  const [meetingLink, setMeetingLink] = useState(initialObj["meetingLink"])
  const [areas, setAreas] = useState(initialObj["areas"])
  const [referenceName, setReferenceName] = useState(oldLead?initialObj["referenceName"]:"")

  const [channelError, setChannelError] = useState(false)
  const [nameError, setNameError] = useState(false)
  const [problemError, setProblemError] = useState(false)
  const [industryError, setIndustryError] = useState(false)
  const [verticalError, setVerticalError] = useState(false)
  const [ctaError, setCtaError] = useState(false)
  const [meetingLinkError, setMeetingLinkError] = useState(false)
  const [areasError, setAreasError] = useState(false)
  const [referenceNameError, setReferenceNameError] = useState(false)

  const [showCopy, setShowCopy] = useState(true)
  const { state: content, undo, redo, updateState: updateContent } = useUndoRedo({
    subject: oldLead?initialObj["subject"]:"",
    message: oldLead?initialObj["message"]:"",
    script: oldLead?initialObj["script"]:"",
  });
  const [instruction, setInstruction] = useState("")

  const [gotAllInputs, setGotAllInputs] = useState(oldLead)
  const [loadingMsg, setLoadingMsg] = useState("")

  useEffect(() => {
    if(oldLead) return
    axios.get(BACKEND_ADDRESS + '/account/', {params: {account_id: account}})
    .then(response => setSellerCompany(response.data["company_name"]))
  }, [])

  const copyToClipboard = (sections) => {
    var flag = true

    //if(section === "message" || section === "script"){
      if(channel === ""){
        setChannelError(true)
        flag = false
      }else setChannelError(false)

      if(name === ""){
        setNameError(true)
        flag = false
      }else setNameError(false)

      if(problem === ""){
        setProblemError(true)
        flag = false
      }else setProblemError(false)

      if(industry === ""){
        setIndustryError(true)
        flag = false
      }else setIndustryError(false)

      if(vertical === ""){
        setVerticalError(true)
        flag = false
      }else setVerticalError(false)

      if(cta === ""){
        setCtaError(true)
        flag = false
      }else setCtaError(false)

      if(meetingLink === ""){
        setMeetingLinkError(true)
        flag = false
      }else setMeetingLinkError(false)

      if(areas === ""){
        setAreasError(true)
        flag = false
      }else setAreasError(false)

      if(channel === "WhatsApp/LinkedIn/Twitter DM etc."){
        if(referenceName === ""){
          setReferenceNameError(true)
          flag = false
        }else setReferenceNameError(false)
      }
    //}

    if(flag){
      var copyText = ""
      if(sections.includes("subject")) copyText += "Subject: " + content["subject"] + "\n\n"
      if(sections.includes("message")) copyText += "Message: " + content["message"] + "\n\n"
      if(sections.includes("script")) copyText += "Script: " + content["script"] + "\n\n"

      navigator.clipboard.writeText(copyText)
      ToastComponent("info", "Copied to Clipboard Successfully!")

      var obj = {
        type: "Contact Lead",
        status: "completed",
        subject: content["subject"],
        message: content["message"],
        script: content["script"],
        channel: channel,

        //copy/script metadata
        problem: problem,
        cta: cta,
        meetingLink: meetingLink,
        areas: areas,
        referenceName: referenceName
      }

      var newActiveCampaign = JSON.parse(JSON.stringify(campaign))
      const statusLength = newActiveCampaign["lead_status"][listNo][leadNo].length
      newActiveCampaign["lead_status"][listNo][leadNo][statusLength - 1] = obj

      // store lead data labeled during campaign
      newActiveCampaign["leads"][listNo]["data"]["Name"][leadNo] = name
      newActiveCampaign["leads"][listNo]["metadata"]["industry"][leadNo] = industry
      newActiveCampaign["leads"][listNo]["metadata"]["vertical"][leadNo] = vertical

      // update everywhere
      axios.put(BACKEND_ADDRESS + '/campaign/', newActiveCampaign)
      props.callback(newActiveCampaign) // in the very end
    }
    else ToastComponent("error", "Failed to copy as content to be copied still contains placeholders")
  }

  const updateCopy = (field, copies) => {
    const sections = ["subject", "message", "script"]
    var newSectionValues

    if (copies === null) newSectionValues = [content["subject"], content["message"], content["script"]]
    else newSectionValues = copies

    for(var i =0;i<sections.length;i+=1){
      if(newSectionValues[i] !== ""){
        if(field === "name" || (field === "all" && name!== ""))newSectionValues[i] = newSectionValues[i].replaceAll("{name}", name)
        if(field === "problem" || (field === "all" && problem !== ""))newSectionValues[i] = newSectionValues[i].replaceAll("{problem}", problem)
        if(field === "industry" || (field === "all" && industry !== ""))newSectionValues[i] = newSectionValues[i].replaceAll("{industry}", industry)
        if(field === "vertical" || (field === "all" && vertical !== ""))newSectionValues[i] = newSectionValues[i].replaceAll("{vertical}", vertical)
        if(field === "cta" || (field === "all" && cta !== ""))newSectionValues[i] = replaceConditionalValues(newSectionValues[i], "meet_in_person", cta === "In Person")
        if(field === "meetingLink" || (field === "all" && meetingLink !== ""))newSectionValues[i] = newSectionValues[i].replaceAll("{meeting_link}", meetingLink)
        if(field === "areas" || (field === "all" && areas !== ""))newSectionValues[i] = newSectionValues[i].replaceAll("{add_areas}", areas)
        if(field === "referenceName" || (field === "all" && referenceName !== ""))newSectionValues[i] = newSectionValues[i].replaceAll("{friend_name}", referenceName)
      }

      if(field === "all"){
        newSectionValues[i] = newSectionValues[i].replaceAll("{seller_name}", sellerName)
        newSectionValues[i] = newSectionValues[i].replaceAll("{seller_company}", sellerCompany)
        newSectionValues[i] = replaceConditionalValues(newSectionValues[i], "has_product", hasProduct)
      }
    }

    updateContent({
      subject: newSectionValues[0],
      message: newSectionValues[1],
      script: newSectionValues[2]
    })
    return newSectionValues
  }

  useEffect(() => {
    if(oldLead) return //dont need to run any state updates if lead is old

    var ind = 0
    for(var i =0;i<allChannels.length;i+=1){
      if(allChannels[i] === channel){
        ind = i
        break
      }
    }
    var obj = props.campaign["copy_data"][ind]["copy"][0]
    var newSubject, newMessage, newScript;

    if("subject" in obj) newSubject = obj["subject"]
    else newSubject = ""

    //substitute variables before setting state
    if("message" in obj) newMessage = obj["message"]
    else newMessage = ""
    if("script" in obj) newScript = obj["script"]
    else newScript = ""

    var newValues = updateCopy("all", [newSubject, newMessage, newScript])
    correctGrammar(newValues[1], newValues[2])
  }, [channel, name, problem, industry, vertical, cta, meetingLink, areas, referenceName])

  const correctGrammar = (newMessage, newScript) => {
    if(channel === "") return false
    if(name === "") return false
    if(problem === "") return false
    if(industry === "") return false
    if(vertical === "") return false
    if(cta === "") return false
    if(meetingLink === "") return false
    if(areas === "") return false

    if(channel === "WhatsApp/LinkedIn/Twitter DM etc.")
      if(referenceName === "") return false
    
    setGotAllInputs(true)
    var interval = setInterval(() => {
      const text = "Patching Abruptness"
      setLoadingMsg(loadingMsg => {
        const dotLength = ((loadingMsg.length === 0)?3:(loadingMsg.length - text.length))
        return (dotLength === 3)?(text + "."):(loadingMsg + ".")
      })
    }, 1000)

    const data = {message: newMessage, script: newScript}
    axios.post(BACKEND_ADDRESS + '/campaign/correct_grammar/', data)
    .then(response => {
      updateContent({
        subject: content["subject"],
        message: response.data["message"],
        script: response.data["script"]
      })
      
      clearInterval(interval)
      setLoadingMsg("")
    }).catch(e => {
      clearInterval(interval)
      setLoadingMsg("")
    })
  }

  const onPersonalise = (method) => {
    var interval = setInterval(() => {
      const text = "Personalising"
      setLoadingMsg(loadingMsg => {
        const dotLength = ((loadingMsg.length === 0)?3:(loadingMsg.length - text.length))
        return (dotLength === 3)?(text + "."):(loadingMsg + ".")
      })
    }, 1000)

    axios.post(BACKEND_ADDRESS + '/campaign/personalise_message/', {type: method, subject: content["subject"], message: content["message"], script: content["script"]})
    .then(response => {

      updateContent({
        subject: content["subject"] !== ""?response.data["subject"]:content["subject"],
        message: content["message"] !== ""?response.data["message"]:content["message"],
        script: content["script"] !== ""?response.data["script"]:content["script"]
      })

      //temporary
      setLoadingMsg("")
      clearInterval(interval)
      ToastComponent("info", "Coming Soon!")
    }).catch(e => {
      setLoadingMsg("")
      clearInterval(interval)
      ToastComponent("error", "Can't personalise. Try again!")
    })
  }

  const onRewrite = (inst) => {
    inst = inst?inst:instruction
    
    var interval = setInterval(() => {
      const text = "Rewriting"
      setLoadingMsg(loadingMsg => {
        const dotLength = ((loadingMsg.length === 0)?3:(loadingMsg.length - text.length))
        return (dotLength === 3)?(text + "."):(loadingMsg + ".")
      })
    }, 1000)
    
    axios.post(BACKEND_ADDRESS + '/campaign/rewrite_message/', {instruction: inst, subject: content["subject"], message: content["message"], script: content["script"]})
    .then(response => {
      updateContent({
        subject: content["subject"] !== ""?response.data["subject"]:content["subject"],
        message: content["message"] !== ""?response.data["message"]:content["message"],
        script: content["script"] !== ""?response.data["script"]:content["script"]
      })
      
      //temporary
      setLoadingMsg("")
      clearInterval(interval)
      setInstruction("") //reset
    }).catch(e => {
      setLoadingMsg("")
      clearInterval(interval)
      ToastComponent("error", "Can't rewrite. Try again!")
    })
  }

  return (
        <Paper elevation={0} sx={{width: "100%", height: "95vh", overflowY: "auto", border: "1px solid rgba(0,0,0,0.12)", borderRadiusTopRight: 2, borderRadiusBottomRight: 2}}>
          <Grid container direction="row" spacing={2} sx={{height: "100%"}}>
            <Grid item xs={12} md={4}>
              <Paper square sx={{padding: "24 16", height: "100%", borderRight:"1px solid rgba(0, 0, 0, 0.12)", backgroundColor: "#f5f5f5"}} elevation={0}>
                <Stack direction="column" spacing={1} sx={{mb:4}}>
                  <FormLabel sx={{color: "#395af6"}}>Copy/Script Settings</FormLabel>
                  <div style={{marginTop: "12"}}></div>
                  <FormControl sx={{width: "95%"}} required error={channelError}>
                    <InputLabel sx={{ml: -2}}>Channel Type</InputLabel>
                    <Select size="small" autoFocus required value={channel} disabled={gotAllInputs}
                    variant="standard" onChange={(e) => {setChannel(e.target.value); setShowCopy(true)}}>
                      {allChannels.map((x, ind) =>
                        <MenuItem key={ind} value={x}>{x}</MenuItem>
                      )}
                    </Select>
                    {channelError?<FormHelperText sx={{ml:0}}>Select a Channel Type</FormHelperText>:<></>}
                  </FormControl>

                  {channel === "WhatsApp/LinkedIn/Twitter DM etc."?
                  <FormControl sx={{width: "95%"}} error={referenceNameError}>
                    <TextField placeholder="Sam Altman" required size="small" label="Lead's Contact Shared by" value={referenceName}
                    variant="standard" onChange={(e) => {setReferenceName(e.target.value)}} disabled={gotAllInputs}/>
                    {referenceNameError?<FormHelperText sx={{ml:0}}>Please fill a valid Name</FormHelperText>:<></>}
                  </FormControl>
                  :<></>}

                  <FormControl sx={{width: "95%"}} error={areasError}>
                    <TextField placeholder="" required size="small" label="Topic/area to discuss with lead" value={areas}
                    variant="standard" onChange={(e) => {setAreas(e.target.value)}} disabled={gotAllInputs}>
                      {areas}
                    </TextField>
                    {areasError?<FormHelperText sx={{ml:0}}>Please add a topic/area</FormHelperText>:<></>}
                  </FormControl>

                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Typography style={gotAllInputs?{color:'rgba(0,0,0,0.38'}:{}}>Meeting CTA</Typography>
                    <ToggleButtonGroup size="small" exclusive color="primary" value={cta} onChange={(e, v) => {setCta(e.target.textContent)}}>
                      {allCTAs.map((x, ind) =>
                        <ToggleButton key={ind} aria-label={x} value={x} sx={{textTransform: 'none'}} disabled={gotAllInputs}>
                          <Typography>{x}</Typography>
                        </ToggleButton>
                      )}
                    </ToggleButtonGroup>
                  </Stack>

                  <FormControl sx={{width: "95%"}} error={meetingLinkError}>
                    <TextField required size="small" label="Meeting Link" value={meetingLink}
                    variant="standard" onChange={e => setMeetingLink(e.target.value)} disabled={gotAllInputs}>
                      {meetingLink}
                    </TextField>
                    {meetingLinkError?<FormHelperText sx={{ml:0}}>Please add a meeting link</FormHelperText>:<></>}
                  </FormControl>
                </Stack>

                <Stack direction="column" spacing={1}>
                  <FormLabel sx={{color: "#395af6"}}>Lead Details</FormLabel>

                  <FormControl sx={{width: "95%"}} error={nameError}>
                    <TextField placeholder="Satya Nadella" required size="small" label="Name" value={name}
                    variant="standard" onChange={e => setName(e.target.value)} disabled={gotAllInputs}>
                      {name}
                    </TextField>
                    {nameError?<FormHelperText sx={{ml:0}}>Please add lead name</FormHelperText>:<></>}
                  </FormControl>

                  <FormControl sx={{width: "95%"}} required error={problemError}>
                    <InputLabel sx={{ml: -2}}>Buyer Problem</InputLabel>
                    <Select size="small" value={problem} disabled={gotAllInputs}
                    variant="standard" onChange={(e) => setProblem(e.target.value)}>
                      {allProblems.map((x, ind) =>
                        <MenuItem key={ind} value={x}>{x}</MenuItem>
                      )}
                    </Select>
                    {problemError?<FormHelperText sx={{ml:0}}>Please select a buyer problem for this lead</FormHelperText>:<></>}
                  </FormControl>

                  <FormControl sx={{width: "95%"}} required error={industryError}>
                    <InputLabel sx={{ml: -2}}>Industry</InputLabel>
                    <Select size="small" value={industry} disabled={gotAllInputs}
                    variant="standard" onChange={(e) => setIndustry(e.target.value)}>
                      {allIndustries.map((x, ind) =>
                        <MenuItem key={ind} value={x}>{x}</MenuItem>
                      )}
                    </Select>
                    {industryError?<FormHelperText sx={{ml:0}}>Please select an industry</FormHelperText>:<></>}
                  </FormControl>

                  <FormControl sx={{width: "95%"}} required error={verticalError}>
                    <InputLabel sx={{ml: -2}}>Vertical</InputLabel>
                    <Select size="small" value={vertical} disabled={gotAllInputs}
                    variant="standard" onChange={(e) => setVertical(e.target.value)}>
                      {allVerticals.map((x, ind) =>
                        <MenuItem key={ind} value={x}>{x}</MenuItem>
                      )}
                    </Select>
                    {verticalError?<FormHelperText sx={{ml:0}}>Please select a vertical</FormHelperText>:<></>}
                  </FormControl>
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={12} md={8}>
              <Paper sx={{padding: "24 16", height: "100%"}} elevation={0}>
                {
                  loadingMsg !== ""?
                    <Stack direction="column" sx={{width: "100%", height:"100%"}} alignItems="center" justifyContent="center">
                      <Typography variant="h6">
                        {loadingMsg}
                      </Typography>
                    </Stack>
                  :channel !== ""?
                    <Stack direction="column" sx={{width: "100%", flexWrap: 'wrap'}} spacing={1} >
                      <Stack direction="column" spacing={4} sx={{mt:0}}>
                        {content["subject"] !== ""?
                          <Typography key={content["subject"]} label="Subject" style={{userSelect: 'none'}}><b>Subject</b><br /> {content["subject"]}</Typography>
                          :<></>
                        }
                        {content["message"] !== ""?
                          <Box sx={{whiteSpace: "pre-wrap"}} key={content["message"]}><b>{channel === "WhatsApp/LinkedIn/Twitter DM etc."?"First Message":"Message"}</b><HighlightText text={content["message"]} /></Box>
                          :<></>
                        }
                        {content["script"] !== ""?
                          <Box sx={{whiteSpace: "pre-wrap"}} key={content["script"]}><b>Script</b><HighlightText text={content["script"]} /></Box>
                          :<></>
                        }
                      </Stack>

                      <Stack direction="row" spacing={0.5} alignItems="center" justifyContent="flex-end">
                        <Stack direction="column" alignItems="center">
                          <IconButton size="small" onClick={undo} disabled={!gotAllInputs}>
                            <UndoIcon fontSize="small"/>
                          </IconButton>
                          
                        </Stack>
                        <Stack direction="column" alignItems="center">
                          <IconButton size="small" onClick={redo} disabled={!gotAllInputs}>
                            <RedoIcon fontSize="small"/>
                          </IconButton>
                        </Stack>
                        <Divider orientation="vertical" flexItem style={{margin: "0 6", backgroundColor: "rgba(0,0,0,0.25)"}}/>

                        {channel === "Email/LinkedIn InMail"?
                        <Button size="small" variant="contained" startIcon={<ContentCopyIcon />} disabled={!gotAllInputs} onClick={()=>{copyToClipboard(["subject", "message"])}}>Send</Button>
                        :channel === "WhatsApp/LinkedIn/Twitter DM etc."?
                        <Button size="small" variant="contained" startIcon={<ContentCopyIcon />} disabled={!gotAllInputs} onClick={()=>{copyToClipboard(["message", "script"])}}>Send</Button>
                        :
                        <Button size="small" variant="contained" startIcon={<ContentCopyIcon />} disabled={!gotAllInputs} onClick={()=>{copyToClipboard("script")}}>Send</Button>
                        }
                      </Stack>

                      <Stack direction="row" spacing={0.5} alignItems="center" justifyContent="flex-end" sx={{flexWrap: 'wrap'}}>
                        <Typography style={!gotAllInputs?{color:'rgba(0,0,0,0.38'}:{}}>🪄 Rewrite</Typography>
                        <Button size="small" variant="outlined" sx={{borderRadius: 16}} disabled={!gotAllInputs} onClick={() => onRewrite("Make it formal")}>Formal</Button>
                        <Button size="small" variant="outlined" sx={{borderRadius: 16}} disabled={!gotAllInputs} onClick={() => onRewrite("Make it casual")}>Casual</Button>
                        <Button size="small" variant="outlined" sx={{borderRadius: 16}} disabled={!gotAllInputs} onClick={() => onRewrite("Make it humorous")}>Humorous</Button>
                        <Button size="small" variant="outlined" sx={{borderRadius: 16}} disabled={!gotAllInputs} onClick={() => onRewrite("Make it serious")}>Serious</Button>
                        <Stack direction="row" alignItems="center">
                          <TextField sx={{"& .MuiInputBase-input": {height: "30px", padding: "0 14px"}}} placeholder="Custom Instruction" value={instruction} disabled={!gotAllInputs} onChange={e => {setInstruction(e.target.value)}} />
                          <Button size="small" variant="outlined" disabled={!gotAllInputs} onClick={() => onRewrite()}>Rewrite</Button>
                        </Stack>
                      </Stack>
                      <Stack direction="row" spacing={0.5} alignItems="center" justifyContent="flex-end" sx={{flexWrap: 'wrap'}}>
                        <Typography style={!gotAllInputs?{color:'rgba(0,0,0,0.38'}:{}}>Personalise using Buyer's</Typography>
                        <Button size="small" variant="outlined" sx={{borderRadius: 16}} disabled={!gotAllInputs} onClick={() => onPersonalise(1)}>Social Media Post</Button>
                        <Button size="small" variant="outlined" sx={{borderRadius: 16}} disabled={!gotAllInputs} onClick={() => onPersonalise(2)}>Work & Expertise</Button>
                        <Button size="small" variant="outlined" sx={{borderRadius: 16}} disabled={!gotAllInputs} onClick={() => onPersonalise(3)}>Company/Industry News</Button>
                      </Stack>
                      {/*
                      [1,2,3].map((x, ind) =>
                        <Stack key={ind} direction="column" sx={{width: "100%"}} spacing={1}>
                          <Skeleton variant="text" width="100%" sx={{fontSize:"30"}}/>
                          <Skeleton variant="rectangular" width="100%" height="100"/>
                        </Stack>
                      )
                      */}
                    </Stack>
                  :<></>
                }
              </Paper>
            </Grid>
          </Grid>
        </Paper>
  )
}