import React, { useState, useEffect } from "react"
import { useSelector } from 'react-redux'
import axios from "axios"
import {BACKEND_ADDRESS} from "./../utils/getEndpoint.js"
import {isValidUrl} from "./../utils/helper.js"
import ToastComponent from "./../utils/ToastComponent";

import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {Grid, Typography, Divider, Paper} from '@mui/material';
//import Divider from '@mui/material/Divider'

import Objections from "./Objections.js"

const initializeObjections = (competitors) => {
  var c1 = "competitor", c2 = "competitor", c3 = "competitor"
  if(typeof(competitors) == typeof([]) && competitors.length > 1){
    c1 = competitors[Math.floor(Math.random() * competitors.length)]
    c2 = competitors[Math.floor(Math.random() * competitors.length)]
    c3 = competitors[Math.floor(Math.random() * competitors.length)]
  }
  const obj = {
    'Need & Urgency': [
        'I really think there are other priorities we need to address first',
        'We\'d like to wait a couple of quarters before we can try this'
    ],
    'Trust': [
        'I do not have confidence that your offering will be useful'
    ],
    'Product Benefit & ROI': [],
    'Competition & Status Quo': [
        'We tried something similar earlier, and it did not work out at all',
        'I think ' + c1 + ' is much better',
        'We\'re already doing this using ' + c2 + ', your offering will not add much'
    ],
    'Pricing & Budget': [
        'I think your offering is very expensive',
        'I have also seen ' + c3 + ', and that costs a fraction compared to you'
    ],
    'Purchase Authority': [],
    'Security & Compliance': []
  }
  return obj
}

const cleanObjectionsBeforeSave = (objs) => {
  const allKeys = Object.keys(objs)
  console.log(objs)
  for(var i=0; i < allKeys.length; i +=1){
    var categoryObjs = objs[allKeys[i]]
    var newCategoryObjs = []

    for(var j = 0; j < categoryObjs.length; j++ )
      categoryObjs[j] !== "" && newCategoryObjs.push(categoryObjs[j])

    objs[allKeys[i]] = newCategoryObjs
  }

  return objs
}

export default function Settings(props){
  //const email = useSelector((state) => state.email.value)
  const account = useSelector((state) => state.account.value);

  const [companyName, setCompanyName] = useState(null)
  const [companyWebsite, setCompanyWebsite] = useState(null)
  const [companyNameError, setCompanyNameError] = useState(false)
  const [companyWebsiteError, setCompanyWebsiteError] = useState(false)
  const [competitorError, setCompetitorError] = useState(false)
  const [competitors, setCompetitors] = useState(null)
  //const [objections, setObjections] = useState(null)
  //const [workSpaceName, setWorkspaceName] = useState(null)

  useEffect(() => {
    props.callback("heading", "Settings")
    props.callback("description", "Change settings for your team here")
    props.callback("bg", "#fbfafe")
    window.scrollTo(0,0) //scrolling to top of new screen

    // check for existing company name and website in DB
    axios.get(BACKEND_ADDRESS + '/account/', {params: {account_id: account}})
    .then((response) => {
      var tt = response.data
      setCompanyName(tt["company_name"])
      setCompanyWebsite(tt["company_website"])

      setCompetitors(tt['competitors'])
      //setObjections(tt["objections"])

      //setWorkspaceName(tt["acc"])
    }).catch(error => console.error(error));
  }, [])

  const saveSettings = () => {
    var validationFlag = true

    // data validation
    if(companyName === "" || companyName === null){
      setCompanyNameError(true)
      validationFlag = false
    }else setCompanyNameError(false)

    if(companyWebsite === null || !isValidUrl(companyWebsite)){
      setCompanyWebsiteError(true)
      validationFlag = false
    }else setCompanyWebsiteError(false)

    if(competitors === null || competitors.length < 2){
      setCompetitorError(true)
      validationFlag = false
    }else{
      setCompetitorError(false)
    }

    //if(objections === null) validationFlag = false

    //error in data. so return
    if(!validationFlag) return

    // var cleanedObjections
    // if(Object.keys(objections).length ===  0){
    //   const objs = initializeObjections(competitors)
    //   setObjections(objs)
    //   cleanedObjections = objs
    // }else{
    //   cleanedObjections = cleanObjectionsBeforeSave(objections)
    // }

    // update company name and website in DB
    axios.get(BACKEND_ADDRESS + '/account/', {params: {account_id: account}})
    .then((response) => {
      var updatedData = response.data
      updatedData["company_name"] = companyName
      updatedData["company_website"] = companyWebsite
      updatedData["competitors"] = competitors
      //updatedData["objections"] = cleanedObjections

      // update back
      axios.put(BACKEND_ADDRESS + '/account/', updatedData)
      .then((response) => {
        ToastComponent("info", "Settings updated", "#395af6")
      }).catch((error) => {console.log(error);ToastComponent("error", "Settings couldn't be updated")})
    }).catch((error) => {console.log(error);ToastComponent("error", "Settings couldn't be updated")})
  }

  return (
    <Grid container direction="row" spacing={4}>
      {/* <Grid item xs={12}>
        <Typography color="primary" variant="h6"><b>Team Settings</b></Typography>
      </Grid> */}
      {companyName != null && companyWebsite != null && competitors != null?
      <>
      <Grid item xs={6}>
        <TextField required label="Name of your company" placeholder="Microsoft" InputLabelProps={{sx: {color: "#395af6"}}}
        defaultValue={companyName} error={companyNameError} helperText={companyNameError?"Company name can't be blank":""}
        variant="outlined" sx={{width: "100%"}} onChange={(e) => setCompanyName(e.target.value)} />
      </Grid>
      <Grid item xs={6}>
        <TextField required label="Company/Product Website" placeholder="https://www.microsoft.com/" InputLabelProps={{sx: {color: "#395af6"}}}
        defaultValue={companyWebsite} error={companyWebsiteError} helperText={companyWebsiteError?"Invalid URL":""}
        variant="outlined" sx={{width: "100%"}} onChange={(e) => setCompanyWebsite(e.target.value)} />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete multiple freeSolo options={[]} value={competitors}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip variant="outlined" label={option} {...getTagProps({ index })} />
            ))
          }
          onChange={(event, newValue) => {setCompetitors(newValue)}}
          renderInput={(params) => (
            <TextField {...params} required variant="outlined" label="Competitor Companies" InputLabelProps={{sx: {color: "#395af6"}}}
            placeholder="Press enter after typing to add" helperText={competitorError?"Add at least 2 companies":""} error={competitorError}/>
          )}
        />
      </Grid>
      {/*
      <Grid item xs={12} style={{marginTop: "24px"}}>
        {objections !== null && Object.keys(objections).length > 0?
          <Objections objections={objections} changeCallback={(v) => setObjections(v)} />
          :<></>
        }
      </Grid>
      */}
      <Paper square style={{position: "sticky", bottom: "0px", zIndex: 5, width: "100%", marginLeft: "14px", marginTop: "12px"}} elevation={0}>
        <center>
          <Button onClick={saveSettings} autoFocus variant="contained"
          sx={{textTransform: 'none', marginBottom: "12px"}}>
            Save Changes
          </Button>
        </center>
      </Paper>
      </>
      :<></>
      }
    </Grid>
  )
}
