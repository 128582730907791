import React, { useState, useEffect } from "react"
import { useSelector } from 'react-redux'
import axios from "axios"

import {BACKEND_ADDRESS} from "./utils/getEndpoint.js"
import {isValidUrl} from "./utils/helper.js"
import ToastComponent from "./utils/ToastComponent";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import Button from '@mui/material/Button';
import {Grid, Typography} from '@mui/material';

import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
//import Carousel from 'react-material-ui-carousel'
//import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackwardIcon from '@mui/icons-material/ArrowBack';


const initializeObjections = (competitors) => {
  var c1 = "competitor", c2 = "competitor", c3 = "competitor"
  if(typeof(competitors) == typeof([]) && competitors .length > 1){
    c1 = competitors[Math.floor(Math.random() * competitors.length)]
    c2 = competitors[Math.floor(Math.random() * competitors.length)]
    c3 = competitors[Math.floor(Math.random() * competitors.length)]
  }
  const obj = {
    'Need & Urgency': [
        'I really think there are other priorities we need to address first',
        'We\'d like to wait a couple of quarters before we can try this'
    ],
    'Trust': [
        'I do not have confidence that your offering will be useful'
    ],
    'Product benefit & ROI': [],
    'Competition & Status Quo': [
        'We tried something similar earlier, and it did not work out at all',
        'I think ' + c1 + ' is much better',
        'We\'re already doing this using ' + c2 + ', your offering will not add much'
    ],
    'Pricing & Budget': [
        'I think your offering is very expensive',
        'I have also seen ' + c3 + ', and that costs a fraction compared to you'
    ],
    'Purchase Authority': [],
    'Security & Compliance': []
  }
  return obj
}

export default function Onboarding(props){
  const email = useSelector((state) => state.email.value)
  const account = useSelector((state) => state.account.value);

  const [page, setPage] = useState(1)
  const [companyName, setCompanyName] = useState("")
  const [companyWebsite, setCompanyWebsite] = useState("")
  const [competitors, setCompetitors] = useState([])
  const [gtmStage, setGtmStage] = useState("")
  const [productStage, setProductStage] = useState("")

  const [companyNameError, setCompanyNameError] = useState(false)
  const [companyWebsiteError, setCompanyWebsiteError] = useState(false)
  const [competitorError, setCompetitorError] = useState(false)
  const [gtmStageError, setGtmStageError] = useState(false)
  const [productStageError, setProductStageError] = useState(false)

  const styles = {width: "400px"}

  useEffect(() => {
    // check for existing company name and website in DB
    axios.get(BACKEND_ADDRESS + '/account/', {params: {account_id: account}})
    .then((response) => {
      var data = response.data
      if(data["company_name"] !== "" && data["company_website"] !== "" && data["competitors"].length > 0){
        // this means some other user from this account has already set these values up.
        // So we can skip these steps in onboarding for this user

        //change onboarding state in DB
        axios.post(BACKEND_ADDRESS + '/user/user_by_email/', {'email': email })
        .then((response) => {
          var data = response.data[0]
          data["onboarding"] = true

          //update in backend
          axios.put(BACKEND_ADDRESS + '/user/', data)
        })

        //close onboarding window
        props.setOnboarded(true)
      }else{
        console.log("account onboarding not done yet!")
      }
    })
  }, [])

  const completeOnboarding = () => {
    var validationFlag = true

    if(page === 1){
      // data validation
      if(companyName === ""){
        setCompanyNameError(true)
        validationFlag = false
      }else setCompanyNameError(false)

      if(!isValidUrl(companyWebsite)){
        setCompanyWebsiteError(true)
        validationFlag = false
      }else setCompanyWebsiteError(false)

      if(competitors === null || competitors.length < 2){
        setCompetitorError(true)
        validationFlag = false
      }else{
        setCompetitorError(false)
      }

      //error in data. so return
      if(!validationFlag) return
      else setPage(page + 1)
    }else{
      if(gtmStage === ""){
        setGtmStageError(true)
        validationFlag = false
      }else setGtmStageError(false)

      if(productStage === ""){
        setProductStageError(true)
        validationFlag = false
      }else setProductStageError(false)

      //error in data. so return
      if(!validationFlag) return
      else setPage(page + 1)
    }

    if(page === 2){//ie last page
      // update company name and website in DB
      axios.get(BACKEND_ADDRESS + '/account/', {params: {account_id: account}})
      .then((response) => {
        var updatedData = response.data
        updatedData["company_name"] = companyName
        updatedData["company_website"] = companyWebsite
        updatedData["competitors"] = competitors
        //updatedData["objections"] = initializeObjections(competitors)
        //updatedData["gtm_stage"] = gtmStage
        //updatedData["product_stage"] = productStage

        // update back
        axios.put(BACKEND_ADDRESS + '/account/', updatedData)
      })

      //change onboarding state in DB
      axios.post(BACKEND_ADDRESS + '/user/user_by_email/', {'email': email })
      .then((response) => {
        var data = response.data[0]
        data["onboarding"] = true

        //update in backend
        axios.put(BACKEND_ADDRESS + '/user/', data)
      })

      //ToastComponent("info", "Perfect! You can edit this data later in the Settings section!", "#395af6")
    }
  }

  return (
    <Dialog open={page<3} size="xs">
      {/*}<DialogTitle color="primary">
        <center>Welcome to Aspiro 🤗</center>
      </DialogTitle>
      */}
      {page == 1?
        <>
        <DialogContent>
            <Grid container direction="column" spacing={2} alignItems="center">
              <Grid item>
                <Typography color="primary" variant="h5">Welcome to Aspiro 🤗</Typography>
              </Grid>
              <Grid item style={{marginTop: "8"}}>
                To get started, tell us about your company.
              </Grid>
              <Grid item>
                <TextField required label="Company Name" placeholder="Microsoft"
                error={companyNameError} helperText={companyNameError?"Company name can't be blank":""}
                variant="standard" sx={styles} onChange={(e) => setCompanyName(e.target.value)} />
               </Grid>
              <Grid item>
                <TextField required label="Company/Product Website" placeholder="https://www.microsoft.com/"
                error={companyWebsiteError} helperText={companyWebsiteError?"Invalid URL":""}
                variant="standard" sx={styles} onChange={(e) => setCompanyWebsite(e.target.value)}/>
              </Grid>
              <Grid item>
                <Autocomplete multiple freeSolo options={[]} value={competitors}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                    ))
                  }
                  sx={styles}
                  onChange={(event, newValue) => {setCompetitors(newValue)}}
                  renderInput={(params) => (
                    <TextField {...params} required variant="standard" label="Competitor Companies"
                    placeholder="Press enter after typing to add" helperText={competitorError?"Add at least 2 companies":""} error={competitorError}/>
                  )}
                />
              </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" startIcon={<ArrowForwardIcon />} onClick={completeOnboarding} autoFocus sx={{textTransform: 'none'}}>Next</Button>
        </DialogActions>
        </>
        :
        <>
        <DialogContent>
            <Grid container direction="column" spacing={3} alignItems="center">
              <Grid item>
                <FormControl required={true} sx={styles} error={gtmStageError}>
                  <FormLabel style={{color: "#395af6"}}>GTM Stage</FormLabel>
                  <RadioGroup onChange={(e) => setGtmStage(e.target.value)}>
                    <FormControlLabel value={"Recently Started Working on an Idea"} control={<Radio />} label={"Recently Started Working on an Idea"} />
                    <FormControlLabel value={"Had a few Early Customer Conversations"} control={<Radio />} label={"Had a few Early Customer Conversations"} />
                    <FormControlLabel value={"Deployed Product for a few Customers"} control={<Radio />} label={"Deployed Product for a few Customers"} />
                    <FormControlLabel value={"Have Paid customers"} control={<Radio />} label={"Have Paid customers"} />
                    <FormControlLabel value={"Have Started Hiring for Sales"} control={<Radio />} label={"Have Started Hiring for Sales"} />
                    <FormControlLabel value={"Have a Small Sales Team"} control={<Radio />} label={"Have a Small Sales Team"} />
                  </RadioGroup>
                  <FormHelperText>{gtmStageError?"Pick your current GTM Stage":""}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl required={true} sx={styles} error={productStageError}>
                  <FormLabel style={{color: "#395af6"}}>Product Development Stage</FormLabel>
                  <RadioGroup onChange={(e) => setProductStage(e.target.value)}>
                    <FormControlLabel value={"Haven't Started Building"} control={<Radio />} label={"Haven't Started Building"} />
                    <FormControlLabel value={"Have a Prototype with No Active Users"} control={<Radio />} label={"Have a Prototype with No Active Users"} />
                    <FormControlLabel value={"Have a Product with Early Users"} control={<Radio />} label={"Have a Product with Early Users"} />
                  </RadioGroup>
                  <FormHelperText>{productStageError?"Pick your current Product Development Stage":""}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
        </DialogContent>
        <DialogActions sx={{justifyContent: "space-between"}}>
          <Button variant="outlined" onClick={completeOnboarding} autoFocus sx={{textTransform: 'none'}} startIcon={<ArrowBackwardIcon />}>Back</Button>
          <Button variant="contained" onClick={completeOnboarding} autoFocus sx={{textTransform: 'none'}} startIcon={<PlayCircleOutlineIcon />}>Let's Start</Button>
        </DialogActions>
        </>
      }
    </Dialog>
  )
}
