import React, {useEffect, useState} from "react"
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {Grid, Typography, Skeleton} from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';

import axios from "axios"
import { BACKEND_ADDRESS } from "./../utils/getEndpoint"
import { PERSONAS, STAGE_MAPPING } from "./../utils/constants"
import TipBox from "./../utils/TipBox"

import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ListIcon from '@mui/icons-material/List';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const getScore = (row) => {
  var score = 0;
  if(row.type === "value"){
    if(row.value?.length === row.veryHigh) score=4
    else if(row.value?.length === row.high) score=3
    else if(row.value?.length === row.medium) score=2
    else score = 1
  }else if(row.type === "value_reverse"){
    if(row.value?.length === row.low) score=1
    else if(row.value?.length === row.medium) score=2
    else if(row.value?.length === row.high) score=3
    else score = 4
  }else if(row.type === "buyingSignalsTech"){
    if(row.value?.length === 0) score = 1
    else if(row.value?.[0] === "Yes, Not Public") score = 2
    else if(row.value?.[0] === "Yes, Public") score = 4
  }else if(row.type === "teamSize"){
    if(row.value?.length > 1) score = 1
    else if(row.value?.[0] === "Range of 100") score = 2
    else if(row.value?.[0] === "Range of 50") score = 3
    else if(row.value?.[0] === "Range of 10") score = 4
  }
  return score
}

export function CPSharpnessTable(props){
  const state = props.state
  const rows = props.rows
  const tipElement = props.tipElement
  const st = props.st
  const percent = props.percent
  const mode = props.mode

  return (
    <Table size="small">
      <TableHead sx={{backgroundColor: "#f5f5f5"}}>
        {mode === 'dashboard'?
        <TableRow sx={{backgroundColor: "#f5f5f5"}}>
          <TableCell colSpan={6} sx={{p: 3}}>
            {tipElement}
          </TableCell>
        </TableRow>
        :<></>}
        <TableRow>
          <TableCell rowSpan={2}><Typography><b>Attribute</b></Typography></TableCell>
          <TableCell rowSpan={2}><Typography><b>Attribute Value(s)</b></Typography></TableCell>
          <TableCell colSpan={4}><center><Typography><b>Sharpness</b></Typography></center></TableCell>
        </TableRow>
        <TableRow>
          <TableCell><Typography><b>Very High (4pts)</b></Typography></TableCell>
          <TableCell><Typography><b>High (3pts)</b></Typography></TableCell>
          <TableCell><Typography><b>Medium (2pts)</b></Typography></TableCell>
          <TableCell><Typography><b>Low (1pt)</b></Typography></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row) => (
          <TableRow key={row.name} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
            <TableCell component="th" scope="row">
              {row.name}
            </TableCell>
            <TableCell>
              {row.value?.map((x, ind) => {
                return (
                  <Chip key={ind} label={x} sx={{color: "primary.main"}} style={{marginBottom: 2}} />
                )
              })}
            </TableCell>
            {row.type === "value"?
              <>
                {state === "loading"?
                  [1,2,3,4].map((x, ind) => <TableCell key={ind}><Skeleton /></TableCell>)
                  :
                  <>
                  <TableCell sx={row.veryHigh === row.value.length?{backgroundColor: "#c2caed"}:{}}>{row.veryHigh}</TableCell>
                  <TableCell sx={row.high === row.value.length?{backgroundColor: "#c2caed"}:{}}>{row.high}</TableCell>
                  <TableCell sx={row.medium === row.value.length?{backgroundColor: "#c2caed"}:{}}>{row.medium}</TableCell>
                  <TableCell sx={row.low <= row.value.length?{backgroundColor: "#c2caed"}:{}}>{row.low + "+"}</TableCell>
                  </>
                }
              </>
            :row.type === "value_reverse"?
              <>
                {state === "loading"?
                  [1,2,3,4].map((x, ind) => <TableCell key={ind}><Skeleton /></TableCell>)
                  :
                  <>
                  <TableCell sx={row.veryHigh <= row.value.length?{backgroundColor: "#c2caed"}:{}}>{row.veryHigh + "+"}</TableCell>
                  <TableCell sx={row.high === row.value.length?{backgroundColor: "#c2caed"}:{}}>{row.high}</TableCell>
                  <TableCell sx={row.medium === row.value.length?{backgroundColor: "#c2caed"}:{}}>{row.medium}</TableCell>
                  <TableCell sx={row.low === row.value.length?{backgroundColor: "#c2caed"}:{}}>{row.low}</TableCell>
                  </>
                }
              </>
            :row.type === "buyingSignalsTech"?
              <>
                {state === "loading"?
                  [1,2,3,4].map((x, ind) => <TableCell key={ind}><Skeleton /></TableCell>)
                  :
                  <>
                  <TableCell sx={row.value.length > 1 && row.value[0] === "Yes, Public"?{backgroundColor: "#c2caed"}:{}}>Yes, Public</TableCell>
                  <TableCell></TableCell>
                  <TableCell sx={row.value.length > 1 && row.value[0] === "Yes, Not Public"?{backgroundColor: "#c2caed"}:{}}>Yes, Not Public</TableCell>
                  <TableCell sx={row.value.length === 0?{backgroundColor: "#c2caed"}:{}}>None</TableCell>
                  </>
                }
              </>
            :row.type === "teamSize"?
              <>
                {state === "loading"?
                  [1,2,3,4].map((x, ind) => <TableCell key={ind}><Skeleton /></TableCell>)
                  :
                  <>
                  <TableCell sx={row.value.length === 1 && row.value[0] === "Range of 10"?{backgroundColor: "#c2caed"}:{}}>Range of 10</TableCell>
                  <TableCell sx={row.value.length === 1 && row.value[0] === "Range of 50"?{backgroundColor: "#c2caed"}:{}}>Range of 50</TableCell>
                  <TableCell sx={row.value.length === 1 && row.value[0] === "Range of 100"?{backgroundColor: "#c2caed"}:{}}>Range of 100</TableCell>
                  <TableCell sx={row.value.length > 1?{backgroundColor: "#c2caed"}:{}}>Multiple Ranges</TableCell>
                  </>
                }
              </>
            :<></>}
          </TableRow>
        ))}
        <TableRow>
          <TableCell rowSpan={2} colSpan={2} />
          <TableCell align="right" colSpan={2}>CP Sharpness</TableCell>
          {state === "loading"?
            <TableCell colSpan={2}><Skeleton /></TableCell>
            :
            <TableCell colSpan={2}>{st}</TableCell>
          }
        </TableRow>
        <TableRow>
          <TableCell align="right" colSpan={2}><Typography variant="h6">CP Sharpness</Typography></TableCell>
          {state === "loading"?
            <TableCell colSpan={2} style={{backgroundColor: "#395af6"}}><Skeleton /></TableCell>
            :
            <TableCell colSpan={2} style={{backgroundColor: "#395af6"}}><Typography color="white">{Math.round(percent * 10) / 10}%</Typography></TableCell>
          }
        </TableRow>
      </TableBody>
    </Table>
  )
}

export default function CustomerProfileScore(props){
  const [state, setState] = useState(props.mode==='dashboard'?(props.data?'open':'loading'):'locked') //locked/loading/open/dashboard
  const activeCustomerProfile = props.data
  let history = useHistory()
  var rows = [
    {
      name: "Industries",
      value: activeCustomerProfile?.["industries"],
      type: "value",
      veryHigh: 2,
      high: 3,
      medium: 4,
      low: 5,
    },
    {
      name: "Verticals",
      value: activeCustomerProfile?.["verticals"],
      type: "value",
      veryHigh: 1,
      high: 2,
      medium: 3,
      low: 4
    },
    {
      name: "Geographies",
      value: activeCustomerProfile?.["geographies"],
      type: "value",
      veryHigh: 1,
      high: 2,
      medium: 3,
      low: 4
    },
    {
      name: "Company Size",
      value: activeCustomerProfile?.["company_size"],
      type: "value",
      veryHigh: 1,
      high: 2,
      medium: 3,
      low: 4
    },
    {
      name: "Team Size",
      value: activeCustomerProfile?.["team_size"],
      type: "teamSize"
    },
    {
      name: "Technical Buying Signals",
      value: activeCustomerProfile?.["buying_signals_tech"],
      type: "buyingSignalsTech"
    },
    {
      name: "Non-technical Buying Signals",
      value: activeCustomerProfile?.["buying_signals_non_tech"],
      type: "value_reverse",
      veryHigh: 3,
      high: 2,
      medium: 1,
      low: 0
    }
  ]

  rows = rows.map(x => {
    x["score"] = getScore(x)
    return (x)
  })

  var st = rows.map((x, ind) => x["score"] + " + ").reduce((temp, row) => temp + row)
  st = st.substring(0, st.length - 2) + "= "

  st = st + rows.reduce((temp, row) => temp + row["score"], 0) + "/" + 4*rows.length
  var percent =  rows.reduce((temp, row) => temp + row["score"], 0)/(4*rows.length) * 100

  useEffect(() => {
    if(props.mode === 'dashboard'){
      props.callback(Math.round(percent*10)/10)
    }
  }, [])

  const tipElementShorter = <Typography><b>CP Sharpness</b> is a measure of how precise your Customer Profile is. Broader CPs lead to wasted efforts in your campaigns and pose a RISK of selling to customers who don't really want your offering or buy in very different ways.<br /><br /></Typography>
  const tipElement = <Typography><b>CP Sharpness</b> is a measure of how precise your Customer Profile is. Broad CPs lead to wasted efforts in your campaigns and pose a RISK of selling to customers who don't really want your offering or buy in very different ways. Your goal is to start with a broad CP and run campaigns to iterate on attribute values, until your CP is ideal. <b>An Ideal Customer Profile (ICP) should ONLY contain a set of attribute values where customers buy in the same way and buy a lot (high buying potential).</b></Typography>

  if(state === "loading"){
    setTimeout(function () {
      if(!(props.mode === 'dashboard' && props.data === null)) setState('open')
    }, 5000)
  }

  return (
    <>
    {props.mode === 'dashboard'?
      <CPSharpnessTable key={state} state={state} rows={rows} tipElement={tipElement} st={st} percent={percent} mode={props.mode} />
      :
      <Paper style={{borderRadius: "8px", padding: "24px", backgroundColor: (state === "locked")?"#f5f5f5":"white", border: "1px solid rgba(0,0,0,0.12)"}} id="cpsharpness">
        <Grid container direction="row" spacing={1} alignItems="center">
          {state === 'loading' || state === 'open'?
          <>
          <Grid item xs={12}>
            <Typography variant="h5">CP Sharpness</Typography>
          </Grid>
          <Grid item xs={5}>
            <center>
            {state === "loading"?
            <Skeleton variant="rectangular" width={244} height={104}/>
            :
            <>
            <Typography variant="h2" display="inline">{Math.round(percent * 10) / 10}%</Typography>&nbsp;&nbsp;
            {percent < 85?<Typography variant="h6" color="red" display="inline">Low</Typography>:<Typography variant="h6" color="green" display="inline">Good</Typography>}
            </>
            }
            <Typography variant="h6">CP Sharpness</Typography>
            </center>
            <br />
            <center>{percent < 85?<><Typography variant="subtitle2" color="red"></Typography><Button variant="contained" onClick={() => history.push("/campaigns")}>Run a Campaign to Sharpen CP</Button></>:<></>}</center>
            <br />
          </Grid>
          </>
          :<></>
          }
          <Grid item xs={state === 'locked'?12:7}>
            {state === 'locked'?tipElementShorter:tipElement}
          </Grid>
          {state !== 'locked'?
            <>
              <Grid item xs={12}>
                <Typography><b>Explanation</b></Typography>
              </Grid>
              <Grid item xs={12}>
                <TableContainer component={Paper} elevation={0} sx={{border: "0.5px solid rgba(0,0,0,0.12)"}}>
                <CPSharpnessTable key={state} state={state} rows={rows} tipElement={tipElement} st={st} percent={percent} mode={props.mode} />
                </TableContainer>
              </Grid>
            </>
            :
            <Grid item xs={12}>
              <center><Button variant="contained" onClick={() => setState("loading")} startIcon={<CalculateOutlinedIcon />}>Calculate CP Sharpness</Button></center>
            </Grid>
          }
        </Grid>
      </Paper>
    }
    </>
  )
}
