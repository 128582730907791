import React from "react"
import { useHistory } from "react-router-dom";
import {Typography, Stack} from '@mui/material';
import Button from '@mui/material/Button';

export default function DoesNotExist(props){
  let history = useHistory();

  return (
    <Stack direction="column" style={{marginTop: "30vh"}} alignItems="center" spacing={2}>
      <Typography variant="h5"> The Page you are looking for does not exist</Typography>
      <Button variant="contained" onClick={() => history.push("/")} sx={{textTransform: 'none'}}>Go to Home</Button>
    </Stack>
  )
}
