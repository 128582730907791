import React, {useEffect, useState} from "react"
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {Grid, Skeleton, Typography} from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import ToastComponent from "./../utils/ToastComponent";
import Chip from '@mui/material/Chip';
import CircleIcon from '@mui/icons-material/Circle';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import axios from "axios"
import { BACKEND_ADDRESS } from "./../utils/getEndpoint"
import { PERSONAS, STAGE_MAPPING } from "./../utils/constants"

import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import LockIcon from '@mui/icons-material/Lock';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider'

import AddMessaging from "./AddMessaging"
import AddPositioning from "./AddPositioning"
import PositioningContent from "./PositioningContent"

const positioningHeadingStyle = {
  fontWeight: "medium", overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: '1', WebkitBoxOrient: 'vertical'
} //all this is added to constraint heading to one line- https://stackoverflow.com/a/71324014/9160820
const positioningDescriptionStyle = {
  fontWeight: "medium", overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: '3', WebkitBoxOrient: 'vertical'
} //all this is added to constraint heading to one line- https://stackoverflow.com/a/71324014/9160820
const messagingDescriptionStyle = {
  fontWeight: "medium", overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: '2', WebkitBoxOrient: 'vertical'
}

function PositioningCard(props){
  const [delPopup, setDelPopup] = useState(false)
  const mode = props.mode

  const onDelete = (id) => {
    axios.delete(BACKEND_ADDRESS + '/positioning/', {params: {id: id}})
    .then((response) => ToastComponent("info", "Positioning deleted successfully!"))
    .catch((error) => ToastComponent("error", "Couldn't delete Positioning"))
  }

  return (
    <>
      <Paper style={{height: "480px", width: "100%", borderRadius: "8px", padding: "24px", position: "relative", overflowY: "auto"}}
      elevation={4} >
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            {mode === "empty"?
            <Skeleton variant="text" sx={{fontSize:"36", width: "200"}} />
            :
            <Typography display="inline" variant="h5" sx={positioningHeadingStyle}><b>{props.data["name"]}</b></Typography>
            }
            {/*<Chip size="small" label={props.data["stage"]} style={{background: 'linear-gradient(to right, primary.main, #19F4A6)', color: "white"}} />
            */}
          </Grid>
          <Grid item>
            {mode === "empty"?
            <Button startIcon={<EditIcon />} variant="contained" size="small">
              Edit
            </Button>
            :
            <Button onClick={() => props.selectPositioning(props.data["_id"]["$oid"])} startIcon={<EditIcon />}
            variant="contained" size="small">
              Edit
            </Button>
            }
          </Grid>
        </Grid>
        
        <Typography style={{paddingTop: "8px"}}><b>Conversation Opener</b></Typography>
        {mode === "empty"?
        <Skeleton variant="text" sx={{fontSize: "16"}} />
        :
        <Typography sx={positioningDescriptionStyle} variant="subtitle2">{props.data["statement"]}</Typography>
        }
        <Divider sx={{margin: "12px 0"}}/>

        <Grid container direction="row" alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <Typography><b>Customer Profile</b></Typography>
            <Stack direction="row" spacing={1} sx={{flexWrap: 'wrap'}}>
              {mode === "empty"?
                <Skeleton variant="text" sx={{width: "50", mb: 2}}/>
                :
                <Chip label={props.cpName} sx={{color: "primary.main"}} style={{marginBottom: 2}}/>
              }
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Typography><b>Strategic Narrative</b></Typography>
            <Stack direction="row" spacing={1} sx={{flexWrap: 'wrap'}}>
              {mode === "empty"?
                <Skeleton variant="text" sx={{width: "50", mb: 2}}/>
                :
                <Chip label={props.data["narrative"]} sx={{color: "primary.main"}} style={{marginBottom: 2}}/>
              }
            </Stack>
          </Grid>

          <Grid item xs={6}>
            <Typography><b>Maturity</b></Typography>
            <Stack direction="row" spacing={1} sx={{flexWrap: 'wrap'}}>
              {mode === "empty"?
                <Skeleton variant="text" sx={{width: "50", mb: 2}}/>
                :
                <Chip label={props.data["maturity"]} sx={{color: "primary.main"}} style={{marginBottom: 2}}/>
              }
            </Stack>
          </Grid>

          <Grid item xs={6}>
            <Typography><b>Outcomes</b></Typography>
            <Stack direction="column">
              {mode === "empty"?
                [1,2,3,4].map((x, ind) =>
                  <Skeleton key={ind}variant="text" sx={{width: "50", mb: 2}}/>
                )
                :props.data["outcomes"].map((x, ind) =>
                  <Typography key={ind} variant="subtitle2">- {x}</Typography>
                )
              }
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Typography><b>Use Cases</b></Typography>
            <Stack direction="column">
              {mode === "empty"?
                [1,2,3,4].map((x, ind) =>
                  <Skeleton key={ind}variant="text" sx={{width: "50", mb: 2}}/>
                )
                :props.data["use_cases"].map((x, ind) =>
                  <Typography key={ind} variant="subtitle2">- {x}</Typography>
                )
              }
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Typography><b>Product Categories</b></Typography>
            <Stack direction="column">
              {mode === "empty"?
                [1,2,3,4].map((x, ind) =>
                  <Skeleton key={ind}variant="text" sx={{width: "50", mb: 2}}/>
                )
                :props.data["categories"].map((x, ind) =>
                  <Typography key={ind} variant="subtitle2">- {x}</Typography>
                )
              }
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Typography><b>Capabilities</b></Typography>
            <Stack direction="column">
              {mode === "empty"?
                [1,2,3,4].map((x, ind) =>
                  <Skeleton key={ind}variant="text" sx={{width: "50", mb: 2}}/>
                )
                :props.data["capabilities"].map((x, ind) =>
                  <Typography key={ind} variant="subtitle2">- {x}</Typography>
                )
              }
            </Stack>
          </Grid>

          <Grid item xs={6}>
            <Typography><b>Competitors</b></Typography>
            <Stack direction="column">
              {mode === "empty"?
                [1,2,3,4].map((x, ind) =>
                  <Skeleton key={ind} variant="text" sx={{width: "50", mb: 2}}/>
                )
                :props.data["competitors"].map((x, ind) =>
                  <Typography key={ind} variant="subtitle2">- {x}</Typography>
                )
              }
            </Stack>
          </Grid>
        </Grid>
        

        {/*
        mode === "empty"?
          <Grid container direction="row" spacing={1}>
            {[1,2,3].map((x, ind) => 
              <Grid item key={ind}>
                <Paper sx={{width: "60px", borderRadius: "4px"}} elevation={0}>
                  <Avatar src={PERSONAS[x]} variant="square" sx={{width: "100%", height: "60px"}} />
                  <center><Skeleton variant="text" sx={{fontSize: "11px"}}/></center>
                </Paper>
              </Grid>
            )}
          </Grid>
        :
        props.data["messagings"].length > 0?
          <Grid container direction="row" spacing={1}>
          {props.data["messagings"].map((x, ind) => {
            return (
              <Grid item key={ind}>
                <Paper sx={{width: "60px", borderRadius: "4px"}} elevation={0}>
                  <Avatar src={PERSONAS[x["avatar"]]} variant="square" sx={{width: "100%", height: "60px"}} />
                  <center><Typography fontSize="11" sx={messagingDescriptionStyle}>{x["designation"] + ", " + x["vertical"]}</Typography></center>
                </Paper>
              </Grid>
            )
          })}
          </Grid>
        :<Typography variant="subtitle2"><i>No Positioning created yet!</i></Typography>
        */}
      </Paper>

      <Dialog open={delPopup} onClose={() => setDelPopup(false)}>
        <DialogTitle>
          Are you sure you want to delete?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            All progress/history of this positioning will be deleted!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDelPopup(false)} autoFocus>Cancel</Button>
          <Button onClick={onDelete} style={{color: "red"}}>Delete</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const emptyPositioning = {
  id: "",
  name: "",
  statement: "",
  cp_id: "",
  narrative: "",
  maturity: "",
  outcomes: [],
  use_cases: [],
  categories: [],
  capabilities: [],
  competitors: []
}

export default function Positioning(props) {
  const activePage = props.subMode
  const [positionings, setPositionings] = useState(null)
  const [allCp, setAllCp] = useState(null);
  const [cpNames, setCpNames] = useState(null)
  const account_id = useSelector((state) => state.account.value)

  useEffect(() => {
    axios.get(BACKEND_ADDRESS + '/positioning/all_positionings/', {params: {account_id: account_id}})
    .then((response) => { 
      var result = response.data["positionings"].reverse()
      result = result.map((x, ind) => {return {ind: ind, ...x}})
      setPositionings(result)
    })
  }, [])

  // Fetch allCp data on load
  useEffect(() => {
    axios
      .get(`${BACKEND_ADDRESS}/cp/all_cps/`, { params: { account_id: account_id } })
      .then((response) => {
        const result = response.data["customer_profiles"].map((x, ind) => ({ ind, ...x }));
        setAllCp(result);
      })
      .catch((error) => {
        console.error("Error fetching customer profiles:", error);
      });
  }, []);

  if(positionings !== null && allCp !== null && cpNames === null){
    var cpIdToName = {}
    var names = []

    for(var i=0;i<allCp.length;i+=1){
      cpIdToName[allCp[i]["_id"]["$oid"]] = allCp[i]["name"]
    }
    console.log(cpIdToName)
    for(var i=0;i<positionings.length;i+=1){
      if(positionings[i]["cp_id"] in cpIdToName) names.push(cpIdToName[positionings[i]["cp_id"]])
      else names.push("")
    }
    console.log(names)
    setCpNames(names);
  }

  var activePositioning = null
  var activeMessaging = null

  if(positionings !== null){
    if(activePage === "positioning_content" || activePage === "add_messaging" || activePage === "edit_messaging"){
      for(var i=0;i<positionings.length;i+=1){
        if(positionings[i]["_id"]["$oid"] === props.match.params.p_id){
          activePositioning = positionings[i]
          activePositioning["id"] = activePositioning["_id"]["$oid"]
          break
        }
      }
  
      // if(activePage === "edit_messaging" && activePositioning !== null){
      //   for(i=0;i<activePositioning["messagings"].length;i+=1){
      //     if(activePositioning["messagings"][i]["_id"]["$oid"] === props.match.params.p_id){
      //       activeMessaging = activePositioning["messagings"][i]
      //       break
      //     }
      //   }
      // }
    }
  }

  useEffect(() => {
    if(activePage === "all_positioning"){
      props.callback("heading", "Market Positioning")
      props.callback("description", "Position your product to resonate with your target audience!")
    }else if(activePage === "positioning_content" && activePositioning !== null){
      props.callback("heading", activePositioning.name)
      props.callback("description", "Edit positioning and save changes")
    }else if(activePage === "add_positioning"){
      props.callback("heading", "Create Positioning")
      props.callback("description", "Describe new Positioning")
    // }else if(activePage === "add_messaging"){
    //   props.callback("heading", "Add Messaging")
    //   props.callback("description", "Add new Messaging in a Positioning")
    // }else if(activePage === "edit_messaging" && activeMessaging !== null){
    //   props.callback("heading", activeMessaging["designation"] + " at " + activeMessaging["vertical"])
    //   props.callback("description", "Edit messaging to reflect your updated understanding")
    }

    window.scrollTo(0,0) //scrolling to top of new screen
  }, [activePage, activePositioning, activeMessaging])

  let history = useHistory();

  const selectPositioning = (ind) => {
    history.push('/positioning/' + String(ind));
  }

  const addPositioning = () => {
    history.push('/positioning/add_new');
  }

  return (
    allCp !== null && allCp.length === 0?
      <Dialog open={true}>
        <DialogTitle>
          Section Locked 🔒
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Before working on Market Positioning, create a Customer Profile first.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => history.push("/icp")} variant="contained" autoFocus>Create Customer Profile</Button>
        </DialogActions>
      </Dialog>
      :
    activePage === "all_positioning"?
      <>
      <Typography>Struggling to get your buyers to understand the value of your product? Position your product for closing deals today!</Typography>&nbsp;
      <Button variant="contained" autoFocus startIcon={<ControlPointIcon />} style={{textTransform: 'none'}} onClick={addPositioning}>
        Create New Positioning
      </Button>
      <div style={{marginTop: "30"}}/>
      <Grid container direction="row" spacing={2}>
        {positionings === null || cpNames === null?
          [1,2,3].map((_, ind) => {
            return (
              <Grid item key={ind} xs={12} md={6} lg={4}>
                <PositioningCard mode="empty" />
              </Grid>
            )
          })
        :positionings.map((val, ind) => {
          return (
            <Grid item key={ind} xs={12} md={6} lg={4}>
              <PositioningCard data={val} cpName={cpNames[ind]} ind={1} selectPositioning={selectPositioning} history={history}/>
            </Grid>
          )
        })}
      </Grid>
      </>
      :
    activePage === "add_positioning" && allCp !== null?
      <AddPositioning data={emptyPositioning} allCp={allCp} {...props} />
      :
    positionings !== null && allCp !== null && activePage === "positioning_content"?
      <AddPositioning data={activePositioning} allCp={allCp} {...props}/>
      :
    positionings !== null && activePage === "add_messaging"?
      <AddMessaging positioning={activePositioning} mode="add" />
      :
    positionings !== null && activePage === "edit_messaging"?
      <AddMessaging positioning={activePositioning} mode="edit" p_id={props.match.params.p_id}/>
    :<></>
  )
}