import React, { useState, useEffect, useRef } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, IconButton, Button, Stack, Grid, Chip, Paper, TextField, Toolbar, Radio, Checkbox, RadioGroup} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider'

import TipBox from "./../utils/TipBox"

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import axios from "axios"
import { BACKEND_ADDRESS } from "./../utils/getEndpoint"
import { useSelector } from 'react-redux'

const pvQuestions = [
  "Was the problem discussed with buyer?",
  "Buyer confirmed the discussed problem as critical?",
  "Buyer articulated (with concrete stories & examples) what they are unable to achieve due to problem?",
  "Buyer had actively invested money/effort/time/tech/human resources to solve the problem?"
]
const pvQuestionsDict = pvQuestions.reduce((acc, x) => {acc[x] = false; return acc}, {})

const stakeholderTypes = ["Champion", "Decision Maker", "Executive Sponsor", "End User", "Influencer", "Budget Holder", "Legal & Compliance", "Blocker"]
const stakeholderDescription = {
  "Champion": "The advocate who pushes for the product or service within the organization",
  "Decision Maker": "The individual with final authority to make the purchase decision",
  "Executive Sponsor": "A senior leader who looks/sponsors the deal from an ROI, budget, and a big picture perspective",
  "End User": "The individual who will use the product or service directly",
  "Influencer": "An individual who has a following and whose opinion influences the decision-making process",
  "Budget Holder": "The person responsible for approving and controlling the budget",
  "Legal & Compliance": "The representative who ensures that the purchase meets all legal and regulatory requirements",
  "Blocker": "An individual who may resist or oppose adoption of the offering"
}

const initialVerdict = {
  "Discussed 2+ problems in the call successfully": false,
  "1+ Buyer Problems were validated": false,
  "Buyer showed strong Intent to buy": false
}

const intialBuyingIntentResponses = {
  "Buying Role": "",
  "Did buyer co-define next steps to a paid outcome like Contract, POC, Early Design Partnership etc.?": null,
  "Did buyer display buying commitment? (tentatively agreed on pricing & timelines, connected you to other stakeholders in their org. etc.)?": null,
  "Did buyer make NO diverting/false positive comments? (asked for a pitch deck/demo, asked you to keep them posted on progress, offered other intros etc.)": null
}

const findInitalValues = (campaign, problems, listNo, leadNo) => {
  var problemStageIntialized = false
  var verdict, buyingIntentResponses, problemValidationResponses, problemStage;

  //initialize verdicts
  if(campaign["verdicts"][listNo][leadNo] !== null){
    verdict = campaign["verdicts"][listNo][leadNo]
    problemStage = 9
    problemStageIntialized = true
  }else{
    verdict = null
  }
  //console.log(verdict)

  //initialize buyingIntentResponses
  if(campaign["buying_intent_responses"][listNo][leadNo] !== null){
    buyingIntentResponses = campaign["buying_intent_responses"][listNo][leadNo]

    if(!problemStageIntialized){
      problemStage = 8
      problemStageIntialized = true
    }
  }else{
    buyingIntentResponses = null
  }
  //console.log(buyingIntentResponses)

  //initialize problemValidationResponses
  var problemResponses = {}

  if(campaign["problem_validation_responses"][problems[0]][listNo][leadNo] === null){
    for(var k=0;k<problems.length;k+=1){
      problemResponses[problems[k]] = pvQuestionsDict
    }
  }else{
    for(var k=0;k<problems.length;k+=1){
      problemResponses[problems[k]] = campaign["problem_validation_responses"][problems[k]][listNo][leadNo]
    }
  }
  problemValidationResponses = problemResponses

  if(!problemStageIntialized){
    if(campaign["problem_validation_responses"][problems[0]][listNo][leadNo] !== null){
      problemStage = 4
      problemStageIntialized = true
    }else{
      problemStage = 1
      problemStageIntialized = true
    }
  }

  return [problemStage, verdict, buyingIntentResponses, problemValidationResponses]
}

export default function LogCall(props){
  var listNo = props.listNo
  var leadNo = props.leadNo
  var campaign = props.campaign
  var problems = props.cp["problems"]
  var leadList = campaign["leads"][listNo]
  var initialisations = findInitalValues(campaign, problems, listNo, leadNo)

  const contentRef = useRef(null);

  var sellerName = useSelector((state) => state.name.value);

  //log
  const [problemStage, setProblemStage] = useState(initialisations[0])
  //const [activeProblems, setActiveProblems] = useState([])
  const [problemValidationResponses, setProblemValidationResponses] = useState(initialisations[3])
  const [buyingIntentResponses, setBuyingIntentResponses] = useState(initialisations[2])
  const buyingIntentResponsesKeys = buyingIntentResponses?Object.keys(buyingIntentResponses):[]
  const [verdict, setVerdict] = useState(initialisations[1])

  const updateBackend = (x, y, z) => {
    for(var i=0;i<problems.length;i+=1){
      campaign["problem_validation_responses"][problems[i]][listNo][leadNo] = x[problems[i]]
    }

    campaign["buying_intent_responses"][listNo][leadNo] = y
    campaign["verdicts"][listNo][leadNo] = z

    if(z !== null){
      //i.e. logging is complete
      var obj = {
        type: "Conversation Done",
        status: "completed"
      }
      const statusLength = campaign["lead_status"][listNo][leadNo].length
      campaign["lead_status"][listNo][leadNo][statusLength - 1] = obj
    }

    //update in backend
    axios.put(BACKEND_ADDRESS + '/campaign/', campaign)
  }

  const handleProblemValidationResponseChange = (e, y, ind) => {
    //(e, ind, rowIndex) => {
    var problemResponsesCopy = JSON.parse(JSON.stringify(problemValidationResponses))
    problemResponsesCopy[y][pvQuestions[ind]] = e.target.checked

    //set values for those ahead in problem questionaire
    for(var k=ind+1;k<pvQuestions.length;k+=1){
      problemResponsesCopy[y][pvQuestions[k]] = false
    }

    setProblemValidationResponses(problemResponsesCopy)

    // re-calculate verdict
    if(problemStage + 1 >= 9){
      var inputData = {
        initial_verdict: initialVerdict,
        problem_validation_responses: problemResponsesCopy, // the new value
        buying_intent_responses: buyingIntentResponses,
        pv_questions: pvQuestions,
      }
      axios.post(BACKEND_ADDRESS + '/campaign/verdict/', inputData)
      .then(response => {
        setVerdict(response.data["verdict"]);
        updateBackend(problemResponsesCopy, buyingIntentResponses, response.data["verdict"])
      })
    }
  }

  const handleBuyingIntentChange = (val, ind) => {
    if(val === "true") val = true
    if(val === "false") val = false

    var buyingIntentResponsesCopy = JSON.parse(JSON.stringify(buyingIntentResponses))
    buyingIntentResponsesCopy[buyingIntentResponsesKeys[ind]] = val

    setBuyingIntentResponses(buyingIntentResponsesCopy)
    setProblemStage(problemStage + 1)
    // contentRef.current.scrollTop = contentRef.current.scrollHeight //scroll down

    // re-calculate verdict
    if(problemStage + 1 >= 9){
      var inputData = {
        initial_verdict: initialVerdict,
        problem_validation_responses: problemValidationResponses,
        buying_intent_responses: buyingIntentResponsesCopy, //the new value
        pv_questions: pvQuestions,
      }
      axios.post(BACKEND_ADDRESS + '/campaign/verdict/', inputData)
      .then(response => {
        setVerdict(response.data["verdict"]);
        updateBackend(problemValidationResponses, buyingIntentResponsesCopy, response.data["verdict"])
      })
    }
  }

  const handleNextQ = () => {
    setProblemStage(problemStage+1)
    if(problemStage+1 === 5){
      setBuyingIntentResponses(intialBuyingIntentResponses);
      updateBackend(problemValidationResponses, intialBuyingIntentResponses, null)
    }else{
      updateBackend(problemValidationResponses, null, null)
    }
  }

  return (
    <>
      <Paper elevation={0} style={{width: "100%", height: "95vh", overflowY: "auto", padding: "24 16", borderRadiusTopRight: 2, borderRadiusBottomRight: 2}}>
        {Object.keys(problemValidationResponses).length > 0?
        <Stack direction="column" spacing={1}>
          <Typography><b>Problem Validation?</b></Typography>
          <Typography variant="subtitle2">{"Answer the following questions on the problem statement discussion" + (leadList["data"]["Name"][leadNo] === ""?"":" with " + leadList["data"]["Name"][leadNo])}-</Typography>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead sx={{backgroundColor: "#f5f5f5"}}>
                <TableRow>
                  <TableCell sx={{width:"200"}}>Problem</TableCell>
                  {pvQuestions.slice(0, problemStage).map((x, ind)=> {
                    return (
                      <TableCell key={ind} align="right"><Typography sx={{fontSize:12}}>{x}</Typography></TableCell>
                    )
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {props.cp["problems"].map((y, rowIndex) =>{
                  return (
                    <TableRow key={rowIndex} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                      <TableCell key="Name" component="th" scope="row">{y}</TableCell>
                      {pvQuestions.slice(0, problemStage).map((x, ind)=> {
                        return (
                          <TableCell key={ind} align="right">
                            {ind>0 && !problemValidationResponses[y][pvQuestions[ind-1]]?
                              <Typography color="red">N/A</Typography>
                              :
                              <Checkbox checked={problemValidationResponses[y][pvQuestions[ind]]} onChange={e => handleProblemValidationResponseChange(e, y, ind)}/>
                            }
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
                {problemStage <= pvQuestions.length?
                <TableRow>
                  <TableCell colSpan={problemStage+1} align="right">
                    <Button onClick={handleNextQ}>{problemStage === pvQuestions.length?"Save":"Next Question"}</Button>
                  </TableCell>
                </TableRow>
                :<></>
                }
              </TableBody>
            </Table>
          </TableContainer>
          <br />

          {problemStage >= 5?
            <>
            <Typography><b>Buying Intent?</b></Typography>
            <Typography variant="subtitle2">{buyingIntentResponsesKeys[0]}</Typography>
            <FormControl size="small">
            <Select variant="outlined" size="small" sx={{width: "100%"}} value={buyingIntentResponses[buyingIntentResponsesKeys[0]]} onChange={(e) => handleBuyingIntentChange(e.target.value, 0)}>
              {stakeholderTypes.map((x, ind) => {
                return (
                  <MenuItem key={ind} value={x}>
                    <Typography display="inline" variant="subtitle2">{x}</Typography>
                    &nbsp;&nbsp;
                    <Typography display="inline" variant="subtitle2" sx={{color: "rgba(0, 0, 0, 0.4)"}}><i>{stakeholderDescription[x]}</i></Typography>
                  </MenuItem>
                )
              })}
            </Select>
            </FormControl>
            </>:<></>
          }

          {buyingIntentResponsesKeys.map((x, ind) => {
            if(ind === 0) return (<React.Fragment key={ind}></React.Fragment>)
            if(problemStage >= 5+ind)
            return (
              <React.Fragment key={ind}>
              <FormControl>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} sx={{mt:1, mb:1}}>
                  <FormLabel sx={{color: "black", fontSize: "14"}}>{x}</FormLabel>
                  <RadioGroup row value={buyingIntentResponses[buyingIntentResponsesKeys[ind]]} onChange={e => handleBuyingIntentChange(e.target.value, ind)}>
                    <Stack direction="row">
                      <FormControlLabel value="true" control={<Radio />} label="Yes" />
                      <FormControlLabel value="false" control={<Radio />} label="No" />
                    </Stack>
                  </RadioGroup>
                </Stack>
              </FormControl>
              </React.Fragment>
            )
          })}
          <br />

          {problemStage >= 9 && verdict !== null?
            <Stack direction="column" spacing={1}>
              <Divider />
              <Typography><b>Goals Achieved</b></Typography>
              {Object.keys(verdict).map((x, ind) => {
                if(verdict[x]) return (<Stack key={ind} direction="row" alignItems="center" spacing={1}><CheckCircleIcon fontSize="small" sx={{color:"#3f8f29"}}/><Typography color="green" variant="subtitle2">{x}</Typography></Stack>)
                else return (<Stack key={ind} direction="row" alignItems="center" spacing={1}><CancelIcon fontSize="small" sx={{color:"#de1a24"}}/><Typography color="red" variant="subtitle2">{x}</Typography></Stack>)
              })}
            </Stack>
            :<></>
          }
        </Stack>
        :<></>
        }
      </Paper>
    </>
  )
}
