import React, {useState} from "react"
//import {FOLLOW_TALKING_POINTS, SELL_TO_A_PERSONA, APPLY_A_TACTIC, HANDLE_A_SITUATION} from "./constants"
import {Box} from "@mui/material"

// https://www.freecodecamp.org/news/how-to-validate-urls-in-javascript/
const isValidUrl = (str) => {
  const pattern = new RegExp(
    '^([a-zA-Z]+:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', // fragment locator
    'i'
  )
  return pattern.test(str);
}

const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

// https://stackoverflow.com/a/37438675/9160820
// generate new object id for saving in DB
const objectId = (m = Math, d = Date, h = 16, s = s => m.floor(s).toString(h)) =>
  s(d.now() / 1000) + ' '.repeat(h).replace(/./g, () => s(m.random() * h))

// format time into HH:MM:SS format
const formatTime = (seconds) => {
   const hours = Math.floor(seconds / 3600);
   const minutes = Math.floor((seconds % 3600) / 60);
   const remainingSeconds = seconds % 60;

   const formattedHours = String(hours).padStart(2, '0');
   const formattedMinutes = String(minutes).padStart(2, '0');
   const formattedSeconds = String(remainingSeconds).padStart(2, '0');

   if(hours === 0 && minutes === 0) return `0:${formattedSeconds}`
   if(hours === 0) return `${formattedMinutes}:${formattedSeconds}`

   return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}


function objectIdToDate(objectId) {
  // Extract the first 8 characters (4 bytes) of the ObjectID and convert to a Date
  const timestamp = parseInt(objectId.substring(0, 8), 16) * 1000; // Convert to milliseconds
  const date = new Date(timestamp);

  // Format the date
  const options = {
    day: '2-digit',
    month: 'long',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };
  // Convert to desired format
  return date.toLocaleString('en-US', options)
}

function getTimeLeft(objectId, weeks) {
  // Extract the timestamp from the ObjectID (first 8 characters, in hex) and convert it to milliseconds
  const timestamp = parseInt(objectId.substring(0, 8), 16) * 1000;
  const creationDate = new Date(timestamp);

  // Calculate the end date by adding the specified number of weeks
  const weeksInMilliseconds = weeks * 7 * 24 * 60 * 60 * 1000;
  const endDate = new Date(creationDate.getTime() + weeksInMilliseconds);

  // Calculate the difference between the current date and the end date
  const now = new Date();
  const timeDifference = endDate - now;

  // If the time difference is negative, the duration has already ended
  if (timeDifference <= 0) {
    return [0, 0];
  }

  // Convert the time difference to days and hours
  const daysLeft = Math.floor(timeDifference / (24 * 60 * 60 * 1000));
  const hoursLeft = Math.floor((timeDifference % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));

  return [daysLeft, hoursLeft];
}

// const HighlightText = ({ text }) => {
//   // Regex to find all curly braces content
//   const regex = /{([^}]+)}/g;
//   const parts = text.split(regex);

//   return (
//     <Box>
//       {parts.map((part, index) =>
//         index % 2 === 1 ? (
//           <Box component="span" key={index} sx={{ color: 'red' }}>
//             {"{" + part + "}"}
//           </Box>
//         ) : (
//           <Box component="span" key={index} style={{userSelect: 'none'}}>
//             {part}
//           </Box>
//         )
//       )}
//     </Box>
//   );
// };

const HighlightText = ({ text }) => {
  // Regex to find both curly brace content and the [question?val1:val2] format
  const regex = /({[^}]+})|(\[[^\?\]]+\?[^\:]+\:[^\]]+\])/g;

  // Split the text based on the regex
  const parts = text.split(regex);

  // Function to render curly brace highlighted content
  const renderCurlyBracePart = (part, index) => (
    <Box component="span" key={`curly-${index}`} sx={{ color: 'red' }}>
      {part}
    </Box>
  );

  // Function to handle [question?val1:val2] highlighting
  const renderQuestionPart = (part, index) => {
    const questionRegex = /\[([^\?]+)\?([^\:]+)\:([^\]]+)\]/;
    const match = part.match(questionRegex);
    if (match) {
      const [, question, val1, val2] = match;

      return (
        <React.Fragment key={`question-${index}`}>
          <Box component="span" key={`${index}-open`} sx={{ color: 'red' }}>
            {"["}
          </Box>
          <Box component="span" key={`${index}-question`} sx={{ color: 'red' }}>
            {question}
          </Box>
          <Box component="span" key={`${index}-questionMark`} sx={{ color: 'red' }}>
            {"?"}
          </Box>
          {highlightTextWithCurlyBraces(val1, `${index}-val1`)}
          <Box component="span" key={`${index}-colon`} sx={{ color: 'red' }}>
            {":"}
          </Box>
          {highlightTextWithCurlyBraces(val2, `${index}-val2`)}
          <Box component="span" key={`${index}-close`} sx={{ color: 'red' }}>
            {"]"}
          </Box>
        </React.Fragment>
      );
    }
    return part;
  };

  // Function to recursively handle highlighting of curly braces within val1/val2
  const highlightTextWithCurlyBraces = (text, keyPrefix) => {
    const nestedParts = text.split(/({[^}]+})/);
    return nestedParts.map((part, index) =>
      part.startsWith("{") && part.endsWith("}") ? (
        <Box component="span" key={`${keyPrefix}-curly-${index}`} sx={{ color: 'red' }}>
          {part}
        </Box>
      ) : (
        <Box component="span" key={`${keyPrefix}-plain-${index}`} style={{ userSelect: 'none' }}>
          {part}
        </Box>
      )
    );
  };

  return (
    <Box>
      {parts.map((part, index) => {
        // Handle curly brace content
        if (part && part.startsWith("{") && part.endsWith("}")) {
          return renderCurlyBracePart(part, index);
        }

        // Handle [question?val1:val2] content
        if (part && part.startsWith("[") && part.endsWith("]")) {
          return renderQuestionPart(part, index);
        }

        // Render plain text
        return (
          <Box component="span" key={`plain-${index}`} style={{ userSelect: 'none' }}>
            {part}
          </Box>
        );
      })}
    </Box>
  );
};

const getRoleplayVerdict = (evaluation) => {
  try{
    const keys = Object.keys(evaluation["evaluation"])
    var satisfactory = 0, unsatisfactory = 0, rating;

    for(var i=0;i<keys.length;i+=1){
      if(evaluation["evaluation"][keys[i]]['result'] === 'SATISFACTORY') satisfactory += 1
      else unsatisfactory += 1
    }

    if(satisfactory + unsatisfactory < 5){
      if(Object.keys(evaluation.evaluation).length === 0) rating = 4
      else if(unsatisfactory === 0) rating = 1
      else if (satisfactory > unsatisfactory) rating = 2
      else rating = 3
    }else{
      const winPercentage = satisfactory/(satisfactory + unsatisfactory);

      if(Object.keys(evaluation.evaluation).length === 0) rating = 4
      else if(winPercentage >= 0.8) rating = 1
      else if (winPercentage >= 0.5) rating = 2
      else rating = 3
    }
  }catch(err) {return 3}

  return rating
}

function useUndoRedo(initialState) {
  const [past, setPast] = useState([]);
  const [present, setPresent] = useState(initialState);
  const [future, setFuture] = useState([]);

  const undo = () => {
    if (past.length === 0) return;

    const newPast = [...past];
    const newPresent = newPast.pop();

    setPast(newPast);
    setFuture([present, ...future]);
    setPresent(newPresent);
  };

  const redo = () => {
    if (future.length === 0) return;

    const newFuture = [...future];
    const newPresent = newFuture.shift();

    setPast([...past, present]);
    setFuture(newFuture);
    setPresent(newPresent);
  };

  const updatePresent = (newState) => {
    setPast([...past, present]);
    setPresent(newState);
    setFuture([]);
  };

  return { state: present, undo, redo, updateState: updatePresent };
}

export {isValidUrl, isValidEmail, objectId, formatTime, objectIdToDate, getTimeLeft, HighlightText, getRoleplayVerdict, useUndoRedo}
