import React,{useState} from "react"
import {Grid,TextField ,Button ,InputAdornment, IconButton,Paper} from '@mui/material';
//import {makeStyles} from '@mui/styles';
import useClasses from './../utils/useClasses'
import {MailOutline,Visibility,VisibilityOff,LockOutlined,PersonOutline, } from '@mui/icons-material'
import { Typography } from '@mui/material';
import fire from "./../utils/base";
import firebase from 'firebase'
import {useHistory} from "react-router-dom"
import { Link } from '@mui/material';
import ToastComponent from "./../utils/ToastComponent";
import {toast } from "react-toastify";
import { COLORS } from "./../utils/colors";
import axios from "axios"
import {BACKEND_ADDRESS} from "./../utils/getEndpoint.js"
import {objectId} from "./../utils/helper"

import {isMobile} from "react-device-detect"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Aspiro from "../Aspiro"

toast.configure();

const useStyles = theme =>({
    root : {
        '& .MuiFormControl-root':{
            width : "100%",
            margin : theme.spacing(1),
        }
    },
    button : {
        color:'white',
        backgroundColor: 'white',
        borderColor:'white'
    },
    button2 : {
        marginTop:"10",
        marginBottom:"10",
        marginLeft:"20",
        marginRight:"10",
    },
    error1 : {
        color:"Red",
    },
    subtitle1: {
        color:"white",
        align:"center",
        marginBottom:"10%"
    },
    Heading1: {
        marginTop:"30%",
        color:"White",
        height:"10%",
    },
    Heading2: {
        marginTop:"15%",
        marginBottom:"0%",
        //color:'673AB7',
        //height:"10%"
    },
    Grid1: {
        position :'absolute',
        top:'0',
        bottom:'0',
        left: '-2',
        right: '-2',
        //background: 'linear-gradient(0deg, #19F4A6 0%, #395af6 100%)'
        background: '#111b49'
    },
    Grid2: {
        position :'absolute',
        top:'0',
        bottom:'0',
        left: '-2',
        right: '-2',
        //background: 'linear-gradient(0deg, #395af6 0%, #19F4A6 100%)'
        background: '#111b49'
    },
    cardText: {
        "fontSize":'90px',
      },
    toaster : {
        backgroundColor : COLORS.white,
        color : COLORS.primary,
        width : 500
    },
    toaster1 : {
        backgroundColor : COLORS.primary
    }
})


function getQueryVariable(variable){
  //get query variable from url
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i=0;i<vars.length;i++) {
     var pair = vars[i].split("=");
     if(pair[0] === variable){return pair[1];}
  }
  return(false);
}

export default function Signin (props) {
    const [showPage, setShowPage] = useState(props.new_user);
    //const handleClickShowPage = () => setShowPage(!showPage);
    const [wrongPassword, setwrongPassword] = useState(false);
    const [printerror, setPrintError] = useState();
    const [showPassword, setShowPassword] = useState(false);
    //const [rememberMe, setRememberMe] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [signedInMobile, setSignedInMobile] = useState(false) //whether use is logged in or not
    const classes = useClasses(useStyles);
    let history = useHistory();
    var invitedAccount = getQueryVariable("account");
    var wasInvited = invitedAccount === false?false:true

    const handletoast = () => {
      ToastComponent("info1", "Please click on the link in the verification email sent on your registered email ID. Don't forget to check the spam!" , () => {})
    }

    const handleSubmitSignUp = (e) =>{
      if(email === "" || name === "" || password === ""){
        setwrongPassword(true);
        setPrintError("All fields are compulsory!");
        return
      }

        e.preventDefault() //to prevent reloading of page
        fire.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        .then(()=> {
        })
        .catch((error) => {
            setwrongPassword(true)
            setPrintError(error.message)
        });

        var promise;

        if(invitedAccount){
          promise = Promise.all([
            axios.get(BACKEND_ADDRESS + '/account/', {params: {account_id: invitedAccount}}),
            axios.post(BACKEND_ADDRESS + '/user/user_by_email/', {email: email})
          ]).then((response) => {
            const r1 = response[0].data
            //const r2 = response[1].data

            const users = r1["users"]
            var ind = -1;
            for(var i=0;i<users.length;i+=1){
              if(users[i]["email"] === email && users[i]["status"] === "pending"){
                ind = i
                break
              }
            }

            if(ind !== - 1){
              var data = r1
              data["users"][ind] = {
                email: data["users"][ind]["email"],
                name: name,
                status: "active"
              }

              // CREATE OR EDIT USER
              // check if user has already been invited in an existing account
              return [data, response[1]]
            }else{
              setwrongPassword(true);
              setPrintError("Can't find your invite. Please ask for a fresh invite on your email!");
              //sending error so that the rest of sequence is not executed.
              throw new Error('');
            }
          })
        }else{
          promise = Promise.all([
            axios.get(BACKEND_ADDRESS + '/all_accounts/', {}),
            axios.post(BACKEND_ADDRESS + '/user/user_by_email/', {email: email})
          ]).then((response) => {
            var account = objectId()
            invitedAccount = account

            const accountData = {
              account_id: account,
              account_name: name + "'s account",
              users: [{email: email, name: name, status: "active"}],
              company_name: "",
              company_website: "",
              competitors: [],
              customer_profiles: [],
              campaigns: [],
              lead_lists: [],
              positionings: []
            }
            const accountInfoData = {
              account_id: account,
              integrations: {crm: {chosen_crm: null, access_token: null, refresh_token: null}}
            }

            return [[accountData, accountInfoData], response[1]]
          })
        }

        promise.then((response) => {
          const r1 = response[0]
          const r2 = response[1]
          // var deletedUser = false;
          if(r2.data.length > 0){
            // email entry already exists
            if(r2.data[0].is_active){
              ToastComponent("error", "User already exists. Please sign-in instead!")
              //sending error so that the rest of sequence is not executed.
              throw new Error('');
            }else{
              if(wasInvited){
                const userData = {name : name, email: email, role : "User",
                  reports : email, onboarding : false, is_active : true, account_id : invitedAccount
                }

                return [r1, userData]
              }else{
                //DELETED ACCOUNT
                // temporary fix
                ToastComponent("info1", "An account with this email ID was deleted previously by someone in your team. If you want to re-activate this account, please email us at hello@letsaspiro.com" , () => {})
                setwrongPassword(false);
                //sending error so that the rest of sequence is not executed.
                throw new Error('');
              }

              /*
              ***** DONT DELETE. FEATURE IS UNDER DEVELOPMENT *****
              var data = r2.data[0]
              // name was entered by user right now only. So we have to add that
              data["name"] = name
              // make user active
              data["is_active"] = true
              // this value might be different in DB if users were invited from multiple accounts
              data["account_id"] = invitedAccount

              allPromise.push(axios.put(BACKEND_ADDRESS + '/user/', data))
              *****************************************************
              */
            }
          }else{
            // fresh email
            // create new user
            const userData = {name : name, email: email, role : "User",
              reports : email, onboarding : false, is_active : true, account_id : invitedAccount
            }

            return [r1, userData]
          }
        }).then((allData) => {
          fire.auth().createUserWithEmailAndPassword(email,password)
          .then(response=>{
            // create entries in DB now that everything is verified to work
            if(wasInvited){
              //not invitedAccount, but wasInvited
              axios.put(BACKEND_ADDRESS + '/account/', allData[0])

              axios.put(BACKEND_ADDRESS + '/user/', allData[1])
              axios.post(BACKEND_ADDRESS + '/user/notify_user_signup/', allData[1])
            }else{
              axios.post(BACKEND_ADDRESS + '/account/', allData[0][0])
              axios.post(BACKEND_ADDRESS + '/account_info/', allData[0][1])

              axios.post(BACKEND_ADDRESS + '/user/', allData[1])
              axios.post(BACKEND_ADDRESS + '/user/notify_user_signup/', allData[1])
            }

            response.user.updateProfile({displayName:name})
            response.user.sendEmailVerification({url: window.location.protocol + "//" + window.location.host + '/signin/'})
            setShowPage((prevvalue) => !prevvalue);
            handletoast();

            //undo the error message that was printed
            setwrongPassword(false);
          }).catch((error)=>{
            var errorCode = error.code
            if (errorCode === 'auth/email-already-in-use') {
              axios.post(BACKEND_ADDRESS + '/user/user_by_email/', {email: email})
              .then((response) => {
                if(response.data.length > 0){
                  if(response.data[0].is_active){
                    setwrongPassword(true);
                    setPrintError(error.message);
                  }else{
                    ToastComponent("info1", "An account with this email ID was deleted previously by someone in your team. If you want to re-activate this account, please email us at hello@letsaspiro.com" , () => {})
                    setwrongPassword(false);
                  }
                }else{
                  ToastComponent("info1", "An account with this email ID was deleted previously by someone in your team. If you want to re-activate this account, please email us at hello@letsaspiro.com" , () => {})
                  setwrongPassword(false);
                }
              })
            }else{
              setwrongPassword(true);
              setPrintError(error.message);
            }
          });
        }).catch((error) => {console.log(error)});
    }

    const handleSubmitSignIn = (e) => {
        e.preventDefault() //to prevent reloading of page
        fire.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
            .then(()=> {
            })
            .catch((error) => {
                setwrongPassword(true);
                setPrintError(error.message);
            });

        fire.auth()
            .signInWithEmailAndPassword(email, password)
            .then(response=>{
                axios.post(BACKEND_ADDRESS + '/user/user_by_email/', {email: email})
                .then((rr) => {
                  console.log(rr.data)
                  if(rr.data[0].is_active){
                    if(response.user.emailVerified ){
                      if(isMobile) setSignedInMobile(true)
                      else if(props.next_path!=='/signin' && props.next_path!=='/signin/' && props.next_path!=='/signup' && props.next_path!=='/signup/'){
                          history.push(props.next_path);
                      }else{
                          history.push('/icp');
                      }
                    }else{
                        response.user.sendEmailVerification({url: window.location.href})
                        handletoast();
                    }
                  }else{
                    // temporary fix
                    ToastComponent("info1", "An account with this email ID was deleted previously by someone in your team. If you want to re-activate this account, please email us at hello@letsaspiro.com" , () => {})
                    setwrongPassword(false);
                  }
                })
            }).catch((error)=>{
                setwrongPassword(true)
                setPrintError(error.message)
            });
    }

    return(
        <>
          {showPage ?
              <Grid direction="row" container className={classes.Grid1} justifyContent="center" alignItems="center">
                <Grid item xs={11} sm={8} md={6} lg={4} xxl={3}>
                  <Paper sx={{borderRadius: "20px", padding: "24px", background: "#fbfafe", height: "500px", maxWidth: "100%"}}>
                    <Grid container item direction="column" xs={12} align="center" justifyContent="center" style={{height: "100%"}}>
                      <Grid item>
                        <a href="https://letsaspiro.com">
                          <center><Aspiro height="50"/></center><br />
                        </a>
                      </Grid>
                      <Grid item>
                        <Typography variant="h5" className = {classes.Heading2} >
                            Create Account
                        </Typography>
                        {wrongPassword ?
                                <Typography variant="subtitle1" className = {classes.error1}>
                                    {printerror}
                                </Typography>:
                                <Typography variant="subtitle1"><br /></Typography>
                        }
                        <form
                            onError={errors=>{
                                for(const err of errors){
                                    console.log(err.props.errorMessages[0])
                                }
                            }}
                            className = {classes.root}
                        >
                            <Grid item xs={10}>
                                <TextField
                                    required
                                    variant = "outlined"
                                    label = "Full Name"
                                    onChange = {e => setName(e.target.value)}
                                    InputProps={{
                                        startAdornment: (
                                        <InputAdornment position="start">
                                            <PersonOutline />
                                        </InputAdornment>
                                        ),
                                    }}
                                    name="name"
                                    value={name}
                                />
                                <TextField
                                    required
                                    variant = "outlined"
                                    label = "Work Email"
                                    onChange = {e => setEmail(e.target.value)}
                                    InputProps={{
                                        startAdornment: (
                                        <InputAdornment position="start">
                                            <MailOutline />
                                        </InputAdornment>
                                        ),
                                    }}
                                    name="email"
                                    value={email}
                                    />
                                <TextField
                                    required
                                    variant = "outlined"
                                    label = "Password"
                                    onChange = {e => setPassword(e.target.value)}
                                    type={showPassword ? "text" : "password"}
                                    InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LockOutlined />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                    }}
                                    name="password"
                                    value={password}
                                    />
                            </Grid>
                            <br />
                            <Button color="primary" type="submit" style={{textTransform: 'none'}}
                            variant = "contained" onClick = {handleSubmitSignUp} autoFocus>
                              Sign Up
                            </Button>
                            <br /><br />
                            <Typography> or <Link href="/signin">Sign In</Link> instead
                            </Typography>
                        </form>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            :
              <Grid direction="row" container className={classes.Grid2} justifyContent="center" alignItems="center">
                <Grid item xs={11} sm={8} md={6} lg={4} xxl={3}>
                  <Paper sx={{ borderRadius: "20px", padding: "24px", background: "#fbfafe", height: "500px", maxWidth: "100%"}}>
                    <Grid container item direction="column" xs={12} align="center" justifyContent="center" style={{height: "100%"}}>
                      <Grid item>
                        <a href="https://letsaspiro.com">
                          <center><Aspiro height="50"/></center><br />
                        </a>
                      </Grid>
                      <Grid item>
                        <Typography variant="h5" className = {classes.Heading2} >
                            Sign In
                        </Typography>
                        {wrongPassword ?
                            <Typography variant="subtitle1" className = {classes.error1}>
                                Invalid email id or password
                            </Typography>:
                            <Typography variant="subtitle1"><br /></Typography>
                        }
                        <form
                            onError={errors=>{
                                for(const err of errors){
                                    console.log(err.props.errorMessages[0])
                                }
                            }}
                            className = {classes.root}
                            >
                            <Grid item xs={10}>
                                <TextField
                                    required
                                    variant = "outlined"
                                    label = "Work Email"
                                    onChange = {e => setEmail(e.target.value)}
                                    InputProps={{
                                        startAdornment: (
                                        <InputAdornment position="start">
                                            <MailOutline />
                                        </InputAdornment>
                                        ),
                                    }}
                                    name="email"
                                    value={email}
                                    />
                                <TextField
                                    required
                                    variant = "outlined"
                                    label = "Password"
                                    onChange = {e => setPassword(e.target.value)}
                                    type={showPassword ? "text" : "password"}
                                    InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LockOutlined />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                    }}
                                    name="password"
                                    value={password}
                                    />
                            </Grid>
                            <br />
                            <Button type="submit" color="primary" variant = "contained" style={{textTransform: 'none'}}
                            className = {classes.button2} onClick={handleSubmitSignIn} autoFocus>
                              Sign In
                            </Button>
                        </form>
                        <Typography><Link href="/signin/forgotpassword" >Forgot Password?</Link></Typography>
                        <br />
                        <Typography> or <Link href="/signup">Sign Up</Link> instead
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Dialog open={signedInMobile}>
                  <DialogTitle>
                    Can't Roleplay!
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      This product is not mobile compatible! Please Sign In on Chrome (desktop) or Safari (desktop) to start.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button variant="contained" onClick={() => setSignedInMobile(false)} sx={{textTransform: 'none'}}>Okay</Button>
                  </DialogActions>
                </Dialog>
              </Grid>
            }
        </>
    )
}
